import { default as axiosBase } from 'axios';

import * as constants from '../constants';
import { storageAvailable } from '../utils/helpers';

const environment = process.env.REACT_APP_ENVIRONMENT;
const ENV_API_BASE = prepareUrl(process.env.REACT_APP_API_HOST?.toString());
let axiosInstances = [];
let CONFIG_API_BASE;
const corsKey = localStorage.getItem('cors');

export let headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'X-SERVER-CACHE': 0,
    mode: environment === constants.environment.production ? 'prod' : 'qa',
};

if (corsKey) {
    headers['X-Allow-All-Origin-Key'] = corsKey;
}

export function getAPIBase(confApiHost = null) {
    const sourceAPISubdomain = storageAvailable() ? localStorage.getItem('sourceapi') : '';
    if (sourceAPISubdomain) {
        if (sourceAPISubdomain.match(/^localhost(:\d*)?$/)) {
            return `https://${sourceAPISubdomain}/`;
        }
        return `https://${sourceAPISubdomain}.tempest.com/`;
    }
    if (confApiHost) {
        CONFIG_API_BASE = prepareUrl(confApiHost);
    }
    return CONFIG_API_BASE || ENV_API_BASE;
}

export function axios(disableHTTPHeaders = false) {
    const apiBase = getAPIBase();
    if (axiosInstances.hasOwnProperty(apiBase)) {
        return axiosInstances[apiBase];
    }
    return (axiosInstances[apiBase] = axiosBase.create({
        baseURL: `${apiBase}`,
        timeout: 30000,
        headers: disableHTTPHeaders ? {} : headers,
    }));
}

function prepareUrl(url) {
    if (!url) {
        return url;
    }
    const cleanUrl = url.replace(/"/g, '');
    return cleanUrl + (cleanUrl.endsWith('/') ? '' : '/');
}

// axios.interceptors.request.use((x) => {
//     // to avoid overwriting if another interceptor
//     // already defined the same object (meta)
//     x.meta = x.meta || {};
//     x.meta.requestStartedAt = new Date().getTime();
//     return x;
// });
//
// axios.interceptors.response.use((x) => {
//     console.log(`Response time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`);
//     return x;
// });
