import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as loadStatuses from '../../../constants/loadStatuses';
import StockWidgetLoading from './loading';

const LazyWidget = lazy(() => import('./lazyLoad'));

class StockWidget extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            lazyWidgetIsLoaded: false,
        };
    }

    handleLazyLoad() {
        this.setState({
            lazyWidgetIsLoaded: true,
        });
    }

    render() {
        const { richHeaderLoadStatus, data } = this.props;
        const { lazyWidgetIsLoaded } = this.state;

        return (
            <>
                {(richHeaderLoadStatus === loadStatuses.loading ||
                    (!lazyWidgetIsLoaded && richHeaderLoadStatus !== loadStatuses.idle)) && <StockWidgetLoading />}
                <Suspense fallback={<div> </div>}>
                    {richHeaderLoadStatus === loadStatuses.completed && (
                        <LazyWidget data={data} onLoad={this.handleLazyLoad.bind(this)} />
                    )}
                </Suspense>
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    richHeaderLoadStatus: state.search.richHeaderLoadStatus,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            dispatch,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(StockWidget);
