import './CategoryTooltip.scss';

import cx from 'classnames';
import React from 'react';
import Popup from 'reactjs-popup';

type CategoryTooltipProps = {
    className?: string;
    children: React.ReactNode;
    hiddenCategoriesCount: number;
};

function CategoryTooltip({ className, children, hiddenCategoriesCount }: CategoryTooltipProps) {
    return (
        <Popup
            className="category-tooltip"
            trigger={() => (
                <span className={cx('category-tooltip__trigger', className)}>&nbsp;+{hiddenCategoriesCount}</span>
            )}
            position={'top center'}
            on={['hover', 'focus']}
            closeOnDocumentClick
        >
            {children}
        </Popup>
    );
}

export default CategoryTooltip;
