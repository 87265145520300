import {
    CHANGE_APP_LANGUAGE,
    CHANGE_BETA_STATUS,
    CHANGE_CONTENT_LOAD_STATUS,
    CHANGE_HOMEPAGE_SECTION,
    CHANGE_LANG_USER_SET,
    CHANGE_OPEN_NEW_TAB_SETTING,
    CHANGE_PAGE_TYPE,
    CHANGE_SAFE_SEARCH_TYPE,
    CHANGE_SEARCH_TYPE,
    CHANGE_THEME,
    CLOSE_MENU,
    ENABLE_LOGGER,
    ENABLE_MOCK_DATA,
    MOBILE_INPUT_OPEN,
    PUT_METRICS,
    SET_DISMISS_OPEN_MAP_CARD,
    SET_IMAGES_SIDEBAR_STATUS,
    SET_MAP_SEARCH_TYPE,
    SET_OTHER_SETTINGS_EXPANDED,
    SET_RECENT_ACTION_DATA,
    SET_RECENT_PATH,
    TOGGLE_ENCRYPTION,
    TOGGLE_LOCATION,
    TOGGLE_MENU,
    TOGGLE_SEND_ANONYMOUS_USAGE_DATA,
    TOGGLE_SHOW_PRIVACY_CARDS,
    TOGGLE_SHOW_SUGGESTIONS,
    TOGGLE_TIME_TO_SHOW_FOOTER,
    USE_APPLE_MAPS_LOOKUP,
} from '../actions/app';
import { EMAIL_SUBMITTED } from '../actions/search';

import constants from '../constants';
import languageOptions from '../constants/language';
import searchTypes from '../constants/searchTypes';
import { saveToLocalStorage } from '../utils/helpers';

const initialState = {
    appReady: false,
    searchType: searchTypes.all,
    mapSearchType: null,
    showSuggestions: true,
    privacyCardsSetting: true,
    sendAnonymousUsage: true,
    locationSetting: false,
    encryptionSetting: false,
    appLanguage: languageOptions[0].value,
    safeSearchType: constants.safeSearchOptions[0].value,
    openInNewTab: false,
    contentLoadStatus: false,
    theme: constants.themeOptions[2].value,
    pageType: constants.pageTypes.home,
    homepageSection: null,
    openBetaSearchToken: null,
    emailSubmitted: false,
    sentMetrics: {},
    isMobileViewOpen: false,
    shouldUseAppleMapsLookup: false,
    timeToShowFooter: true,
    menuIsOpen: false,
    recentPath: null,
    recentActionData: null,
    imagesSidebarStatus: false,
    dismissMapOpenCard: false,
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case EMAIL_SUBMITTED:
            saveToLocalStorage(state.emailSubmitted, 'emailSubmitted', action.data);
            return {
                ...state,
                emailSubmitted: action.data,
            };
        case TOGGLE_MENU:
            return {
                ...state,
                menuIsOpen: !state.menuIsOpen,
            };
        case CLOSE_MENU:
            return {
                ...state,
                menuIsOpen: false,
            };
        case CHANGE_BETA_STATUS:
            let params = {};
            if (action.data !== null) {
                saveToLocalStorage(state.betaAllowed, 'openBetaSearchToken', action.data);
                params.emailSubmissionStatus = true;
            } else {
                localStorage.removeItem('openBetaSearchToken');
                localStorage.removeItem('emailSubmitted');
                params.emailSubmissionStatus = false;
                params.theme = constants.themeOptions[2].value;
            }
            return {
                ...state,
                openBetaSearchToken: action.data,
                ...params,
            };
        case CHANGE_SEARCH_TYPE:
            const newState = {
                ...state,
                searchType: action.data,
                previousSearchType: state.previousSearchType !== action.data ? action.data : state.previousSearchType,
            };

            if (state.searchType === searchTypes.map && action.data !== searchTypes.map) {
                newState.mapSearchType = null;
            }

            return newState;
        case SET_MAP_SEARCH_TYPE:
            return {
                ...state,
                mapSearchType: action.data,
            };
        case CHANGE_PAGE_TYPE:
            return {
                ...state,
                pageType: action.data,
            };
        case CHANGE_CONTENT_LOAD_STATUS:
            return {
                ...state,
                contentLoadStatus: action.data,
            };
        case CHANGE_HOMEPAGE_SECTION:
            return {
                ...state,
                homepageSection: action.data,
            };
        case CHANGE_THEME:
            saveToLocalStorage(state.theme, 'theme', action.data);
            return {
                ...state,
                theme: action.data,
            };
        case TOGGLE_SHOW_SUGGESTIONS:
            const suggestionsSetting = action.data ? action.data : !state.showSuggestions;
            saveToLocalStorage(state.showSuggestions, 'showSuggestions', suggestionsSetting);
            return {
                ...state,
                showSuggestions: suggestionsSetting,
            };
        case TOGGLE_SHOW_PRIVACY_CARDS:
            const privacyCardsSetting = action.data ? action.data : !state.privacyCardsSetting;
            saveToLocalStorage(state.privacyCardsSetting, 'privacyCardsSetting', privacyCardsSetting);
            return {
                ...state,
                privacyCardsSetting: privacyCardsSetting,
            };
        case TOGGLE_SEND_ANONYMOUS_USAGE_DATA:
            const sendAnonymousUsage = action.data ? action.data : !state.sendAnonymousUsage;
            saveToLocalStorage(state.sendAnonymousUsage, 'sendAnonymousUsage', sendAnonymousUsage);
            return {
                ...state,
                sendAnonymousUsage: sendAnonymousUsage,
            };
        case TOGGLE_LOCATION:
            saveToLocalStorage(null, 'locationSetting', action.data);
            return {
                ...state,
                locationSetting: action.data,
            };
        case TOGGLE_ENCRYPTION:
            saveToLocalStorage(state.encryptionSetting, 'encryptionSetting', action.data);
            return {
                ...state,
                encryptionSetting: action.data,
            };
        case SET_DISMISS_OPEN_MAP_CARD:
            return {
                ...state,
                dismissMapOpenCard: action.data,
            };
        case CHANGE_APP_LANGUAGE:
            saveToLocalStorage(state.appLanguage, 'lang', action.data);
            return {
                ...state,
                appLanguage: action.data,
            };
        case CHANGE_LANG_USER_SET:
            const value = action.data ? 'yes' : 'no';
            saveToLocalStorage(state.langUserSet, 'langUserSet', value);
            return {
                ...state,
                langUserSet: value,
            };
        case CHANGE_SAFE_SEARCH_TYPE:
            const newSafeSearchSetting = action.data;
            saveToLocalStorage(state.safeSearchType, 'search', newSafeSearchSetting);
            return {
                ...state,
                safeSearchType: newSafeSearchSetting,
            };
        case CHANGE_OPEN_NEW_TAB_SETTING:
            const newTabSetting = typeof action.data === 'boolean' ? action.data : !state.openInNewTab;
            saveToLocalStorage(state.newTabSetting, 'openInNewTab', newTabSetting);
            return {
                ...state,
                openInNewTab: newTabSetting,
            };
        case ENABLE_LOGGER:
            return {
                ...state,
                logger: true,
            };
        case ENABLE_MOCK_DATA:
            return {
                ...state,
                mockData: true,
            };
        case PUT_METRICS:
            const buf = [];
            buf[action.data] = Date.now();
            return {
                ...state,
                sentMetrics: { ...state.sentMetrics, ...buf },
            };
        case MOBILE_INPUT_OPEN:
            return {
                ...state,
                isMobileInputOpen: action.data,
            };
        case USE_APPLE_MAPS_LOOKUP:
            return {
                ...state,
                userCoordinates: action.data,
            };
        case TOGGLE_TIME_TO_SHOW_FOOTER:
            return {
                ...state,
                timeToShowFooter: action.data,
            };
        case SET_RECENT_PATH:
            return {
                ...state,
                recentPath: action.data,
            };
        case SET_RECENT_ACTION_DATA:
            return {
                ...state,
                recentActionData: action.data,
            };
        case SET_OTHER_SETTINGS_EXPANDED:
            saveToLocalStorage(state.isOtherSettingsExpanded, 'isOtherSettingsExpanded', !!action.data);
            return {
                ...state,
                isOtherSettingsExpanded: action.data,
            };
        case SET_IMAGES_SIDEBAR_STATUS:
            return {
                ...state,
                imagesSidebarStatus: action.data,
            };
        default:
            return state;
    }
}
