import './SkeletonTemplate.scss';

import cx from 'classnames';
import React from 'react';

type ItemProps = {
    className?: string;
    type?: 'line' | 'circle' | 'gradient-field';
    color?: 'dark';
};
function Item({ className, type, color }: ItemProps) {
    return (
        <div
            className={cx('skeleton-item', className, {
                [`skeleton-item--${type}`]: type,
                [`skeleton-item--${color}`]: color,
            })}
        />
    );
}

function Footer() {
    return (
        <div className="skeleton-footer">
            <SkeletonTemplate.Item type="line" className="skeleton-footer__item" />
            <SkeletonTemplate.Item type="line" className="skeleton-footer__item skeleton-footer__item--2" />
        </div>
    );
}

const SkeletonTemplate = {
    Item,
    Footer,
};

export default SkeletonTemplate;
