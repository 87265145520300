/**
 * # Navigation Bar
 */

/**
 * ## Imports
 */
import './NavigationBar.scss';

import React from 'react';
import { Trans } from 'react-i18next';

import { isDarkThemeEnabled } from '../../../utils/helpers';
import Link from '../Link/Link';
import NavigationBarInteraction from '../NavigationBarInteraction/NavigationBarInteraction';

/**
 * ## Component
 * @param {object} submitMetrics
 * @param {boolean} isInteriorPage
 * @param {boolean} onDarkBackground
 * @returns
 */
const NavigationBar = ({ submitMetrics, isInteriorPage = true, onDarkBackground = false }) => {
    const isLightLogo = !isDarkThemeEnabled() && !onDarkBackground;
    const logo = `/images/homepage_rebrand/assets/tempest-logo${isLightLogo ? '' : '-medium'}.svg`;

    return (
        <div
            className={`navbar${isInteriorPage ? ' navbar--interior' : ''}${
                onDarkBackground ? ' navbar--dark-background' : ''
            }`}
        >
            {!isInteriorPage ? (
                <h1 className="navbar__slogan">
                    <Trans
                        ns="websiteV2"
                        i18nKey="homepage-section-1-topleft-punchline"
                        defaults={`<span className="navbar__slogan-first-part">It's your data.</span> Own it.`}
                        components={{ span: <span /> }}
                    />
                </h1>
            ) : (
                <Link to="/">
                    <picture>
                        <img src={logo} alt="Tempest logo" width="176" height="39" className="navbar__logo" />
                    </picture>
                </Link>
            )}
            <NavigationBarInteraction submitMetrics={submitMetrics} />
        </div>
    );
};

export default NavigationBar;
