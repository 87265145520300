import './Link.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';

function Link({ children, to, className, target, clickHandler }) {
    return (
        <ReactLink onClick={clickHandler} className={`link${className && ` ${className}`}`} to={to} target={target}>
            {children}
        </ReactLink>
    );
}

Link.defaultProps = {
    children: '',
    to: '',
    className: '',
    target: '_self',
};

Link.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
};

export default Link;
