import * as PropTypes from 'prop-types';
import React, { PureComponent, Suspense, lazy } from 'react';
import { isAndroid, isIOS, isMobile, isTablet } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';

import { changeSearchType, submitMetrics } from '../../../../actions/app';
import { clearWikiBox, getMoreBusinesses, getPagePrivacyInfo, searchRequest } from '../../../../actions/search';

import BackToTop from '../../../../common/components/BackToTop/BackToTop';
import Button from '../../../../common/components/Button/Button';
import InstantAnswerTimezoneWidget from '../../../../common/components/InstantAnswerTimezoneWidget/InstantAnswerTimezoneWidget';
import InstantAnswerWidget from '../../../../common/components/InstantAnswerWidget/InstantAnswerWidget';
import SearchResultItem from '../../../../common/components/SearchResultItem/SearchResultItem';
import StockWidget from '../../../../common/components/StockWidget';
import WeatherWidget from '../../../../common/components/WeatherWidget';
import WidgetTemplate from '../../../../common/components/WidgetTemplate/WidgetTemplate';
import WikiHow from '../../../../common/components/WikiHow/WikiHow';
import SmView from '../../../../common/components/responsiveComponents/components/SmView/SmView';
import OneColumnView from './components/OneColumnView/OneColumnView';
import TwoColumnsView from './components/TwoColumnsView/TwoColumnsView';

import { ReactComponent as ArrowDownIcon } from '../../../../common/images/icons/arrow-down-icon.svg';
import imagesIcon from '../../../../common/images/icons/imagesGrey.svg';
import { ReactComponent as RelatedSearchIcon } from '../../../../common/images/icons/related-search-icon.svg';
import constants from '../../../../constants';
import * as loadStatuses from '../../../../constants/loadStatuses';
import { action, category } from '../../../../constants/metrics';
import searchTypes from '../../../../constants/searchTypes';
import { ReactComponent as Loader } from '../../../../img/report-card/loader.svg';
import { getRankingResponseChanges, getWebPagesChanges } from '../../../../selectors/searchSelector';
import { encrypt } from '../../../../utils/cryptoHelper';
import { highlightWords, scrollToTop, storageAvailable } from '../../../../utils/helpers';
import SearchResultSideContainer from '../SearchResultSideContainer/SearchResultSideContainer';

const parser = require('tld-extract');

const SearchImagesItem = lazy(() => import('./lazyComponents/lazySearchImagesItem'));
const Pagination = lazy(() => import('./lazyComponents/lazyPagination'));
const SimpleSliderWidget = lazy(() => import('./lazyComponents/lazySimpleSliderWidget'));
const SpellCheck = lazy(() => import('./lazyComponents/lazySpellCheck'));
const TimeConversionInfocard = lazy(() => import('./lazyComponents/lazyTimeConversionInfocard'));
const BusinessWidget = lazy(() => import('../../../../common/components/BusinessWidget'));
const FootballAndBasketballWidget = lazy(() => import('../../../../common/components/FootballWidget'));
const CurrencyWidget = lazy(() => import('../../../../common/components/CurrencyWidget'));
const pageSize = constants.pageSize;
const firstPageSize = constants.firstPageSize;
const isMobileButNotIPad = isMobile && !(isTablet && isIOS);

class AllResults extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            lastPage: '',
            filter: '',
            entityCleared: false,
            currentPreloadedPage: '',
            showImageWidget: true,
            showVideoWidget: false,
            widgetImagesReady: false,
            showItems: pageSize,
            openedPrivacyCards: new Set(),
            privateInfoLoadingFor: new Set(),
            wikiIsLoaded: false,
            publicKey: '',
            publicKeyVersion: '',
        };
    }

    componentDidMount() {
        const {
            passRefUpward,
            webPages,
            totalEstimatedMatches,
            searchPageNumber,
            submitMetrics,
            encryptionSetting,
            publicKey,
            publicKeyVersion,
        } = this.props;
        const { pageSize } = this.state;
        submitMetrics({
            Category: category.serp,
            Action: action.pageLoad,
            Object: 'all',
        });
        this.clearPagePrivacyCardInfo();
        this.setState({
            page: searchPageNumber ? searchPageNumber : 1,
        });
        if (totalEstimatedMatches) {
            const itemsTotal = totalEstimatedMatches;
            let lastPage =
                itemsTotal % pageSize === 0 && itemsTotal > 0
                    ? itemsTotal / pageSize
                    : Math.floor(itemsTotal / pageSize) + 1;
            this.setState({ lastPage });
        }

        passRefUpward(webPages?.length ?? 0);
        if (encryptionSetting) {
            if (publicKey && publicKeyVersion) {
                this.setState({ publicKey, publicKeyVersion });
            } else if (storageAvailable('localStorage')) {
                this.setState({
                    publicKey: localStorage.getItem('publicKey'),
                    publicKeyVersion: localStorage.getItem('publicKeyVersion'),
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            query,
            savedFormInput,
            clearWikiBox,
            searchPageNumber,
            totalEstimatedMatches,
            webPages,
            passRefUpward,
            wikiBoxLoadStatus,
        } = this.props;
        const { page, wikiIsLoaded } = this.state;
        if (!!searchPageNumber && prevProps.searchPageNumber !== this.props.searchPageNumber) {
            this.clearPagePrivacyCardInfo();
        }
        if ((searchPageNumber && searchPageNumber !== page) || searchPageNumber !== prevProps.searchPageNumber) {
            this.setState({ page: searchPageNumber });
        }
        if (savedFormInput !== prevProps.savedFormInput || query !== prevProps.query) {
            this.clearPagePrivacyCardInfo();
        }
        if (savedFormInput && query && query !== prevProps.query && query !== savedFormInput) {
            this.clearPagePrivacyCardInfo();
            // this.setState({page: 1});
            let stateSettings = {
                page: 1,
                showItems: pageSize,
                wikiIsLoaded: false,
            };
            stateSettings.page = 1;

            if (!this.state.entityCleared) {
                clearWikiBox();
                // this.setState({entityCleared: true})
                stateSettings.entityCleared = true;
            }
            if (this.state.widgetImagesReady) {
                // this.setState({
                //   widgetImagesReady: false,
                //   loadedImages: {}
                // })
                stateSettings.widgetImagesReady = false;
                stateSettings.loadedImages = {};
            }
            // this.setState({
            //   showItems: pageSize
            // })
            this.setState({
                ...stateSettings,
            });
            this.scrollToTop();
        }
        if (!!totalEstimatedMatches && totalEstimatedMatches !== prevProps.totalEstimatedMatches) {
            const itemsTotal = totalEstimatedMatches;
            let lastPage =
                itemsTotal % pageSize === 0 && itemsTotal > 0
                    ? itemsTotal / pageSize
                    : Math.floor(itemsTotal / pageSize) + 1;
            this.setState({ lastPage });
        }

        passRefUpward(webPages?.length ?? 0);

        // Not sure if this code should be here. It blocks wikibox displaying for some
        // requests (see issue https://github.com/tempest-tech-ltd/tempest-search/issues/2829)
        // Second part was added to force wikibox displaying if request status is "completed"
        if (wikiBoxLoadStatus !== loadStatuses.completed) {
            this.setState({
                wikiIsLoaded: false,
            });
        } else if (wikiBoxLoadStatus === loadStatuses.completed && !wikiIsLoaded) {
            this.setState({
                wikiIsLoaded: true,
            });
        }
    }

    searchRelatedQuery(query) {
        const { makeSearch } = this.props;
        this.props.submitMetrics({
            Category: category.serp,
            Action: action.click,
            Object: 'relatedSearches',
            Value: '',
            Extras: this.state.page,
        });
        this.clearPagePrivacyCardInfo();
        this.props.dispatch(change('search_input', 'search_field', query));
        makeSearch(query);
        this.setState({ page: 1 });
    }

    choosePage = (page) => {
        const { pageSize } = this.state;
        const { makeSearch, query, savedFormInput } = this.props;
        let offset;
        this.clearPagePrivacyCardInfo();
        if (page !== 1) {
            offset = page * pageSize - pageSize;
            this.setState({
                showItems: offset,
            });
        }
        Promise.all([makeSearch(query ?? savedFormInput, offset, 'AllResults-ChoosePage')]).then(() => {
            this.setState({
                page: page,
                widgetImagesReady: false,
                loadedImages: {},
            });
            if (!isMobileButNotIPad) {
                this.scrollToTop();
            }
        });
    };

    handleTabChange = async (type) => {
        const { savedFormInput, changeSearchType, makeSearch, query } = this.props;
        const getQuery = query ? query : savedFormInput;
        await changeSearchType(type, 'AllResults :263');
        await makeSearch(getQuery);
    };

    scrollToTop() {
        setTimeout(() => {
            scrollToTop('instant');
        }, 50);
    }

    getPagePrivacyCardInfo = async (url) => {
        const { getPagePrivacyInfo } = this.props;
        const { privateInfoLoadingFor } = this.state;
        let webPagesSet = privateInfoLoadingFor;
        webPagesSet.add(url);
        this.setState({
            privateInfoLoadingFor: webPagesSet,
        });
        await getPagePrivacyInfo(url).then((response) => {
            this.setState({
                [url]: response,
            });
        });
    };

    clearPagePrivacyCardInfo = () => {
        const { webPages } = this.props;
        const { privateInfoLoadingFor } = this.state;
        if (webPages) {
            privateInfoLoadingFor.forEach((url, i) => {
                this.setState({
                    [url]: '',
                });
            });
        }
        this.setState({
            dataLoaded: false,
            openedPrivacyCards: new Set(),
            privateInfoLoadingFor: new Set(),
        });
    };

    setPrivateInfoLoadedStatus() {
        this.setState({
            dataLoaded: true,
        });
    }

    setOpenedPrivacyCards(url) {
        const { openedPrivacyCards } = this.state;
        let newOpenedPrivacyCards = openedPrivacyCards;
        newOpenedPrivacyCards.add(url);
        this.setState({
            openedPrivacyCards: newOpenedPrivacyCards,
        });
    }

    getDomain(item) {
        try {
            return item && parser(item)?.domain;
        } catch (e) {
            return item;
        }
    }

    handleWikiBoxOnLoad() {
        this.setState({
            wikiIsLoaded: true,
        });
    }

    getRelatedSearchUrl(query) {
        const plainQueryUrl = `/search?q=${query}`;
        const { publicKey, publicKeyVersion } = this.state;
        const { encryptionSetting } = this.props;
        if (!encryptionSetting || !query.length || !publicKey || !publicKeyVersion) {
            return plainQueryUrl;
        }
        const encryptedQuery = encrypt({ q: query }, publicKey, publicKeyVersion, 'Search');
        if (!encryptedQuery || !encryptedQuery.hasOwnProperty('encv') || !encryptedQuery.hasOwnProperty('encq')) {
            return plainQueryUrl;
        }
        return `/search?encq=${encryptedQuery.encq}&encv=${encryptedQuery.encv}`;
    }

    imagesWidgetCtaHandler() {
        this.props.submitMetrics({
            Category: category.serp,
            Action: action.click,
            Object: 'imagesWidget',
            Value: 'images',
            Extras: 'viewAll',
        });
        this.handleTabChange(searchTypes.images);
        this.scrollToTop();
    }

    renderInfoCards() {
        const {
            widgetType,
            footballWidget,
            cricketWidget,
            basketballWidget,
            instantAnswer,
            stocksWidget,
            currencyWidget,
            timezoneWidget,
            timezoneWidgetMetaData,
            widgetDataType,
            makeSearch,
            locationSetting,
            businessWidgetContext,
            wikiHowData,
            openInNewTab,
        } = this.props;
        const { page } = this.state;
        const showTimeZoneWidget =
            widgetType === constants.richHeaderTypes.timezone &&
            widgetDataType === constants.widgetDataTypes.timezoneCompare &&
            (page === 1 || isMobileButNotIPad) &&
            timezoneWidget?.source?.timezone &&
            timezoneWidget?.target?.timezone;
        const isNearMeSearch = businessWidgetContext?.nearme_loc;
        if (widgetType === constants.richHeaderTypes.soccer) {
            return (
                <FootballAndBasketballWidget
                    data={footballWidget}
                    widgetType={constants.richHeaderTypes.soccer}
                    data-test={'FootballWidget'}
                    makeSearch={makeSearch}
                />
            );
        } else if (widgetType === constants.richHeaderTypes.cricket) {
            return (
                cricketWidget?.rounds?.length > 0 && (
                    <FootballAndBasketballWidget
                        data={cricketWidget}
                        widgetType={constants.richHeaderTypes.cricket}
                        data-test={'CricketWidget'}
                        makeSearch={makeSearch}
                    />
                )
            );
        } else if (widgetType === constants.richHeaderTypes.basketball) {
            return (
                widgetType === constants.richHeaderTypes.basketball && (
                    <FootballAndBasketballWidget
                        data={basketballWidget}
                        widgetType={constants.richHeaderTypes.basketball}
                        data-test={'BasketballWidget'}
                        makeSearch={makeSearch}
                    />
                )
            );
        } else if (instantAnswer && !showTimeZoneWidget && instantAnswer.type === 'time') {
            return <InstantAnswerTimezoneWidget data={instantAnswer} />;
        } else if (instantAnswer && !showTimeZoneWidget && instantAnswer.type !== 'time') {
            return <InstantAnswerWidget data={instantAnswer} />;
        } else if (widgetType === constants.richHeaderTypes.stocks) {
            return <StockWidget data={stocksWidget} />;
        } else if (widgetType === constants.richHeaderTypes.weather) {
            return <WeatherWidget />;
        } else if (widgetType === constants.richHeaderTypes.currency) {
            return <CurrencyWidget data={currencyWidget} />;
        } else if (showTimeZoneWidget) {
            return <TimeConversionInfocard data={timezoneWidget} metadata={timezoneWidgetMetaData} />;
        } else if (
            (widgetType === constants.richHeaderTypes.location && !isNearMeSearch) ||
            (widgetType === constants.richHeaderTypes.location && isNearMeSearch && locationSetting)
        ) {
            return <BusinessWidget />;
        } else if (widgetType === constants.richHeaderTypes.wikihow) {
            return <WikiHow data={wikiHowData} openInNewTab={openInNewTab} />;
        }
    }

    renderMobilePagination() {
        const { t } = this.props;
        return (
            <Button
                className="search-result__pagination-button"
                onClick={this.choosePage.bind(this, this.state.page + 1)}
            >
                {t('pagination-moreresults')}
                <ArrowDownIcon />
            </Button>
        );
    }

    render() {
        const {
            webPages,
            widgetImages,
            widgetVideos,
            relatedSearches,
            wikiBox,
            openInNewTab,
            wikiBoxDataIsExpected,
            savedFormInput,
            t,
            privacyCardsSetting,
            query,
            queryContextContainer,
            rankingResponse,
            widgetType,
            wikiBoxLoadStatus,
            textAds,
            productCardAds,
            news,
            sideBarProductAds,
            sidebarTextAds,
            mobilePagesAreLoading,
            moviesInfoCard,
            moviesInfoCardMetaData,
            moviesCollectionInfoCard,
            moviesCollectionInfoCardMetaData,
            richHeaderLoadStatus,
            makeSearch,
            showErrorMessage,
            widgetDataType,
            businessWidget,
            businessWidgetMetaData,
            isAMPM,
            appLanguage,
            theme,
            getMoreBusinesses,
            widgetEntityType,
            businessWidgetContext,
        } = this.props;
        const { page, wikiIsLoaded } = this.state;
        const resultsFetched = webPages?.length > 0 || widgetType;
        const resultsFetchedAll = webPages?.length > 0;
        const shouldLoadInfoCards = (!isMobileButNotIPad && page === 1) || isMobileButNotIPad;
        const getPageSize = page === 1 ? firstPageSize : pageSize;
        const getTextAds = textAds?.slice(0, 2);
        const textAdsCount = getTextAds?.length;
        const queryContext = queryContextContainer && queryContextContainer[searchTypes.all];
        const isNearMeSearch = businessWidgetContext?.nearme_loc;
        const isMovieWidget =
            widgetType === constants.richHeaderTypes.movies && widgetDataType === constants.widgetDataTypes.movie;
        const isMovieCollectionWidget =
            moviesCollectionInfoCard &&
            widgetType === constants.richHeaderTypes.movies &&
            widgetDataType === constants.widgetDataTypes.collection;

        return (
            <section className="inner search-result__inner" data-test={'AllResults'}>
                <h1 className="sr-only">{t('header-category-all')}</h1>
                {shouldLoadInfoCards && resultsFetched && (
                    <TwoColumnsView>
                        <SearchResultSideContainer
                            wikiBoxDataIsExpected={wikiBoxDataIsExpected}
                            isNearMeSearch={isNearMeSearch}
                            wikiBoxLoadStatus={wikiBoxLoadStatus}
                            wikiIsLoaded={wikiIsLoaded}
                            wikiBox={wikiBox}
                            sidebarTextAds={sidebarTextAds}
                            page={page}
                            openInNewTab={openInNewTab}
                            sideBarProductAds={sideBarProductAds}
                            handleTabChange={this.handleTabChange.bind(this)}
                            handleWikiBoxOnLoad={this.handleWikiBoxOnLoad.bind(this)}
                            movieWidgetLoaded={(isMovieWidget || isMovieCollectionWidget) && shouldLoadInfoCards}
                            moviesInfoCard={moviesInfoCard}
                            moviesInfoCardMetaData={moviesInfoCardMetaData}
                            moviesCollectionInfoCard={moviesCollectionInfoCard}
                            moviesCollectionInfoCardMetaData={moviesCollectionInfoCardMetaData}
                            richHeaderLoadStatus={richHeaderLoadStatus}
                            widgetTypeIsMovie={isMovieWidget}
                            widgetTypeIsMovieCollection={isMovieCollectionWidget}
                            makeSearch={makeSearch}
                            businessWidget={businessWidget}
                            businessWidgetMetaData={businessWidgetMetaData}
                            isAMPM={isAMPM}
                            language={appLanguage}
                            theme={theme}
                            getMoreBusinesses={getMoreBusinesses}
                            widgetEntityType={widgetEntityType}
                        />
                    </TwoColumnsView>
                )}
                <div className="search-result__main">
                    {resultsFetched && !showErrorMessage && (
                        <>
                            {queryContext &&
                                (queryContext.alteredQuery ||
                                    (queryContext.alterationOverrideQuery &&
                                        queryContext.alterationOverrideQuery.toLowerCase() !==
                                            `+${queryContext.alterationDisplayQuery.toLowerCase()}`)) && (
                                    <Suspense fallback={<div></div>}>
                                        <SpellCheck queryContext={queryContext} type={searchTypes.all} />
                                    </Suspense>
                                )}
                            {shouldLoadInfoCards && (
                                <>
                                    <Suspense fallback={<div></div>}>{this.renderInfoCards()}</Suspense>
                                    {productCardAds && (
                                        <Suspense fallback={<div></div>}>
                                            <SimpleSliderWidget
                                                query={query ? query : savedFormInput}
                                                isMobile={isMobileButNotIPad}
                                                openInNewTab={openInNewTab}
                                                handleTabChange={this.handleTabChange.bind(this)}
                                                data-test={'SimpleSliderWidget'}
                                                type={constants.widgetTypes.ads}
                                                data={productCardAds}
                                                queryContext={queryContext}
                                            />
                                        </Suspense>
                                    )}
                                    {textAds &&
                                        getTextAds.map((ad, i) => {
                                            const domain = this.getDomain(ad.url);
                                            return (
                                                <SearchResultItem
                                                    key={`ad_${i}`}
                                                    isAd
                                                    item={ad}
                                                    page={page}
                                                    index={ad.resultIndex}
                                                    openInNewTab={openInNewTab}
                                                    domain={domain}
                                                    keyIndex={i}
                                                    isLastItem={textAdsCount - 1 === i}
                                                />
                                            );
                                        })}
                                </>
                            )}
                            {(page === 1 || isMobileButNotIPad) && (
                                <OneColumnView>
                                    <SearchResultSideContainer
                                        wikiBoxDataIsExpected={wikiBoxDataIsExpected}
                                        isNearMeSearch={isNearMeSearch}
                                        wikiBoxLoadStatus={wikiBoxLoadStatus}
                                        wikiIsLoaded={wikiIsLoaded}
                                        wikiBox={wikiBox}
                                        sidebarTextAds={sidebarTextAds}
                                        page={page}
                                        openInNewTab={openInNewTab}
                                        sideBarProductAds={sideBarProductAds}
                                        handleTabChange={this.handleTabChange.bind(this)}
                                        handleWikiBoxOnLoad={this.handleWikiBoxOnLoad.bind(this)}
                                        movieWidgetLoaded={
                                            (isMovieWidget || isMovieCollectionWidget) && shouldLoadInfoCards
                                        }
                                        moviesInfoCard={moviesInfoCard}
                                        moviesInfoCardMetaData={moviesInfoCardMetaData}
                                        moviesCollectionInfoCard={moviesCollectionInfoCard}
                                        moviesCollectionInfoCardMetaData={moviesCollectionInfoCardMetaData}
                                        richHeaderLoadStatus={richHeaderLoadStatus}
                                        widgetTypeIsMovie={isMovieWidget}
                                        widgetTypeIsMovieCollection={isMovieCollectionWidget}
                                        makeSearch={makeSearch}
                                        businessWidget={businessWidget}
                                        businessWidgetMetaData={businessWidgetMetaData}
                                        isAMPM={isAMPM}
                                        language={appLanguage}
                                        theme={theme}
                                        getMoreBusinesses={getMoreBusinesses}
                                        widgetEntityType={widgetEntityType}
                                    />
                                </OneColumnView>
                            )}
                            {rankingResponse.length > 0 &&
                                rankingResponse.map((item, i) => {
                                    if (
                                        item.answerType === 'WebPages' &&
                                        (i < getPageSize || isMobileButNotIPad) &&
                                        !!webPages[item?.resultIndex]
                                    ) {
                                        const domain = this.getDomain(webPages[item?.resultIndex ?? i]?.url);
                                        return (
                                            <SearchResultItem
                                                item={webPages[item?.resultIndex ?? i]}
                                                page={page}
                                                key={`searchResult_${item.resultIndex ?? i}`}
                                                index={item.resultIndex}
                                                openInNewTab={openInNewTab}
                                                privacyCardsSetting={privacyCardsSetting}
                                                getPagePrivacyInfo={this.getPagePrivacyCardInfo}
                                                privacyInfo={this.state[domain]}
                                                dataLoaded={this.state.dataLoaded}
                                                setPrivateInfoLoadedStatus={this.setPrivateInfoLoadedStatus.bind(this)}
                                                setOpenedPrivacyCards={this.setOpenedPrivacyCards.bind(this)}
                                                domain={domain}
                                                openedPrivacyCards={this.state.openedPrivacyCards}
                                                privateInfoLoadingFor={this.state.privateInfoLoadingFor}
                                                query={queryContext ? queryContext.originalQuery : query}
                                                keyIndex={i}
                                            />
                                        );
                                    } else if (
                                        item.answerType === 'Images' &&
                                        widgetImages?.value?.length > 0 &&
                                        (page === 1 || isMobileButNotIPad)
                                    ) {
                                        return (
                                            <div
                                                className="widget images-widget"
                                                data-test={'ImagesWidget'}
                                                key={`image_${i}`}
                                            >
                                                <WidgetTemplate.Header
                                                    titleText={t('images-for', 'Images')}
                                                    titleIconSrc={imagesIcon}
                                                    titleIconAlt="Images logo"
                                                    onTitleClickHandler={() => {
                                                        this.handleTabChange(searchTypes.images);
                                                        this.scrollToTop();
                                                    }}
                                                    onButtonClickHandler={() => {
                                                        this.handleTabChange(searchTypes.images);
                                                        this.scrollToTop();
                                                    }}
                                                    ctaText={t('btn-link-more', 'More')}
                                                />
                                                <div className="widget__list widget__images-list">
                                                    {widgetImages.value.length >= 3 &&
                                                        widgetImages.value.map((image, index) => {
                                                            const wrapperWidth = isMobileButNotIPad
                                                                ? (image.width * 120) / image.height
                                                                : (image.width * 200) / image.height;
                                                            const width = {
                                                                width: isMobileButNotIPad
                                                                    ? wrapperWidth > 200
                                                                        ? wrapperWidth / 2
                                                                        : wrapperWidth / 1.5
                                                                    : wrapperWidth > 300
                                                                    ? wrapperWidth / 2
                                                                    : wrapperWidth / 1.5,
                                                            };
                                                            return (
                                                                <Suspense
                                                                    key={index}
                                                                    fallback={<div key={index}></div>}
                                                                >
                                                                    <SearchImagesItem
                                                                        key={`image_${index}`}
                                                                        image={image}
                                                                        index={index}
                                                                        widgetWidth={width}
                                                                        openInNewTab={openInNewTab}
                                                                        handleTabChange={this.handleTabChange.bind(
                                                                            this,
                                                                        )}
                                                                        isWidgetImage
                                                                    />
                                                                </Suspense>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        );
                                    } else if (
                                        item.answerType === 'Videos' &&
                                        widgetVideos?.value?.length > 0 &&
                                        (page === 1 || isMobileButNotIPad)
                                    ) {
                                        return (
                                            <Suspense key={`video_{i}`} fallback={<div></div>}>
                                                <SimpleSliderWidget
                                                    query={query ? query : savedFormInput}
                                                    isMobile={isMobileButNotIPad}
                                                    openInNewTab={openInNewTab}
                                                    handleTabChange={this.handleTabChange.bind(this)}
                                                    data-test={'SimpleSliderWidget'}
                                                    type={constants.widgetTypes.videos}
                                                    data={widgetVideos}
                                                    queryContext={queryContext}
                                                />
                                            </Suspense>
                                        );
                                    } else if (item.answerType === 'News' && (page === 1 || isMobileButNotIPad)) {
                                        return (
                                            <Suspense key={`news_${i}`} fallback={<div></div>}>
                                                <SimpleSliderWidget
                                                    query={query ? query : savedFormInput}
                                                    isMobile={isMobileButNotIPad}
                                                    openInNewTab={openInNewTab}
                                                    handleTabChange={this.handleTabChange.bind(this)}
                                                    data-test={'SimpleSliderWidget'}
                                                    type={constants.widgetTypes.news}
                                                    data={news}
                                                />
                                            </Suspense>
                                        );
                                    }
                                    return null;
                                })}
                            <SmView>
                                <BackToTop />
                            </SmView>
                            {mobilePagesAreLoading && isMobileButNotIPad && (
                                <div className="loader">
                                    <div className="loader__body">
                                        <div className="loader__inner">
                                            <Loader />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isAndroid && (
                                <nav className="search-result__pagination">
                                    <h2 className="sr-only">{t('pagination-page-naviation', 'Page Navigation')}</h2>
                                    {this.renderMobilePagination()}
                                </nav>
                            )}
                            <div className="related-search-wrapper">
                                {!!relatedSearches?.value && resultsFetchedAll && (
                                    <section className="search-result__related-search related-search">
                                        <div className="related-search__texts">
                                            <WidgetTemplate.Header titleText={t('relatedsearch')} disabled />
                                        </div>
                                        <ul className="related-search__items">
                                            {!!relatedSearches &&
                                                relatedSearches.value.map((item) => {
                                                    return (
                                                        <a
                                                            key={item.text}
                                                            href={this.getRelatedSearchUrl(item.text)}
                                                            className="link related-search__item-link"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                this.searchRelatedQuery(item.text);
                                                                this.scrollToTop();
                                                            }}
                                                        >
                                                            <li className="related-search__item">
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: highlightWords(
                                                                            item.displayText || item.text,
                                                                        ),
                                                                    }}
                                                                ></span>
                                                                <RelatedSearchIcon className="related-search__icon" />
                                                            </li>
                                                        </a>
                                                    );
                                                })}
                                        </ul>
                                    </section>
                                )}
                                <nav className="search-result__pagination">
                                    <h2 className="sr-only">{t('pagination-page-naviation', 'Page Navigation')}</h2>
                                    {resultsFetchedAll && (
                                        <>
                                            <SmView>{!isAndroid && this.renderMobilePagination()}</SmView>
                                            <Suspense fallback={<div></div>}>
                                                <Pagination
                                                    ariaLabel={t('pagination-aria-all-result')}
                                                    page={this.state.page}
                                                    lastPage={this.state.lastPage}
                                                    choosePage={this.choosePage}
                                                />
                                            </Suspense>
                                        </>
                                    )}
                                </nav>
                            </div>
                        </>
                    )}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        webPages: getWebPagesChanges(state.search.webPages ?? []),
        mobilePagesAreLoading: state.search.mobilePagesAreLoading,
        totalEstimatedMatches: state.search.totalEstimatedMatches,
        widgetImages: state.search.widgetImages,
        widgetVideos: state.search.widgetVideos,
        relatedSearches: state.search.relatedSearches,
        wikiBox: state.search.wikiBox,
        query: state.search.query,
        savedFormInput: state.search.savedFormInput,
        searchLocation: state.search.searchLocation,
        searchPageNumber: state.search.searchPageNumber,
        filterByTime: state.search.filterByTime,
        searchParams: state.search.searchParams,
        showSuggestions: state.app.showSuggestions,
        openInNewTab: state.app.openInNewTab,
        weatherLocation: state.search.weatherLocation,
        stocksWidget: state.search.stocksWidget,
        footballWidget: state.search.footballWidget,
        basketballWidget: state.search.basketballWidget,
        currencyWidget: state.search.currencyWidget,
        cricketWidget: state.search.cricketWidget,
        encryptedQueryFromURL: state.search.encryptedQueryFromURL,
        queryFromUrl: state.search.queryFromUrl,
        privacyCardsSetting: state.app.privacyCardsSetting,
        queryContextContainer: state.search.queryContext,
        rankingResponse: getRankingResponseChanges(state.search.rankingResponse),
        widgetType: state.search.widgetType,
        widgetDataType: state.search.widgetDataType,
        wikiBoxLoadStatus: state.search.wikiBoxLoadStatus,
        richHeaderLoadStatus: state.search.richHeaderLoadStatus,
        textAds: state.search.textAds,
        news: state.search.widgetNews,
        productCardAds: state.search.productCardAds,
        sideBarProductAds: state.search.sideBarProductAds,
        sidebarTextAds: state.search.sidebarTextAds,
        wikiBoxDataIsExpected: state.search.wikiBoxDataIsExpected,
        publicKey: state.search.publicKey,
        publicKeyVersion: state.search.publicKeyVersion,
        encryptionSetting: state.app.encryptionSetting,
        instantAnswer: state.search.instantAnswer,
        wikiHowData: state.search.wikiHowData,
        moviesInfoCard: state.search.moviesInfoCard,
        moviesInfoCardMetaData: state.search.moviesInfoCardMetaData,
        moviesCollectionInfoCard: state.search.moviesCollectionInfoCard,
        moviesCollectionInfoCardMetaData: state.search.moviesCollectionInfoCardMetaData,
        timezoneWidget: state.search.timezoneWidget,
        timezoneWidgetMetaData: state.search.timezoneWidgetMetaData,
        businessWidget: state.search.businessWidget,
        isAMPM: state.search.isAMPM,
        locationSetting: state.app.locationSetting,
        businessWidgetContext: state.search.businessWidgetContext,
        businessWidgetMetaData: state.search.businessWidgetMetaData,
        theme: state.app.theme,
        appLanguage: state.app.appLanguage,
        widgetEntityType: state.search.widgetEntityType,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            searchRequest,
            clearWikiBox,
            changeSearchType,
            getPagePrivacyInfo,
            dispatch,
            submitMetrics,
            getMoreBusinesses,
        },
        dispatch,
    );
}

const imagesPropTypes = PropTypes.shape({
    id: PropTypes.string,
    isFamilyFriendly: PropTypes.bool,
    readLink: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            contentSize: PropTypes.string,
            contentUrl: PropTypes.string,
            datePublished: PropTypes.string,
            encodingFormat: PropTypes.string,
            height: PropTypes.number,
            hostPageDisplayUrl: PropTypes.string,
            hostPageUrl: PropTypes.string,
            name: PropTypes.string,
            thumbnail: PropTypes.shape({
                height: PropTypes.number,
                width: PropTypes.number,
            }),
            thumbnailUrl: PropTypes.string,
            webSearchUrl: PropTypes.string,
            width: PropTypes.number,
        }),
    ),
    webSearchUrl: PropTypes.string,
});

const videoPropTypes = PropTypes.shape({
    id: PropTypes.string,
    isFamilyFriendly: PropTypes.bool,
    readLink: PropTypes.string,
    scenario: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            allowHttpsEmbed: PropTypes.bool,
            allowMobileEmbed: PropTypes.bool,
            contentUrl: PropTypes.string,
            datePublished: PropTypes.string,
            description: PropTypes.string,
            duration: PropTypes.string,
            embedHtml: PropTypes.string,
            encodingFormat: PropTypes.string,
            height: PropTypes.number,
            hostPageDisplayUrl: PropTypes.string,
            hostPageUrl: PropTypes.string,
            isSuperfresh: PropTypes.bool,
            motionThumbnailUrl: PropTypes.string,
            name: PropTypes.string,
            publisher: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                }),
            ),
            thumbnail: PropTypes.shape({
                height: PropTypes.number,
                width: PropTypes.number,
            }),
            thumbnailUrl: PropTypes.string,
            webSearchUrl: PropTypes.string,
            width: PropTypes.number,
        }),
    ),
    webSearchUrl: PropTypes.string,
});

const relatedSearchPropTypes = PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            displayText: PropTypes.string,
            text: PropTypes.string,
            webSearchUrl: PropTypes.string,
        }),
    ),
});

const wikiBoxPropTypes = PropTypes.shape({
    value: PropTypes.arrayOf(
        PropTypes.shape({
            bingId: PropTypes.string,
            contractualRules: PropTypes.arrayOf(
                PropTypes.shape({
                    license: PropTypes.shape({
                        name: PropTypes.string,
                        url: PropTypes.string,
                    }),
                    licenseNotice: PropTypes.string,
                    mustBeCloseToContent: PropTypes.bool,
                    targetPropertyName: PropTypes.string,
                }),
            ),
            description: PropTypes.string,
            entityPresentationInfo: PropTypes.shape({
                entityScenario: PropTypes.string,
                entityTypeHints: PropTypes.arrayOf(PropTypes.string),
            }),
            id: PropTypes.string,
            image: PropTypes.shape({
                height: PropTypes.number,
                hostPageUrl: PropTypes.string,
                name: PropTypes.string,
                provider: PropTypes.arrayOf(
                    PropTypes.shape({
                        url: PropTypes.string,
                    }),
                ),
                sourceHeight: PropTypes.number,
                sourceWidth: PropTypes.number,
                thumbnailUrl: PropTypes.string,
                width: PropTypes.number,
            }),
            name: PropTypes.string,
            url: PropTypes.string,
            webSearchUrl: PropTypes.string,
        }),
    ),
});

AllResults.propTypes = {
    webPages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    widgetImages: PropTypes.oneOfType([PropTypes.array, imagesPropTypes]),
    widgetVideos: PropTypes.oneOfType([PropTypes.array, videoPropTypes]),
    relatedSearches: PropTypes.oneOfType([PropTypes.array, relatedSearchPropTypes]),
    wikiBox: PropTypes.oneOfType([PropTypes.array, wikiBoxPropTypes]),
    openInNewTab: PropTypes.bool,
    weatherLocation: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    savedFormInput: PropTypes.string,
    t: PropTypes.func,
    footballWidget: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    basketballWidget: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AllResults));
