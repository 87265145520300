import './loading.scss';

import React from 'react';

import SkeletonTemplate from '../SkeletonTemplate/SkeletonTemplate';
import WidgetTemplate from '../WidgetTemplate/WidgetTemplate';

function WeatherWidgetLoading(props) {
    return (
        <WidgetTemplate.Wrapper>
            <WidgetTemplate.Paper>
                <div className="weather-widget-skeleton__top">
                    <div className="weather-widget-skeleton__block-1">
                        <SkeletonTemplate.Item className="weather-widget-skeleton__item-1-1" type="line" color="dark" />
                        <SkeletonTemplate.Item className="weather-widget-skeleton__item-1-2" type="line" color="dark" />
                    </div>
                    <div className="weather-widget-skeleton__group">
                        <SkeletonTemplate.Item className="weather-widget-skeleton__square" type="line" color="dark" />
                        <div className="weather-widget-skeleton__block-2">
                            <SkeletonTemplate.Item
                                className="weather-widget-skeleton__item-2-1"
                                type="line"
                                color="dark"
                            />
                            <SkeletonTemplate.Item
                                className="weather-widget-skeleton__item-2-2"
                                type="line"
                                color="dark"
                            />
                        </div>
                        <div className="weather-widget-skeleton__block-3">
                            {Array.from({ length: 4 }).map((value, index) => (
                                <SkeletonTemplate.Item
                                    className="weather-widget-skeleton__item-3"
                                    key={index}
                                    type="line"
                                    color="dark"
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <SkeletonTemplate.Item className="weather-widget-skeleton__field" type="gradient-field" />
                <WidgetTemplate.Block className="weather-widget-skeleton__cards">
                    {Array.from({ length: 8 }).map((value, index) => (
                        <SkeletonTemplate.Item className="weather-widget-skeleton__card" key={index} type="line" />
                    ))}
                </WidgetTemplate.Block>
            </WidgetTemplate.Paper>
            <SkeletonTemplate.Footer />
        </WidgetTemplate.Wrapper>
    );
}

export default WeatherWidgetLoading;
