import './SwiperSlider.scss';

import React, { useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import 'swiper/components/pagination/pagination.min.css';

/* TODO: Refactor to TS*/
SwiperCore.use([Navigation, Pagination]);

const sliderDefaultConfig = {
    slidesPerView: 'auto',
    speed: 450,
};

function SwiperSlider({ className, children, config = {} }) {
    const sliderConfig = { ...sliderDefaultConfig, ...config };
    const sliderInstance = useRef(null);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);

    useEffect(() => {
        handleSlideChange();
    }, [children]);

    const changeSlide = (navigationButtonType) => {
        if (sliderInstance.current) {
            navigationButtonType === 'prev' && sliderInstance.current.slidePrev();

            navigationButtonType === 'next' && sliderInstance.current.slideNext();
        }
    };

    const handleSlideChange = () => {
        setIsNextButtonDisabled(sliderInstance.current.isEnd);
        setIsPrevButtonDisabled(sliderInstance.current.isBeginning);
    };

    return (
        <div className={`swiper-component${className ? ` ${className}` : ''}`}>
            <Swiper
                {...sliderConfig}
                onSwiper={(swiper) => (sliderInstance.current = swiper)}
                onSlideChange={handleSlideChange}
                modules={[Pagination]}
            >
                {React.Children.map(children, (child, ind) => (
                    <SwiperSlide key={ind}>{child}</SwiperSlide>
                ))}
            </Swiper>
            <button
                type="button"
                className="swiper-arrow swiper-arrow--prev"
                onClick={() => changeSlide('prev')}
                disabled={isPrevButtonDisabled}
            />
            <button
                type="button"
                className="swiper-arrow swiper-arrow--next"
                onClick={() => changeSlide('next')}
                disabled={isNextButtonDisabled}
            />
        </div>
    );
}

export { SwiperSlider };
