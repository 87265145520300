import { useMediaQuery } from 'react-responsive';

import breakpoints from '../../../../../constants/breakpoints';

const SmView = ({ children }) => {
    const isSmView = useMediaQuery({
        maxWidth: breakpoints.maxWidthSmBreakPoint,
    });
    return <>{isSmView && children}</>;
};

export default SmView;
