import './FeedbackForm.scss';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isAndroid } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

import { sendFeedback } from '../../../actions/app';

import { ReactComponent as CloseIcon } from '../../images/icons/close-icon.svg';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import Radiobutton from '../Radiobutton/Radiobutton';

const feedbackOptions = ['This is not relevant', 'The information is not accurate', 'Something is wrong with the text'];

export class FeedbackForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            sendSearchTerm: true,
        };
        this.formRef = React.createRef();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { language, searchLocation, query, sendFeedback, onClose } = this.props;
        let data = {
            userInput: this.state.text,
            selection: this.state.feedbackOption,
        };
        let params = {};
        if (this.state.sendSearchTerm) {
            data = {
                ...data,
                query,
            };
            params = {
                lang: language,
                mkt: searchLocation.mcode,
            };
        }
        sendFeedback(params, data);
        onClose();
    }

    onValueChange(e) {
        this.setState({ feedbackOption: e.target.value });
    }

    onSendSearchTermChange(e) {
        this.setState({ sendSearchTerm: !this.state.sendSearchTerm });
    }

    onTextChange(e) {
        this.setState({ text: e.target.value });
    }

    scrollBottom() {
        if (!isAndroid) {
            return;
        }
        setTimeout(() => {
            if (this.formRef.current.clientHeight > window.innerHeight) {
                const x = this.formRef.current.clientHeight - window.innerHeight;
                this.formRef.current.style.bottom = `${x}px`;
            }
        }, 300);
    }

    scrollTop() {
        this.formRef.current.style.bottom = 'auto';
    }

    render() {
        const { onClose, t } = this.props;
        const { feedbackOption, sendSearchTerm } = this.state;

        return (
            <div className="popup-box" ref={this.formRef}>
                <OutsideAlerter onClick={onClose}>
                    <>
                        {onClose && (
                            <Button className="popup-box__close-button" onClick={onClose}>
                                <CloseIcon />
                            </Button>
                        )}
                        <div className="popup-box__inner">
                            <div className="popup-box__title">
                                {t('feedback-dialog-help-us-improve', 'Help us improve Tempest')}
                            </div>
                            <div className="popup-box__subtitle">
                                {t('feedback-dialog-what-do-you-think', 'What do you think of the information shown?')}
                            </div>
                            <form onSubmit={this.handleSubmit.bind(this)}>
                                <div className="popup-box__radio-list">
                                    <div className="popup-box__radio-list-item">
                                        <Radiobutton
                                            text={t('feedback-dialog-not-relevant', 'This is not relevant')}
                                            name="feedback-options"
                                            value={feedbackOptions[0]}
                                            checked={feedbackOption === feedbackOptions[0]}
                                            onChange={this.onValueChange.bind(this)}
                                            onFocus={this.scrollTop.bind(this)}
                                        />
                                    </div>
                                    <div className="popup-box__radio-list-item">
                                        <Radiobutton
                                            text={t(
                                                'feedback-dialog-not-accurate',
                                                'The information displayed on this page is not accurate',
                                            )}
                                            name="feedback-options"
                                            value={feedbackOptions[1]}
                                            checked={feedbackOption === feedbackOptions[1]}
                                            onChange={this.onValueChange.bind(this)}
                                            onFocus={this.scrollTop.bind(this)}
                                        />
                                    </div>
                                    <div className="popup-box__radio-list-item">
                                        <Radiobutton
                                            text={t(
                                                'feedback-dialog-something-wrong',
                                                'Something is wrong with the text',
                                            )}
                                            name="feedback-options"
                                            value={feedbackOptions[2]}
                                            checked={feedbackOption === feedbackOptions[2]}
                                            onChange={this.onValueChange.bind(this)}
                                            onFocus={this.scrollTop.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="popup-box__subtitle">
                                    {t('feedback-dialog-comments', 'Comments or suggestions')}
                                </div>
                                <textarea
                                    className="popup-box__textarea"
                                    onChange={this.onTextChange.bind(this)}
                                    onFocus={this.scrollBottom.bind(this)}
                                />
                                <Checkbox
                                    className="popup-box__checkbox"
                                    labelText={t(
                                        'feedback-dialog-send-my-search-term',
                                        'Send my search term along with this anonymous feedback',
                                    )}
                                    isReversed
                                    checked
                                    value={sendSearchTerm}
                                    onChange={this.onSendSearchTermChange.bind(this)}
                                />
                                <Button className="popup-box__button" type="submit" disabled={!feedbackOption}>
                                    {t('feedback-dialog-submit', 'Submit')}
                                </Button>
                            </form>
                        </div>
                    </>
                </OutsideAlerter>
            </div>
        );
    }
}

FeedbackForm.propTypes = {
    onClose: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
    language: state.app.appLanguage,
    searchLocation: state.search.searchLocation,
    query: state.search.query,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            sendFeedback,
            dispatch,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(FeedbackForm));
