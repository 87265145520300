import './SingleBusinessCardSkeleton.scss';

import React from 'react';
import { useMediaQuery } from 'react-responsive';

import SkeletonTemplate from '../../../SkeletonTemplate/SkeletonTemplate';
import WidgetTemplate from '../../../WidgetTemplate/WidgetTemplate';

function SingleBusinessCardSkeleton() {
    const isMobile = useMediaQuery({ maxWidth: 759 });

    return (
        <WidgetTemplate.Wrapper>
            <WidgetTemplate.Paper className="single-business-skeleton">
                <div className="single-business-skeleton__top">
                    <div className="single-business-skeleton__top-part-1">
                        <SkeletonTemplate.Item
                            className="single-business-skeleton__top-item-1"
                            type="line"
                            color="dark"
                        />
                        <SkeletonTemplate.Item
                            className="single-business-skeleton__top-item-2"
                            type="line"
                            color="dark"
                        />
                        <SkeletonTemplate.Item
                            className="single-business-skeleton__top-item-3"
                            type="line"
                            color="dark"
                        />
                        {isMobile && (
                            <>
                                <SkeletonTemplate.Item
                                    className="single-business-skeleton__top-item-4"
                                    type="line"
                                    color="dark"
                                />
                                <SkeletonTemplate.Item
                                    className="single-business-skeleton__top-item-5"
                                    type="line"
                                    color="dark"
                                />
                                <SkeletonTemplate.Item
                                    className="single-business-skeleton__top-item-6"
                                    type="line"
                                    color="dark"
                                />
                            </>
                        )}
                    </div>
                    <SkeletonTemplate.Item className="single-business-skeleton__top-part-2" type="gradient-field" />
                </div>
                <WidgetTemplate.Block className="single-business-skeleton__block single-business-skeleton__block--1">
                    {Array.from({ length: 3 }).map((value, index) => (
                        <div className="single-business-skeleton__part-1-1" key={index}>
                            <SkeletonTemplate.Item className="single-business-skeleton__item-1-1" type="line" />
                            <SkeletonTemplate.Item className="single-business-skeleton__item-1-2" type="line" />
                        </div>
                    ))}
                </WidgetTemplate.Block>
                <WidgetTemplate.Block className="single-business-skeleton__block single-business-skeleton__block--2">
                    {Array.from({ length: 3 }).map((value, index) => (
                        <div className="single-business-skeleton__part-2-1" key={index}>
                            <SkeletonTemplate.Item className="single-business-skeleton__item-2-1" type="line" />
                            <SkeletonTemplate.Item className="single-business-skeleton__item-2-1" type="line" />
                        </div>
                    ))}
                </WidgetTemplate.Block>
                <WidgetTemplate.Block className="single-business-skeleton__block single-business-skeleton__block--3">
                    <SkeletonTemplate.Item className="single-business-skeleton__item-3-1" type="line" />
                    <div className="single-business-skeleton__part-3-1">
                        <SkeletonTemplate.Item className="single-business-skeleton__circle" type="circle" />
                        <SkeletonTemplate.Item className="single-business-skeleton__item-3-2" type="line" />
                    </div>
                    <SkeletonTemplate.Item className="single-business-skeleton__item-3-3" type="line" />
                    <SkeletonTemplate.Item className="single-business-skeleton__item-3-3" type="line" />
                    <SkeletonTemplate.Item className="single-business-skeleton__item-3-4" type="line" />
                    <SkeletonTemplate.Item className="single-business-skeleton__item-3-5" type="line" />
                </WidgetTemplate.Block>
            </WidgetTemplate.Paper>
            <SkeletonTemplate.Footer />
        </WidgetTemplate.Wrapper>
    );
}

export default SingleBusinessCardSkeleton;
