import './SearchResult.scss';

import React, { PureComponent, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeSearchType, historyPush, setRecentActionData } from '../../actions/app';
import { changePageType } from '../../actions/app';
import {
    clearImages,
    clearNewsPages,
    clearState,
    clearSuggestions,
    clearVideos,
    clearWebPages,
    readQueryFromURL,
    runSearch,
    setSearchPageNumber,
} from '../../actions/search';

// import AppleMapsDetailedView from '../../common/components/AppleMaps/AppleMapsDetailedView';
import InfoComponent from '../../common/components/InfoComponent';
import Layout from '../../common/components/Layout/Layout';
import AllResults from './components/AllResults/AllResults';

import appErrorTypes from '../../constants/appErrorTypes';
import companyConfigs from '../../constants/company';
import searchTypes from '../../constants/searchTypes';

const NewsResults = React.lazy(() => import('./lazyComponents/lazyNewsResults.js'));
const ImageResults = React.lazy(() => import('./lazyComponents/lazyImageResults.js'));
const VideoResults = React.lazy(() => import('./lazyComponents/lazyVideoResults.js'));
const AppleMapsDetailedView = React.lazy(() => import('../../common/components/AppleMaps/AppleMapsDetailedView'));

class SearchResult extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            key: 0,
            myRequestedRefs: 0,
            resultsLength: 0,
            newSearch: true,
            isBackAction: false,
            imageSideBarIsClosed: true,
        };
    }

    getImageSideBarClosedStatus(status) {
        this.setState({
            imageSideBarIsClosed: status,
        });
    }

    getRefsFromChild(resultsLength) {
        this.setState({
            resultsLength: resultsLength,
        });
    }

    stateCleaningRequired = (query) => {
        const { savedFormInput } = this.props;
        return query !== savedFormInput;
    };

    makeSearch(input, offset, src, relatedSearch, seeMoreSearch, extraParams) {
        const {
            clearSuggestions,
            clearVideos,
            clearImages,
            runSearch,
            searchType,
            encryptedQueryFromURL,
            encryptedQueryKeyVersion,
            encryptionSetting,
        } = this.props;
        let getQuery;
        if (relatedSearch) {
            this.setState({
                imageSideBarIsClosed: true,
            });
        }

        if (!encryptedQueryFromURL || (input && !input.encq)) {
            getQuery = (input.search_field ? input.search_field : input).trim();
        } else if (encryptedQueryFromURL && (!input || input.encq)) {
            getQuery = {
                encq: encryptedQueryFromURL,
                encv: encryptedQueryKeyVersion,
            };
        }
        if (this.stateCleaningRequired(getQuery)) {
            if (searchType === searchTypes.images && !relatedSearch) {
                clearImages();
            } else if (searchType === searchTypes.videos) {
                clearVideos();
            }
        }
        if ((encryptedQueryFromURL && encryptionSetting) || input) {
            Promise.all([
                runSearch({
                    query: getQuery,
                    offset: isNaN(offset) ? '' : offset,
                    isEncryptedQueryFromURL: !!encryptedQueryFromURL && !input,
                    relatedSearch,
                    seeMoreSearch,
                    extraParams,
                }),
            ]).then(() => {
                clearSuggestions();
                this.setState({
                    newSearch: true,
                });
            });
        }
    }

    render() {
        const {
            searchType,
            query,
            savedFormInput,
            showErrorMessage,
            widgetType,
            webPages,
            timeToShowFooter,
            dataFetching,
        } = this.props;
        const isResultView = searchType === searchTypes.all;
        const isNewsView = searchType === searchTypes.news;
        const isImagesView = searchType === searchTypes.images;
        const isVideosView = searchType === searchTypes.videos;
        const isMapView = searchType === searchTypes.map;
        const allResultsErrorMessage = showErrorMessage && !widgetType;
        const widgetOnlyMode =
            showErrorMessage === appErrorTypes.searchErrors.noResultsInData &&
            widgetType &&
            searchType === searchTypes.all;

        const noResultsInData = showErrorMessage === appErrorTypes.searchErrors.noResultsInData;

        return (
            <div data-test={'SearchResult'}>
                <Layout
                    className={isNewsView ? 'news-result' : 'search-result'}
                    withHeader
                    withFooter={isResultView || isNewsView || isVideosView || isImagesView}
                    isReady={!dataFetching}
                    makeSearch={this.makeSearch.bind(this)}
                    newSearch={this.state.newSearch}
                    withFeedbackButton={noResultsInData || !showErrorMessage || (isResultView && webPages?.length > 0)}
                    timeToShowFooter={timeToShowFooter}
                >
                    {showErrorMessage && !widgetOnlyMode && (
                        <InfoComponent
                            query={query ? query : savedFormInput}
                            type={showErrorMessage}
                            searchType={searchType}
                            makeSearch={this.makeSearch.bind(this)}
                        />
                    )}
                    {isResultView && !allResultsErrorMessage && (
                        <AllResults
                            passRefUpward={this.getRefsFromChild.bind(this)}
                            makeSearch={this.makeSearch.bind(this)}
                            showErrorMessage={allResultsErrorMessage}
                        />
                    )}
                    {isNewsView && (
                        <Suspense fallback={<div> </div>}>
                            <NewsResults
                                makeSearch={this.makeSearch.bind(this)}
                                passRefUpward={this.getRefsFromChild.bind(this)}
                            />
                        </Suspense>
                    )}
                    {isImagesView && (
                        <Suspense fallback={<div> </div>}>
                            <ImageResults
                                isBackAction={this.state.isBackAction}
                                passRefUpward={this.getRefsFromChild.bind(this)}
                                getImageSideBarClosedStatus={this.getImageSideBarClosedStatus.bind(this)}
                                doSearch={this.makeSearch.bind(this)}
                                openInNewTab={true}
                            />
                        </Suspense>
                    )}
                    {isVideosView && (
                        <Suspense fallback={<div> </div>}>
                            <VideoResults passRefUpward={this.getRefsFromChild.bind(this)} />
                        </Suspense>
                    )}
                    {companyConfigs?.features?.withAppleMapsDetailedView && (
                        <Suspense fallback={<div> </div>}>
                            <AppleMapsDetailedView open={isMapView} />
                        </Suspense>
                    )}
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    searchType: state.app.searchType,
    query: state.search.query,
    searchParams: state.search.searchParams,
    queryFromUrl: state.search.queryFromUrl,
    searchLocation: state.search.searchLocation,
    searchPageNumber: state.search.searchPageNumber,
    savedFormInput: state.search.savedFormInput,
    encryptedQueryFromURL: state.search.encryptedQueryFromURL,
    encryptedQueryKeyVersion: state.search.encryptedQueryKeyVersion,
    encryptionSetting: state.app.encryptionSetting,
    webPages: state.search.webPages,
    images: state.search.images,
    videos: state.search.videos,
    news: state.search.news,
    showErrorMessage: state.search.showErrorMessage,
    widgetType: state.search.widgetType,
    timeToShowFooter: state.app.timeToShowFooter,
    dataFetching: state.search.dataFetching,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeSearchType,
            runSearch,
            readQueryFromURL,
            setSearchPageNumber,
            clearImages,
            clearVideos,
            clearState,
            clearWebPages,
            clearSuggestions,
            clearNewsPages,
            changePageType,
            historyPush,
            setRecentActionData,
            dispatch,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
