import getSymbolFromCurrency from 'currency-symbol-map';
import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';
import { extendMoment } from 'moment-range';
import React from 'react';

import { getAPIBase } from '../actions/axiosConfig';

import userDefaultLogoDark from '../common/images/icons/default-avatar-dark.svg';
import userDefaultLogoLight from '../common/images/icons/default-avatar-light.svg';
import { ReactComponent as PinIcon } from '../common/images/icons/pin-location-search-icon.svg';

const momentRange = extendMoment(moment);

export const storageAvailable = (src) => {
    try {
        var storage = window['localStorage'],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
};

export const getTranslationForSelectOptions = (array, t) => {
    return array.map((option) => {
        /**
         * Individual labels for color options to be able
         * to give them individual CSS classes to style
         * them according to our needs.
         */
        if (option.hasOwnProperty('colorOption')) {
            return {
                value: option.value,
                label: <em className={option.className}>{option.key ? t(option.key) : option.label}</em>,
            };
        } else {
            return {
                label: option.key ? t(option.key) : option.label,
                value: option.value,
                className: option.className ? option.className : '',
            };
        }
    });
};

export const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

// TODO: Move `currentValue` parameter into the end
export const saveToLocalStorage = (currentValue, key, value) => {
    if (currentValue !== value && storageAvailable('saveToLocalStorage')) {
        localStorage.setItem(key, value);
    }
};

export const getFromLocalStorage = (key, shouldParse = false) => {
    if (storageAvailable('getFromLocalStorage')) {
        const value = localStorage.getItem(key);
        return shouldParse && typeof value === 'string' ? JSON.parse(value) : value;
    }
};

export const removeFromLocalStorage = (key) => {
    if (storageAvailable('removeFromLocalStorage')) {
        localStorage.removeItem(key);
    }
};

export const getHostnameWithProtocolFromRegex = (url) => {
    // eslint-disable-next-line
    const matches = url.match(/^(https?\:\/\/[^\/?#]+)(?:[\/?#]|$)/i);
    return matches && matches[1];
};

export const removeProtocolFromTheURL = (url) => {
    // eslint-disable-next-line
    return url.replace(/^(https?:|)\/\//, '').replace(/\/$/, '');
};

export const formatCountry = (country, theme, isSelectedComponent) => {
    const getClassName = isSelectedComponent
        ? 'header__countries-option countries-option__selected-item'
        : 'header__countries-option countries-option';
    const darkIcon = theme === 'dark' && country.code === '00';
    const imgUrl = `images/flags/4x3/${country.code.toLowerCase()}${darkIcon ? '_dark' : ''}.svg`;

    return {
        value: country.name,
        label: (
            <div className={getClassName}>
                {country.code && <img src={imgUrl} alt={country.name} className="countries-option__image" />}
                <em className="countries-option__name">{country.name}</em>
            </div>
        ),
        name: country.name,
        mcode: country.mcode,
        code: country.code,
    };
};

export const formatLocationPopupOptionsLabel = (value) => {
    return (
        <>
            <span className="option-icon">
                <PinIcon className="option-icon-svg" />
            </span>
            <span className="option-text">{value}</span>
        </>
    );
};

export const findCurrencyIcon = (currency) => {
    // const rateCountry = currencies.find(x => x.currency_code === currency.code)
    // const findCountry = rateCountry && countries.find(x => rateCountry.country.includes(x.name))
    // if (findCountry) {
    //   return <img alt='Country flag' src={`/images/flags/4x3/${findCountry.code.toLowerCase()}.svg`} className="currency-widget__select-icon"/>
    // } else if (currency.code === 'EUR') {
    //   return <img alt='Country flag' src={`/images/flags/4x3/eu.svg`} className="currency-widget__select-icon"/>
    // } else if (currency.code === 'USD') {
    //   return <img alt='Country flag' src={`/images/flags/4x3/us.svg`} className="currency-widget__select-icon"/>
    // }
    return <span className={'currency-widget__converter-icon'}>{getSymbolFromCurrency(currency.code)}</span>;
};

export const formatCurrencies = (rates) => {
    // Temporary fix
    const duplicatedNameCodes = ['ZMK', 'ZMW', 'CNH', 'CNY'];
    return rates.map((rate) => {
        const currencyIcon = findCurrencyIcon(rate);
        const name = duplicatedNameCodes.includes(rate.code) ? `${rate.name} ${rate.code}` : rate.name;
        return {
            value: rate.code,
            name: name,
            code: rate.code,
            icon: currencyIcon,
            label: (
                <span className="currency-widget__select-item">
                    <span className="currency-widget__select--value">{name}</span>
                </span>
            ),
        };
    });
};

export const chunkArrayInGroups = (arr, size) => {
    var newArray = [];
    for (var i = 0; i < arr.length; i += size) {
        newArray.push(arr.slice(i, i + size));
    }
    return newArray;
};

export const getMomentCalendarSettings = (language) => {
    const localisedTitles = (function (language) {
        const titles = {
            'de-DE': {
                sameDay: '[Heute]',
                nextDay: '[Morgen]',
                lastDay: '[Gestern]',
            },
            'pt-PT': {
                sameDay: '[Hoje]',
                nextDay: '[Amanhã]',
                lastDay: '[Ontem]',
            },
            'es-ES': {
                sameDay: '[Hoy]',
                nextDay: '[Mañana]',
                lastDay: '[Ayer]',
            },
            'fr-FR': {
                sameDay: "[Aujourd'hui]",
                nextDay: '[Demain]',
                lastDay: '[Hier]',
            },
            'en-EN': {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                lastDay: '[Yesterday]',
            },
        };
        if (titles.hasOwnProperty(language)) {
            return titles[language];
        }
        return titles['en-EN'];
    })(language);
    function regularFormat(now) {
        if (this.isAfter(now, 'year')) {
            return 'ddd, DD MMM YYYY';
        } else {
            return 'ddd, DD MMM';
        }
    }
    return {
        sameDay: localisedTitles.sameDay,
        nextDay: localisedTitles.nextDay,
        nextWeek: regularFormat,
        lastDay: localisedTitles.lastDay,
        lastWeek: regularFormat,
        sameElse: regularFormat,
    };
};

export const getChartTickBasedOnRange = ({ data, interval, step, stepsCount, timeStart, timeEnd, isMobile }) => {
    const getTimeDifference = data[1]?.dt ? data[1].dt - data[0].dt : data[0].dt;
    const isHourlyData = getTimeDifference / 3600 === 1;
    const dataLength = data.length;
    let getSteps = step;
    if (dataLength <= stepsCount) {
        return Array.from(data, (x) => x.dt);
    }
    const time_start = moment.unix(timeStart);
    const time_end = moment.unix(timeEnd);
    if (step && !isHourlyData) {
        getSteps = Math.trunc(step ? step : dataLength / stepsCount);
    } else if (isMobile) {
        getSteps = Math.trunc(dataLength / stepsCount);
    }

    const timeRange = momentRange.range(time_start, time_end);
    let time_slots = Array.from(timeRange.by(interval, { step: getSteps }));

    if (stepsCount) {
        let i = time_slots.length >= stepsCount ? stepsCount : time_slots.length;
        time_slots = time_slots.slice(0, i);
    }
    const ticks = Array.from(time_slots, (x) => x.unix());
    return ticks;
};

export const stripUnicodeChars = (value) => {
    if (!value) {
        return value;
    }
    return value.replace(/[\uE000-\uE019]/g, '');
};

export const highlightWords = (text) => {
    if (!text) {
        return text;
    }
    return text.replace(/\uE000/g, '<mark class="hl">').replace(/\uE001/g, '</mark>');
};

export const validatePublicKey = (publicKey, publicKeyExpirationTime, publicKeyDomain) => {
    return (
        publicKeyExpirationTime &&
        publicKeyExpirationTime > moment().unix() &&
        publicKey &&
        publicKeyDomain === getAPIBase()
    );
};

export const getIconPath = (iconId, isBig) => {
    const size = isBig ? 'Big' : 'Small';
    // eslint-disable-next-line eqeqeq -- can't be fixed because it used to detect iconId kind
    if (!iconId || typeof iconId !== 'string' || parseInt(iconId) != iconId) {
        return `/images/weatherWidgetIcons/${size}/${iconId}.svg`;
    }
    const id = iconId.padStart(2, '0');
    return `/images/weatherWidgetIcons/Accuweather${size}/${id}.svg`;
};

export const freezeBody = (noFixedBody = false) => {
    const body = getHtmlBody();
    const el = getScrollingHalf();
    body.style.overflow = 'hidden';
    if (!noFixedBody) {
        body.style.position = 'fixed';
    }
    if (!!el) {
        el.style.visibility = 'hidden';
    }
};

export const unfreezeBody = () => {
    const body = getHtmlBody();
    const el = getScrollingHalf();
    // body.style.overflow = 'auto';
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    if (!!el) {
        el.style.removeProperty('visibility');
    }
};

const getScrollingHalf = () => {
    return document.querySelector('.scrolling-half');
};

const getHtmlBody = () => {
    const htmlBody = document.body;
    return htmlBody;
};

export const scrollToTop = (behavior = 'smooth') => {
    // @ts-ignore
    window.scrollTo({ top: 0, behavior: behavior });
};

export const addBodyClass = (className) => {
    if (className) {
        getHtmlBody().classList.add(className);
    }
};

export const removeBodyClass = (className) => {
    if (className) {
        const body = getHtmlBody();
        const bodyClassList = body.classList;
        bodyClassList.remove(className);
        if (bodyClassList.length === 0) {
            body.removeAttribute('class');
        }
    }
};

export const unixDatetimeByTimezone = (timezone) => {
    const getUTCOffset = typeof timezone !== 'number' ? 0 : timezone;
    return moment().utcOffset(getUTCOffset);
};

export const getDateTimeFromUnix = ({ timestamp, utcOffset, format }) => {
    const dateTime = moment.unix(timestamp);
    if (utcOffset) {
        dateTime.utcOffset(utcOffset);
    }
    if (format) {
        const formattedDateTime = dateTime.format(format);
        return formattedDateTime;
    }
    return dateTime;
};

export const isDarkThemeEnabled = () => document.querySelector('html').classList.contains('dark-theme');

export const safeParsePhoneNumber = (phone) => {
    if (!phone) {
        return;
    }
    try {
        return parsePhoneNumber(phone)?.formatInternational();
    } catch (e) {
        return phone;
    }
};

export const handleBusinessCardUserAvatar = (avatar) => {
    const noAvatar = [
        'https://s3-media-yelp.tempest.com/assets/public/user_60_square.yji-514f6997a3184af475d5adc800b6d0b1.png',
        null,
        undefined,
        '',
    ].some((item) => item === avatar);

    if (noAvatar) {
        return isDarkThemeEnabled() ? userDefaultLogoDark : userDefaultLogoLight;
    }

    return avatar;
};
