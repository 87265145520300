import { isAndroid, isIOS, isIOS13, isIPad13, isIPhone13, isIPod13, isMacOs, isWindows } from 'react-device-detect';

const userAgent = navigator.userAgent;
const vendor = navigator.vendor;

export const UserAgentBrowserTypes = {
    Chrome: 'Chrome',
    Yandex: 'Yandex BrowserType',
    FirefoxIOS: 'FxiOS',
    ChromeIOS: 'CriOS',
    Safari: 'Safari',
    Opera: 'Opera',
    Firefox: 'Firefox',
    SamsungBrowser: 'Samsung Internet BrowserType',
    Edge: 'Edge',
    IOS: 'IOS',
    Android: 'Android',
    Mobile: 'Mobile',
    Other: 'Other',
};

export const UserAgentOSTypes = {
    MacOS: 'MacOS',
    Windows: 'Windows',
    Linux: 'Linux',
    Android: 'Android',
    iOS: 'iOS',
};

const iTunesLink = 'iTunesLink';

export const pluginSupportedBrowsers = [UserAgentBrowserTypes.Chrome, UserAgentBrowserTypes.Firefox];
// export const supportedOS = [UserAgentOSTypes.Windows, UserAgentOSTypes.iOS, UserAgentOSTypes.MacOS];

export const ExtensionLinks = {
    [UserAgentBrowserTypes.Chrome]:
        'https://chrome.google.com/webstore/detail/tempest-search/aifabaooljbmglcjiedhcnmbpdbmliia',
    [UserAgentBrowserTypes.Firefox]: 'https://addons.mozilla.org/en-US/firefox/addon/tempest/',
    [UserAgentBrowserTypes.Safari]:
        'https://chrome.google.com/webstore/detail/tempest-search/aifabaooljbmglcjiedhcnmbpdbmliia',
    [UserAgentBrowserTypes.Opera]:
        'https://chrome.google.com/webstore/detail/tempest-search/aifabaooljbmglcjiedhcnmbpdbmliia',
};

export const browserLinks = {
    [UserAgentOSTypes.MacOS]: 'https://downloads.tempest.com/tempestbrowser.dmg',
    [UserAgentOSTypes.Windows]: 'https://downloads.tempest.com/tempestinstaller.exe',
    [UserAgentOSTypes.iOS]: 'https://itunes.apple.com/us/app/apple-store/id1565563169',
    iTunesLink: 'https://itunes.apple.com/us/app/apple-store/id1565563169',
    [UserAgentOSTypes.Android]: 'https://play.google.com/store/apps/details?id=com.tempest.browser',
    [UserAgentOSTypes.Linux]: '/browser',
};

// export const isWindowsOS = /win/i.test(userAgent);
// export const isMacOS = /mac/i.test(userAgent);
export const isLinuxOS = /linux/i.test(userAgent);
// export const isAndroid = isAndroid;
export const isIos = isIOS || isIOS13 || isIPod13 || isIPad13 || isIPhone13;

export const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(vendor);
export const isYandexIOS = /YaBrowser/.test(userAgent) || /YaApp/.test(userAgent);
export const isFirefoxIOS = /FxiOS/.test(userAgent);
export const isChromeIOS = /CriOS/.test(userAgent);
export const isSafari =
    !isChromeIOS && !isYandexIOS && !isFirefoxIOS && /Safari/.test(userAgent) && /Apple Computer/.test(vendor);
export const isOpera = /OPR|Opera/.test(userAgent);
export const isFirefox = /Firefox/.test(userAgent);
export const isSamsungBrowser = /SamsungBrowser/.test(userAgent);
export const isEdge = /Edg/.test(userAgent);
export const isTablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent);
export const isMobile = /Mobi/i.test(userAgent) || 'ontouchstart' in document.documentElement || isIOS || isAndroid;
export const isWebView = /wv/i.test(userAgent);
// export const isFacebook = /FBAN|FBAV/i.test(userAgent);
// export const isInstagram = /Instagram/i.test(userAgent);
// export const isTwitter = /Twitter/i.test(userAgent);
// export const isInAppBrowser = isFacebook || isInstagram || isTwitter;

export const getBrowserName = () => {
    if (isYandexIOS) {
        return UserAgentBrowserTypes.Yandex;
    }
    if (isFirefoxIOS) {
        return UserAgentBrowserTypes.FirefoxIOS;
    }
    if (isChromeIOS) {
        return UserAgentBrowserTypes.ChromeIOS;
    }
    if (isSafari) {
        return UserAgentBrowserTypes.Safari;
    }
    if (isOpera) {
        return UserAgentBrowserTypes.Opera;
    }
    if (isFirefox) {
        return UserAgentBrowserTypes.Firefox;
    }
    if (isSamsungBrowser) {
        return UserAgentBrowserTypes.SamsungBrowser;
    }
    if (isEdge) {
        return UserAgentBrowserTypes.Edge;
    }
    if (isIOS) {
        return UserAgentBrowserTypes.IOS;
    }
    if (isChrome) {
        return UserAgentBrowserTypes.Chrome;
    }
    if (isAndroid) {
        return UserAgentBrowserTypes.Android;
    }
    if (isMobile) {
        return UserAgentBrowserTypes.Mobile;
    }
    return UserAgentBrowserTypes.Other;
};

export const getOS = (isDownloadLink) => {
    if (isAndroid) {
        return UserAgentOSTypes.Android;
    }
    if (isWindows) {
        return UserAgentOSTypes.Windows;
    }
    // isOS should be above isMacOs for correct identification of iPads and iPhones
    if (isIOS) {
        if (isDownloadLink) {
            return iTunesLink;
        }
        return UserAgentOSTypes.iOS;
    }
    if (isMacOs) {
        return UserAgentOSTypes.MacOS;
    }
    if (isLinuxOS) {
        return UserAgentOSTypes.Linux;
    }
};

export const isPluginSupportedBrowser = () => {
    return pluginSupportedBrowsers.includes(getBrowserName());
};

// export const isSupportedOS = () => {
//     return supportedOS.includes(getOS());
// }
