import PropTypes from 'prop-types';
import React from 'react';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

function Layout(props) {
    const {
        children,
        withHeader,
        withFooter,
        className,
        isReady,
        makeSearch,
        newSearch,
        withFeedbackButton,
        isFixedFooter,
        timeToShowFooter,
        isHomepage = false,
    } = props;
    return (
        <>
            {!!withHeader && <Header makeSearch={makeSearch} newSearch={newSearch} />}

            {!isHomepage && <main className={`main${className && ` ${className}`}`}>{children}</main>}
            {!!withFooter && (!!isReady || !!timeToShowFooter) && (
                <Footer withFeedbackButton={withFeedbackButton} isFixedFooter={isFixedFooter} />
            )}
        </>
    );
}

Layout.defaultProps = {
    children: <>Main</>,
    withHeader: false,
    withFooter: true,
    className: '',
    isReady: true,
    timeToShowFooter: true,
};

Layout.propTypes = {
    children: PropTypes.node,
    withHeader: PropTypes.bool,
    withFooter: PropTypes.bool,
    className: PropTypes.string,
};

export default Layout;
