/**
 * # Products Popover
 */

/**
 * ## Imports
 */
import './ProductsPopover.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import companyConfigs from '../../../../../constants/company';
import Link from '../../../Link/Link';

const { withBrowserPage, withSearchPage, withInfinityBrowsersLink } = companyConfigs?.webPages;

/**
 * ## Component
 * @param {boolean} isVisible
 * @param {function} onCLick
 * @returns
 */
const ProductsPopover = React.forwardRef(({ isVisible = false, onClick }, ref) => {
    const { t } = useTranslation('websiteV2');

    return (
        <div className={`popover${isVisible ? ` popover--visible` : ''}`} ref={ref}>
            <ul className="products-list">
                {withSearchPage && (
                    <li className="products-list__item">
                        <Link
                            to="/tempest-search"
                            className="products-list__link products-list__link--browser"
                            clickHandler={onClick({ metricLabel: 'tempest-search', path: '/tempest-search' })}
                        >
                            <span>{t('homepage-shared-header-product-1', 'Tempest Search')}</span>
                        </Link>
                    </li>
                )}
                {withBrowserPage && (
                    <li className="products-list__item">
                        <Link
                            to="/browser"
                            className="products-list__link products-list__link--browser"
                            clickHandler={onClick({ metricLabel: 'browser', path: '/browser' })}
                        >
                            <span>{t('homepage-shared-header-product-2', 'Tempest Browser')}</span>
                        </Link>
                    </li>
                )}
                {withInfinityBrowsersLink && (
                    <li className="products-list__item">
                        <a
                            href="https://infinity-browsers.com/"
                            rel='noopener noreferrer'
                            target='_blank'
                            className="products-list__link products-list__link--infinity-browsers"
                            onClick={onClick({ metricLabel: 'infinity-browsers', path: 'https://infinity-browsers.com/' })}
                        >
                            <span>{t('homepage-shared-header-product-3', 'Infinity Browsers')}</span>
                        </a>
                    </li>
                )}
            </ul>
        </div>
    );
});

export default ProductsPopover;
