import './ExpandableText.scss';

import React, { useState } from 'react';

import Truncate from '../Truncate/Truncate';

type ExpandableTextProps = {
    text: string;
    className: string;
};

function ExpandableText({ text, className }: ExpandableTextProps) {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const toggle: () => void = () => setIsExpanded((prev) => !prev);

    if (isExpanded) {
        return <p className={className}>{text}</p>;
    }

    return (
        <p className={className}>
            <Truncate
                lines={5}
                ellipsis={
                    <span className="ellipsis" onClick={toggle} role="button">
                        ...
                    </span>
                }
            >
                {text}
            </Truncate>
        </p>
    );
}

export default ExpandableText;
