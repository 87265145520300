import './MovieProfileItem.scss';

import React, { PureComponent } from 'react';

class MovieProfileItem extends PureComponent {
    render() {
        const { url, icon, label, openInNewTab } = this.props;
        return (
            <a
                href={url}
                className="movie-profile-item"
                target={openInNewTab ? '_blank' : ''}
                rel="noopener noreferrer"
            >
                <div className="movie-profile-item__img-container">
                    <img className="movie-profile-item__img" src={icon} alt="" />
                </div>
                <div className="movie-profile-item__info">{label}</div>
            </a>
        );
    }
}

export default MovieProfileItem;
