/**
 * # Page Footer
 */

/**
 * ## Imports
 */
import './PageFooter.scss';

import cx from 'classnames';
import React from 'react';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import { submitMetrics } from '../../../actions/app';

import { analyticsType } from '../../../constants';
import companyConfigs from '../../../constants/company';
import { action, category } from '../../../constants/metrics';
import {
    ExtensionLinks,
    UserAgentOSTypes,
    browserLinks,
    getBrowserName,
    getOS,
    isPluginSupportedBrowser,
} from '../../../constants/userAgent';
import CompanyLogo from '../../../features/Home/src/CompanyLogo';
import { ReactComponent as HomepageLogo } from '../../../img/branding/logo-tempest.svg';
import { scrollToTop } from '../../../utils/helpers';
import Link from '../Link/Link';
import BoilerplateImprint from './BoilerplateImprint/BoilerplateImprint';

const {
    withAboutPage,
    withBrowserPage,
    withSearchPage,
    withTermsOfUsePage,
    withCareersPage,
    withSupportPage,
    withNewsroom,
    withImpressumPage,
    withPrivacyPolicyGdprPage,
    withPrivacyPolicyPage,
    withAfterDownloadPage,
} = companyConfigs?.webPages;

/**
 * ## Component
 * @returns React
 */
const PageFooter = ({ withAnchorNavi = true, footerBoilerPlateOnly = false }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('websiteV2');
    const location = useLocation();
    const isViewportBelow760 = useMediaQuery({ maxWidth: 759 });

    const submitLabelMetrics = (value) => {
        dispatch(
            submitMetrics({
                Category: category.footer,
                Action: action.click,
                Object: 'label',
                Value: value,
            }),
        );
    };

    const isPluginSupported = isPluginSupportedBrowser();

    const handleDownloadClick = (type) => {
        handleDownloadClickMetrics(type);
        if (isIOS) {
            const downloadUrl = browserLinks[getOS()];
            window.location.href = downloadUrl;
        } else {
            window.open('/after-download', '_blank', 'noreferrer');
        }
    };

    const handleDownloadClickMetrics = (type) => {
        dispatch(
            submitMetrics(
                {
                    Category: category.footer,
                    Action: action.click,
                    Object: 'download',
                    Value: type,
                    Extras: getBrowserName(),
                },
                analyticsType.wb,
            ),
        );
    };

    const handleSocialsClick = (social) => {
        dispatch(
            submitMetrics(
                {
                    Category: category.footer,
                    Action: action.click,
                    Object: 'social',
                    Value: social,
                },
                analyticsType.wb,
            ),
        );
    };

    const handleNavigationClick = (path, analitic) => () => {
        if (location.pathname === path) {
            scrollToTop('instant');
        }

        if (analitic) {
            submitLabelMetrics(path);
        }
    };

    const shouldRenderCompanyLinks =
        withSearchPage || withBrowserPage || withAboutPage || withCareersPage || withSupportPage || withNewsroom;
    const shouldRenderBoilerplateImprint =
        withPrivacyPolicyGdprPage || withPrivacyPolicyPage || withTermsOfUsePage || withImpressumPage;

    return (
        <footer
            className={cx('pagefooter', {
                'pagefooter--anchornavi': withAnchorNavi,
                'pagefooter--theme-tempest': !footerBoilerPlateOnly,
            })}
        >
            {!footerBoilerPlateOnly && (
                <div className="pagefooter__body home-centerwrapper-wide">
                    <h2 className="sr-only">Further information about Tempest.</h2>

                    <section className="pagefooter__row-left">
                        <h3 className="pagefooter__headline-mini">Tempest</h3>
                        <h4 className="homepage-headline-big homepage-headline-big--no-change">
                            <span className="headline-sober">{t('footer-title-1', 'It’s your data')}</span>
                            <br />
                            {t('footer-title-2', 'Own it.')}
                        </h4>
                    </section>

                    <section className="pagefooter__row-middle pagefooter__downloads-social">
                        {withAfterDownloadPage && (
                            <>
                                <h3 className="pagefooter__headline-mini">
                                    {t('homepage-shared-footer-downloads-list-title', 'Downloads')}
                                </h3>
                                <ul className="pagefooter__download-list">
                                    <li>
                                        <a
                                            href={browserLinks[UserAgentOSTypes.iOS]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="pagefooter__download-link"
                                            onClick={() => handleDownloadClickMetrics(UserAgentOSTypes.iOS)}
                                        >
                                            {t('homepage-shared-footer-downloads-list-item1', 'IOS')}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={browserLinks[UserAgentOSTypes.Android]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="pagefooter__download-link"
                                            onClick={() => handleDownloadClickMetrics(UserAgentOSTypes.Android)}
                                        >
                                            {t('homepage-shared-footer-downloads-list-item5', 'Android')}
                                        </a>
                                    </li>
                                    <li>
                                        <Link
                                            to={`/after-download?os=${UserAgentOSTypes.Windows}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="pagefooter__download-link"
                                            onClick={() => handleDownloadClick(UserAgentOSTypes.Windows)}
                                        >
                                            {t('homepage-shared-footer-downloads-list-item2', 'Windows')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`/after-download?os=${UserAgentOSTypes.MacOS}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="pagefooter__download-link"
                                            onClick={() => handleDownloadClick(UserAgentOSTypes.MacOS)}
                                        >
                                            {t('homepage-shared-footer-downloads-list-item3', 'Mac')}
                                        </Link>
                                    </li>
                                    {isPluginSupported && (
                                        <li>
                                            <a
                                                href={ExtensionLinks[getBrowserName()]}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="pagefooter__download-link"
                                                onClick={() => handleDownloadClickMetrics(`browserExt`)}
                                            >
                                                {t('homepage-shared-footer-downloads-list-item4', 'Browser extension')}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </>
                        )}

                        <div className="pagefooter__social-wrapper">
                            <h3 className="pagefooter__headline-mini">
                                {t('homepage-shared-footer-social', 'Social')}
                            </h3>
                            <ul className="pagefooter__social">
                                <li className="pagefooter__social-item">
                                    <a
                                        href="https://www.linkedin.com/company/tempest-privacy/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="pagefooter__social-link pagefooter__social-link--linkedin"
                                        onClick={() => handleSocialsClick('LinkedIn')}
                                    >
                                        <span lang="en" className="sr-only">
                                            LinkedIn
                                        </span>
                                    </a>
                                </li>
                                <li className="pagefooter__social-item">
                                    <a
                                        href="https://www.facebook.com/tempestprivacy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="pagefooter__social-link pagefooter__social-link--facebook"
                                        onClick={() => handleSocialsClick('Facebook')}
                                    >
                                        <span lang="en" className="sr-only">
                                            Facebook
                                        </span>
                                    </a>
                                </li>
                                <li className="pagefooter__social-item">
                                    <a
                                        href="https://twitter.com/tempesttechltd"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="pagefooter__social-link pagefooter__social-link--twitter"
                                        onClick={() => handleSocialsClick('Twitter')}
                                    >
                                        <span lang="en" className="sr-only">
                                            Twitter
                                        </span>
                                    </a>
                                </li>
                                {/* <li className="pagefooter__social-item">
                                    <a
                                        href="https://www.instagram.com/tempestprivacy/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="pagefooter__social-link pagefooter__social-link--instagram"
                                        onClick={() => handleSocialsClick('Instagram')}
                                    >
                                        <span lang="en" className="sr-only">
                                            Instagram
                                        </span>
                                    </a>
                                </li> */}
                                {/* - Really not wanted anymore?
								<li className="pagefooter__social-item">
									<a
										href="https://github.com/orgs/tempest-tech-ltd/"
										target="_blank"
										rel="noopener noreferrer"
										className="pagefooter__social-link pagefooter__social-link--github"
										onClick={() => handleSocialsClick('GitHub')}
									>
										<span lang="en" className="sr-only">
											Github
										</span>
									</a>
								</li>
								*/}
                            </ul>
                            {isViewportBelow760 && shouldRenderBoilerplateImprint && (
                                <BoilerplateImprint handleNavigationClick={handleNavigationClick} />
                            )}
                        </div>
                    </section>

                    {shouldRenderCompanyLinks && (
                        <section className="pagefooter__row-right pagefooter__tempest">
                            <h3 className="pagefooter__headline-mini">
                                {t('homepage-shared-footer-company-list-title', 'Company')}
                            </h3>
                            <ul className="pagefooter__link-list">
                                {withSearchPage && (
                                    <li className="pagefooter__link-item">
                                        <Link
                                            to="/tempest-search"
                                            className="pagefooter__link-external"
                                            clickHandler={handleNavigationClick('/tempest-search', 'search')}
                                        >
                                            {t('homepage-shared-footer-company-list-search', 'Search')}
                                        </Link>
                                    </li>
                                )}
                                {withBrowserPage && (
                                    <li className="pagefooter__link-item">
                                        <Link
                                            to="/browser"
                                            className="pagefooter__link-external"
                                            clickHandler={handleNavigationClick('/browser', 'browser')}
                                        >
                                            {t('homepage-shared-footer-company-list-browser', 'Browser')}
                                        </Link>
                                    </li>
                                )}
                                {withAboutPage && (
                                    <li className="pagefooter__link-item">
                                        <Link
                                            to="/about"
                                            className="pagefooter__link-external"
                                            clickHandler={handleNavigationClick('/about', 'about')}
                                        >
                                            {t('homepage-shared-footer-company-list-about', 'About')}
                                        </Link>
                                    </li>
                                )}
                                {withCareersPage && (
                                    <li className="pagefooter__link-item">
                                        <Link
                                            to="/careers"
                                            className="pagefooter__link-external"
                                            clickHandler={handleNavigationClick('/careers', 'careers')}
                                        >
                                            {t('homepage-shared-footer-company-list-careers', 'Careers')}
                                        </Link>
                                    </li>
                                )}
                                {withSupportPage && (
                                    <li className="pagefooter__link-item">
                                        <Link
                                            to="/support"
                                            className="pagefooter__link-external"
                                            clickHandler={handleNavigationClick('/support', 'support')}
                                        >
                                            {t('homepage-shared-footer-company-list-support', 'Support')}
                                        </Link>
                                    </li>
                                )}
                                {withNewsroom && (
                                    <li className="pagefooter__link-item">
                                        <Link
                                            to="/newsroom"
                                            className="pagefooter__link-external"
                                            clickHandler={handleNavigationClick('/newsroom', 'newsroom')}
                                        >
                                            {t('homepage-shared-footer-company-list-newsroom', 'Newsroom')}
                                        </Link>
                                    </li>
                                )}
                                {/* TODO Hiding links below since pages are not ready yet */}
                                {/* <li className="pagefooter__link-item">
								<a href="https://www.tempest.com" className="pagefooter__link-external">{t('homepage-shared-footer-company-list-careers', 'Careers')}</a>
							</li>
							<li className="pagefooter__link-item">
								<Link
									to="/support"
									className="pagefooter__link-external"
									clickHandler={() =>
										submitLabelMetrics('support')
									}
								>
									{t('homepage-shared-footer-company-list-support', 'Support')}
								</Link> */}
                                {/*<a href="https://www.tempest.com" className="pagefooter__link-external">Support</a>*/}
                                {/* </li>
							<li className="pagefooter__link-item"><a href="https://www.tempest.com" className="pagefooter__link-external">{t('homepage-shared-footer-company-list-newsroom', 'Newsroom')}</a></li> */}
                            </ul>
                        </section>
                    )}
                </div>
            )}
            <div className="pagefooter__boilerplate">
                <div className="pagefooter__boilerplate-body home-centerwrapper-wide">
                    <Link to="/" clickHandler={handleNavigationClick('/')}>
                        <CompanyLogo
                            alwaysBrightLogo={true}
                            svgComponent={<HomepageLogo />}
                            width={137}
                            height={32}
                            classList="company-logo footer-logo"
                        />
                    </Link>

                    {!isViewportBelow760 && shouldRenderBoilerplateImprint && (
                        <BoilerplateImprint handleNavigationClick={handleNavigationClick} />
                    )}

                    <small className="pagefooter__imprint">© 2024 Tempest</small>
                </div>
            </div>
        </footer>
    );
};

export default PageFooter;
