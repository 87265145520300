const searchTypes = {
    home: 'home',
    all: 'all',
    news: 'news',
    images: 'images',
    videos: 'videos',
    map: 'map',
};

export default searchTypes;
