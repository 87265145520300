module.exports = {
    category: {
        general: 'general',
        wikicard: 'wikicard',
        homepage: 'homepage',
        serp: 'serp',
        settings: 'settings',
        infocards: 'infocards',
        footer: 'footer',
        searchPage: 'searchPage',
        browserPage: 'browserPage',
        support: 'support',
        careerspage: 'careerspage',
        privacy: 'privacy',
    },
    action: {
        trigger: 'trigger',
        get: 'get',
        measure: 'measure',
        click: 'click',
        scroll: 'scroll',
        toggle: 'toggle',
        select: 'select',
        change: 'change',
        pageLoad: 'pageLoad',
        adClick: 'adClick',
        hover: 'hover',
        view: 'view',
        search: 'search',
    },
};
