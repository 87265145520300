import './InstantAnswerSkeleton.scss';

import React from 'react';

import SkeletonTemplate from '../../SkeletonTemplate/SkeletonTemplate';
import WidgetTemplate from '../../WidgetTemplate/WidgetTemplate';

function InstantAnswerSkeleton() {
    return (
        <WidgetTemplate.Wrapper className="instant-answer-skeleton">
            <WidgetTemplate.Paper paddingStyle="narrow-top">
                <SkeletonTemplate.Item className="instant-answer-skeleton__item-1" type="line" color="dark" />
                <SkeletonTemplate.Item className="instant-answer-skeleton__item-2" type="line" color="dark" />
            </WidgetTemplate.Paper>
            <SkeletonTemplate.Footer />
        </WidgetTemplate.Wrapper>
    );
}

export default InstantAnswerSkeleton;
