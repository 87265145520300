import './ToggleSchedule.scss';

import moment from 'moment/moment';
import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import SlideToggle from 'react-slide-toggle';

import { ReactComponent as ArrowIcon } from '../../images/icons/arrow.svg';

function ToggleSchedule({
    onClick,
    isOpen,
    workingDays,
    isShowSchedule,
    mapOpeningHours,
    getCurrentDayNumber,
    openCloseTime,
    isAMPM,
    withTitle,
    isShortDay,
    language,
}) {
    const { t } = useTranslation('common');

    moment.locale(language);

    const sortedWorkingDays = useMemo(
        () => workingDays?.slice(getCurrentDayNumber)?.concat(workingDays?.slice(0, getCurrentDayNumber)),
        [workingDays, getCurrentDayNumber],
    );

    function formatTime(time) {
        return moment(time, 'hmm')
            .local(language)
            .format(isAMPM ? 'h:mm A' : 'HH:mm');
    }

    const dayFormat = isShortDay ? 'ddd' : 'dddd';

    return (
        <ul className="schedule">
            <SlideToggle
                collapsed={!isShowSchedule}
                render={({ toggle, setCollapsibleElement }) => (
                    <li className="my-collapsible">
                        <button
                            type="button"
                            className={`schedule__top${withTitle ? ' schedule__top--with-title' : ''}`}
                            onClick={() => onClick(toggle)}
                        >
                            {withTitle && (
                                <span className="schedule__title">
                                    {!isShowSchedule && isMobile
                                        ? 'Hours'
                                        : moment()
                                              .day(getCurrentDayNumber + 1)
                                              .format('dddd')}
                                </span>
                            )}
                            <span
                                className={`schedule__status${
                                    isOpen ? ' schedule__status--open' : ' schedule__status--closed'
                                }`}
                            >
                                {isOpen ? t('infocard-open', 'Open') : t('infocard-closed', 'Closed')}
                            </span>
                            {workingDays?.length > 0 && (
                                <>
                                    <span className="schedule__separator">{openCloseTime}</span>
                                    <ArrowIcon
                                        className={`schedule__arrow-icon${
                                            isShowSchedule ? ' schedule__arrow-icon--up' : ''
                                        }`}
                                    />
                                </>
                            )}
                        </button>
                        <div className="my-collapsible__content" ref={setCollapsibleElement}>
                            <ul className="my-collapsible__content-inner">
                                {sortedWorkingDays?.map((i) => {
                                    const day = mapOpeningHours[i];
                                    if (day.length > 0) {
                                        return (
                                            <li
                                                // commented temporary for demo
                                                // className={`schedule__item${
                                                // 	getCurrentDayNumber === i
                                                // 		? ' schedule__item--current'
                                                // 		: ''
                                                // }`}
                                                className="schedule__item"
                                                key={i}
                                            >
                                                <time
                                                    className="schedule__time"
                                                    dateTime={moment()
                                                        .day(i + 1)
                                                        .format('LLLL')}
                                                >
                                                    <span className="schedule__day">
                                                        <abbr
                                                            title={moment()
                                                                .day(i + 1)
                                                                .format('dddd')}
                                                            className="schedule__abbr"
                                                        >
                                                            {moment()
                                                                .day(i + 1)
                                                                .format(dayFormat)}
                                                        </abbr>
                                                        &nbsp;
                                                    </span>
                                                    <span
                                                        className={`schedule__day ${
                                                            day.length === 2 ? 'schedule__interval-hours' : ''
                                                        } ${isAMPM ? 'schedule__ampm' : ''}`}
                                                    >
                                                        {day[0] && (
                                                            <span className={`${isAMPM ? 'schedule__ampm__time' : ''}`}>
                                                                <span
                                                                    className={`${
                                                                        isAMPM ? 'schedule__ampm__time__start' : ''
                                                                    }`}
                                                                >
                                                                    {formatTime(day[0].start)}
                                                                </span>
                                                                <span>&nbsp; - &nbsp;</span>
                                                                <span
                                                                    className={`${
                                                                        isAMPM ? 'schedule__ampm__time__end' : ''
                                                                    }`}
                                                                >
                                                                    {formatTime(day[0].end)}
                                                                </span>
                                                            </span>
                                                        )}
                                                        {day[1] && (
                                                            <span className={`${isAMPM ? 'schedule__ampm__time' : ''}`}>
                                                                <span
                                                                    className={`${
                                                                        isAMPM ? 'schedule__ampm__time__start' : ''
                                                                    }`}
                                                                >
                                                                    {formatTime(day[1].start)}
                                                                </span>
                                                                <span>&nbsp; - &nbsp;</span>
                                                                <span
                                                                    className={`${
                                                                        isAMPM ? 'schedule__ampm__time__end' : ''
                                                                    }`}
                                                                >
                                                                    {formatTime(day[1].end)}
                                                                </span>
                                                            </span>
                                                        )}
                                                    </span>
                                                </time>
                                            </li>
                                        );
                                    }
                                    return null;
                                })}
                            </ul>
                        </div>
                    </li>
                )}
            />
        </ul>
    );
}

ToggleSchedule.propTypes = {};

export default ToggleSchedule;
