import React, { PureComponent } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeSearchType, openMobileInput } from '../../../actions/app';

import appErrorTypes from '../../../constants/appErrorTypes';
import searchTypes from '../../../constants/searchTypes';
import ErrorComponent from '../ErrorComponent/ErrorComponent';

const { searchErrors } = appErrorTypes;

class InfoComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.resultsRef = React.createRef();
        this.state = {
            isReady: false,
            key: 0,
            myRequestedRefs: 0,
            resultsLength: 0,
            newSearch: true,
            isBackAction: false,
            imageSideBarIsClosed: true,
        };
    }

    getTranslationForSearchType() {
        const { t, searchType } = this.props;
        if (searchType === searchTypes.news) {
            return t('msg-no-data-for-market-src-news');
        }
        return t(`header-${searchType}`).toLowerCase();
    }

    handleButtonClick = () => {
        const { savedFormInput, changeSearchType, makeSearch, query } = this.props;
        const getQuery = query ? query : savedFormInput;
        changeSearchType(searchTypes.all, 'InfoComponent :41');
        window.scrollTo({ top: 0, behavior: 'instant' });
        makeSearch({ query: getQuery });
    };

    render() {
        const { query, t, openMobileInput, type, searchType } = this.props;
        const noDataForMarket = type === searchErrors.noResultsInData && searchType !== searchTypes.all;

        return (
            <>
                {type === searchErrors.linkExpired && (
                    <ErrorComponent
                        title={t('msg-no-longer-available-title', 'Your privacy matters')}
                        errorType={type}
                        imgWidth={340}
                        buttonText="Please try a new search"
                        buttonClickHandler={() => {
                            openMobileInput(true);
                        }}
                    >
                        <p>
                            {t('msg-no-longer-available')}
                            <br />
                            <span className="mobile">{t('msg-please-try-new-search')}</span>
                        </p>
                    </ErrorComponent>
                )}
                {type === searchErrors.endOfImages && (
                    <ErrorComponent
                        align="left"
                        title={t('msg-end-of-images-title', 'There you go!')}
                        errorType={searchErrors.endOfImages}
                        imgWidth={253}
                        linkButtonText={t('msg-check-web-results', 'Show web results')}
                        buttonClickHandler={this.handleButtonClick.bind(this)}
                    >
                        <Trans
                            ns={'common'}
                            i18nKey="msg-end-of-images"
                            values={{
                                searchType: this.getTranslationForSearchType(),
                                query: query,
                            }}
                        />
                    </ErrorComponent>
                )}
                {type === searchErrors.endOfVideos && (
                    <ErrorComponent
                        align="left"
                        title={t('msg-end-of-images-title', 'There you go!')}
                        errorType={searchErrors.endOfVideos}
                        imgWidth={253}
                        linkButtonText={t('msg-check-web-results', 'Show web results')}
                        buttonClickHandler={this.handleButtonClick.bind(this)}
                    >
                        <Trans
                            ns={'common'}
                            i18nKey="msg-end-of-videos"
                            values={{
                                searchType: this.getTranslationForSearchType(),
                                query: query,
                            }}
                        />
                    </ErrorComponent>
                )}
                {type === searchErrors.internalServerError && (
                    <ErrorComponent
                        title={t('msg-internal-error-title', 'Internal error')}
                        errorType={type}
                        imgWidth={510}
                    >
                        <p>
                            <Trans
                                ns={'common'}
                                i18nKey="msg-internal-error"
                                defaults="Something wrong with that page.<br/>Sorry, this should not have happened."
                            />
                        </p>
                    </ErrorComponent>
                )}
                {type === searchErrors.genericError && (
                    <ErrorComponent
                        title={t('msg-generic-error-title', 'That’s an error')}
                        errorType={type}
                        imgWidth={335}
                    >
                        <p>
                            {t(
                                'msg-generic-error-1',
                                'We’re sorry but it appears that there has been an internal server error while processing your request. ' +
                                    'Our engineers have been notified and are working to resolve the issue.',
                            )}
                        </p>
                        <p>{t('msg-generic-error-2', 'Please try again later')}</p>
                    </ErrorComponent>
                )}
                {type === searchErrors.noResultsInData && !noDataForMarket && (
                    <ErrorComponent
                        title={t('msg-no-results-title', 'No Results Found')}
                        errorType={type}
                        imgWidth={260}
                    >
                        <p className="error-component__text">
                            {t('msg-no-results', 'There are no results for your search')}{' '}
                            <span className="error-component__query">{query}</span>
                        </p>
                        <div className="error-component__text">
                            {t('msg-no-results-suggestions-title', 'Suggestions')}:
                            <ul>
                                <li>
                                    {t(
                                        'msg-no-results-suggestions-list-item-1',
                                        'Make sure that all words are spelled correctly',
                                    )}
                                </li>
                                <li>{t('msg-no-results-suggestions-list-item-2', 'Try different keywords')}</li>
                                <li>{t('msg-no-results-suggestions-list-item-3', 'Try more general keywords')}</li>
                                <li>{t('msg-no-results-suggestions-list-item-4', 'Try fewer keywords')}</li>
                            </ul>
                        </div>
                    </ErrorComponent>
                )}
                {noDataForMarket && (
                    <ErrorComponent
                        title={t('msg-no-results-title', 'No Results Found')}
                        errorType={searchErrors.noResultsInMarket}
                        imgWidth={340}
                        linkButtonText={t('msg-no-data-for-market-button-text', 'Show web results')}
                        buttonClickHandler={this.handleButtonClick.bind(this)}
                    >
                        <Trans
                            ns={'common'}
                            i18nKey="msg-no-data-for-market"
                            values={{
                                searchType: this.getTranslationForSearchType(),
                                query: query,
                            }}
                        />
                    </ErrorComponent>
                )}
                {type === searchErrors.resourceNotFound && (
                    <ErrorComponent
                        title={t('msg-resource-not-found-title', 'Ooops, resource not found')}
                        errorType={type}
                        imgWidth={335}
                    >
                        <p>{t('msg-resource-not-found', 'The requested URL does not match any results')}</p>
                    </ErrorComponent>
                )}
                {type === searchErrors.connectionLost && (
                    <ErrorComponent title="Check your connection" errorType={type} imgWidth={395}>
                        <Trans
                            ns={'common'}
                            i18nKey="msg-connection-lost"
                            defaults="You don’t seem to have an active internet connection.<br/>
								Please check your connection and try again."
                        />
                    </ErrorComponent>
                )}
                {/*{isEncryptedQueryDraft && (
					<ErrorComponent
						title="This is an encrypted query"
						errorType="EncriptedQueryError"
						imgWidth={340}
					>
						<p>
							Please enable "Search terms encryption" in the Settings and reload the page.
						</p>
					</ErrorComponent>
				)}*/}

                {/*{!!encrSettingIsRequired && (*/}
                {/*	<div className="search-result-item__text">*/}
                {/*		{t(*/}
                {/*			'msg-do-enable-encryption',*/}
                {/*			'This is an encrypted query, please enable "Search terms encryption" in the Settings and reload the page.',*/}
                {/*		)}*/}
                {/*	</div>*/}
                {/*)}*/}
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    searchInputClearHandler: state.search.searchInputClearHandler,
    theme: state.app.theme,
    searchLocation: state.search.searchLocation,
    savedFormInput: state.search.savedFormInput,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeSearchType,
            openMobileInput,
            dispatch,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(InfoComponent));
