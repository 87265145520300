import React, { FC, RefObject, useEffect, useRef } from 'react';

interface OutsideAlerterProps {
    children: JSX.Element;
    onClick: (e?: React.MouseEvent<HTMLElement>) => void;
}

const useOutsideAlerter = <T extends HTMLElement>(
    ref: RefObject<T>,
    onClick: (e?: React.MouseEvent<HTMLElement>) => void,
) => {
    useEffect(() => {
        /* - Check if clicked outside of element. */
        function clickOutsideHandler(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                /* - `any` as type is sub-optimal here. If we can find a better solution in the
                 * future, we should use it. */
                onClick(event as any);
            }
        }

        /* - Bind the event listener. */
        document.addEventListener('mousedown', clickOutsideHandler);

        return () => {
            /* - Unbind the event listener on clean up. */
            document.removeEventListener('mousedown', clickOutsideHandler);
        };
    }, [ref, onClick]);
};

const OutsideAlerter: FC<OutsideAlerterProps> = ({ children, onClick }) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, onClick);

    return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
