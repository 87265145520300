import './MovieInfocard.scss';

import moment, { now } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import TextTruncate from 'react-text-truncate';
import { bindActionCreators } from 'redux';

import { setRecentActionData } from '../../../../actions/app';
import { getPublicKey } from '../../../../actions/search';

import { ReactComponent as FallbackPhoto } from '../../../../img/cast-item-image-bg.svg';
import { encrypt } from '../../../../utils/cryptoHelper';
import { validatePublicKey } from '../../../../utils/helpers';
import { ReactComponent as StarIcon } from '../../../images/icons/star-icon.svg';
import { ReactComponent as TMDBIcon } from '../../../images/icons/tmdb-icon.svg';
import ExpandableText from '../../ExpandableText/ExpandableText';
import WidgetCTA from '../../WidgetCTA/WidgetCTA';
import WidgetTemplate from '../../WidgetTemplate/WidgetTemplate';
import MovieProfileItem from '../MovieProfileItem/MovieProfileItem';
import MovieSlider from '../MovieSlider/MovieSlider';
import MovieSliderItem from '../MovieSliderItem/MovieSliderItem';

const extractTrailerUrl = (videos) => {
    const trailer = videos.find((video) => video.type === 'Trailer' && video.site === 'YouTube');

    if (trailer && trailer.key) {
        return `https://www.youtube.com/watch?v=${trailer.key}`;
    }
};

const formatCurrency = (value) => {
    if (!value) {
        return;
    }
    if (value >= 1.0e12) {
        return '$' + (value / 1.0e12).toFixed(1).replace('.0', '') + ' trillion';
    }
    if (value >= 1.0e9) {
        return '$' + (value / 1.0e9).toFixed(1).replace('.0', '') + ' billion';
    }
    if (value >= 1.0e6) {
        return '$' + (value / 1.0e6).toFixed(1).replace('.0', '') + ' million';
    }
    return `$${value}`;
};

const formatRuntime = (value) => {
    const h = Math.floor(value / 60),
        m = value % 60;
    let result = h ? `${h}h` : '';
    result += m ? ` ${m}m` : '';
    return result.trim();
};

const prepareAvatarPath = (baseUrl, value) => {
    if (!value) {
        return '/images/home/join-waitlist-bg.jpg';
    }
    const path = value.replace(/^\/(https?:)/, '$1');
    if (path.match(/^http/)) {
        return path;
    }
    return baseUrl + value;
};

const prepareAllReviewsUrl = (watchNowLink, movieId) => {
    if (watchNowLink) {
        return watchNowLink.replace(/(\/watch.+)$/, '/reviews');
    }
    if (movieId) {
        return `https://www.themoviedb.org/movie/${movieId}/reviews`;
    }
    return 'https://www.themoviedb.org';
};

const MovieInfocard = (props) => {
    const {
        encryptionSetting,
        publicKey,
        publicKeyVersion,
        getPublicKey,
        publicKeyExpirationTime,
        publicKeyDomain,
        makeSearch,
        moviesInfoCard,
        moviesInfoCardMetaData,
        openInNewTab,
        setRecentActionData,
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [reviewPhotoError, setReviewPhotoError] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 759 });

    useEffect(() => {
        if (initialized) {
            return;
        }

        if (encryptionSetting && !validatePublicKey(publicKey, publicKeyExpirationTime, publicKeyDomain)) {
            getPublicKey();
        }

        setInitialized(true);
        // eslint-disable-next-line
    }, [props, initialized]);

    const extractCrew = (crew) => {
        const crewSorted = crew.sort((a, b) => b.popularity - a.popularity);
        const wantedJobs = ['Director', 'Writer', 'Producer'];

        return wantedJobs
            .map((job) => ({
                job: job,
                names: crewSorted.filter((c) => c.job === job).map((c) => c.name),
            }))
            .filter((j) => j.names && j.names.length);
    };

    const toggleDetails = () => {
        if (isExpanded) {
            window.scrollTo(0, 0);
        }
        setIsExpanded((prevState) => !prevState);
    };

    const searchCastQuery = (query) => {
        setRecentActionData(null);
        makeSearch(query);
    };

    const onReviewPhotoError = () => {
        setReviewPhotoError(true);
    };

    const prepareSearchUrl = (query, movieId) => {
        let result = `/search?q=${query}`;
        if (encryptionSetting && publicKey) {
            const encryptedQuery = encrypt(
                { q: query, rh: 1, wb: 1, wbvers: 'v2' },
                publicKey,
                publicKeyVersion,
                'MovieInfocard',
            );
            result = `/search?encv=${encryptedQuery.encv}&encq=${encodeURIComponent(encryptedQuery.encq)}`;
        }
        if (movieId) {
            result += `&movie=${movieId}`;
        }
        return result;
    };

    const searchMoreMoviesQuery = (title, movieId) => {
        setRecentActionData(null);
        makeSearch(title, null, null, false, false, {
            postPayload: {
                infocard: 'movie',
                movie_type: 'movie',
                movie_id: parseInt(movieId),
            },
        });
    };

    const showAdditionalSections = !isMobile || isExpanded;
    const posterPath = moviesInfoCard.base_url + moviesInfoCard.poster_path;
    const backdropPath = moviesInfoCard.base_url + moviesInfoCard.backdrop_path;
    const genres = moviesInfoCard.genres?.map((g) => g.name).join('/') || '';
    const creators = extractCrew(moviesInfoCard?.credits.crew);
    const trailerUrl = extractTrailerUrl(moviesInfoCard?.videos.results);
    const budget = formatCurrency(moviesInfoCard?.budget);
    const revenue = formatCurrency(moviesInfoCard?.revenue);
    const runtime = formatRuntime(moviesInfoCard?.runtime);
    const firstReview =
        moviesInfoCard?.reviews_summary &&
        moviesInfoCard?.reviews_summary.reviews &&
        moviesInfoCard?.reviews_summary.reviews[0];
    const reviewUserAvatar = prepareAvatarPath(moviesInfoCard?.base_url, firstReview?.author_details?.avatar_path);
    const moreReviewCount = firstReview ? moviesInfoCard?.reviews_summary.total - 1 : 0;
    const allReviewsUrl = prepareAllReviewsUrl(moviesInfoCard?.watch_provider?.link, moviesInfoCard?.id);
    const movieRating = `${parseInt(moviesInfoCard.vote_average * 10)}%`;
    const getHomePageSrc = moviesInfoCard?.homepage ? moviesInfoCard.homepage : moviesInfoCard?.tmdb_homepage;
    const releaseDate = moment(moviesInfoCard.release_date).isAfter(now(), 'day')
        ? moment(moviesInfoCard?.release_date).format('DD MMM YY')
        : moment(moviesInfoCard?.release_date).format('YYYY');
    const collectionParts = useMemo(
        () =>
            moviesInfoCard.collection?.parts?.length
                ? moviesInfoCard.collection.parts
                      .filter((item) => item.id !== moviesInfoCard.id)
                      .sort((a, b) => a.release_date > b.release_date)
                      .slice(0, 10)
                : null,
        [moviesInfoCard],
    );

    return (
        <WidgetTemplate.Wrapper>
            <WidgetTemplate.Paper className="movie-infocard" withAnimatedBorder={!isMobile}>
                {!isMobile && (
                    <a
                        href={trailerUrl}
                        className="movie-infocard__video-img-wrapper"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <figure
                            className="figure movie-infocard__poster-wrapper"
                            style={{
                                backgroundImage: `url(${backdropPath})`,
                            }}
                        >
                            <div className="movie-infocard__poster-black-layer"></div>
                            <img src={posterPath} alt="Movie poster" className="movie-infocard__video-img" />
                            <div className="movie-infocard__video-info-wrapper">
                                <span className="movie-infocard__video-info-text">Trailer</span>
                                <span className="movie-infocard__video-info-text hover">Play trailer on Youtube</span>
                            </div>
                        </figure>
                    </a>
                )}
                <h2 className="movie-infocard__title movie-infocard__title--top">
                    {getHomePageSrc ? (
                        <a
                            className="movie-infocard__title-text movie-infocard__title-text--link"
                            href={getHomePageSrc}
                            target={openInNewTab ? '_blank' : '_self'}
                            rel="noopener noreferrer"
                        >
                            {moviesInfoCard.title}
                        </a>
                    ) : (
                        <span className="movie-infocard__title-text">{moviesInfoCard.title}</span>
                    )}
                    {moviesInfoCard.vote_average > 0 && (
                        <span className="movie-infocard__rating-short">
                            <span className="movie-infocard__rating-icon" />
                            &nbsp;
                            <span className="movie-infocard__rating-text">
                                <span className="movie-infocard__rating-label">{movieRating}&nbsp;</span>
                                <abbr title="The Movie Database" className="abbreviation">
                                    TMDb
                                </abbr>
                            </span>
                        </span>
                    )}
                </h2>
                <h3 className="movie-infocard__subtitle">
                    {releaseDate}
                    {runtime && (
                        <>
                            <span className="movie-infocard__dot">&nbsp;•&nbsp;</span>
                            {runtime}
                        </>
                    )}
                    {genres && (
                        <>
                            <span className="movie-infocard__dot">&nbsp;•&nbsp;</span>
                            {genres}
                        </>
                    )}
                </h3>
                {isMobile && (
                    <div className="movie-infocard__media">
                        <a
                            href={trailerUrl}
                            className="movie-infocard__poster"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={posterPath} alt="" className="movie-infocard__poster-img" />
                        </a>
                        <a
                            href={trailerUrl}
                            className="movie-infocard__video-img-wrapper"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={backdropPath} alt="" className="movie-infocard__video-img-mobile" />
                            <div className="movie-infocard__video-info-wrapper">
                                <div className="movie-infocard__video-info-text">Play trailer on Youtube</div>
                            </div>
                        </a>
                    </div>
                )}
                <WidgetTemplate.Block className="movie-infocard__block">
                    <ExpandableText text={moviesInfoCard.overview} className="movie-infocard__text" />
                </WidgetTemplate.Block>
                <WidgetTemplate.Block className="movie-infocard__block">
                    <ul className="movie-infocard__lines">
                        {creators.map((creator, c) => (
                            <li className="line" key={c}>
                                <em className="line__caption">
                                    {creator.job}
                                    <span className="line__colon">:&nbsp;</span>
                                </em>
                                <div className="line__value">
                                    {creator.names.map((name, cn) => (
                                        <div className="line__value-item" key={cn}>
                                            <a
                                                href={prepareSearchUrl(name)}
                                                target={openInNewTab ? '_blank' : '_self'}
                                                rel="noreferrer noopener"
                                                onClick={(event) => {
                                                    if (!openInNewTab) {
                                                        event.preventDefault();
                                                        searchCastQuery(name);
                                                    }
                                                }}
                                                className="line__value-item--link"
                                            >
                                                {name}
                                            </a>
                                            <span className="line__comma">,&nbsp;</span>
                                        </div>
                                    ))}
                                </div>
                            </li>
                        ))}
                        {budget && (
                            <li className="line">
                                <em className="line__caption">
                                    Budget
                                    <span className="line__colon">:&nbsp;</span>
                                </em>
                                <div className="line__value">
                                    <div className="line__value-item">{budget}</div>
                                </div>
                            </li>
                        )}
                        {revenue && (
                            <li className="line">
                                <em className="line__caption">
                                    Revenue
                                    <span className="line__colon">:&nbsp;</span>
                                </em>
                                <div className="line__value">
                                    <div className="line__value-item">{revenue}</div>
                                </div>
                            </li>
                        )}
                        {moviesInfoCard.collection && (
                            <li className="line">
                                <em className="line__caption">
                                    Collection
                                    <span className="line__colon">:&nbsp;</span>
                                </em>
                                <div className="line__value">
                                    <div className="line__value-item">{moviesInfoCard.collection.name}</div>
                                </div>
                            </li>
                        )}
                    </ul>
                </WidgetTemplate.Block>

                {moviesInfoCard.vote_average > 0 && (
                    <WidgetTemplate.Block className="movie-infocard__block">
                        <h3 className="movie-infocard__title movie-rating__title">Ratings</h3>
                        <div className="movie-rating">
                            <div className="movie-rating__img-wrapper">
                                <TMDBIcon />
                            </div>
                            <div className="movie-rating__info">
                                <div className="movie-rating__caption">
                                    The Movie Database (
                                    <abbr title="The Movie Database" className="abbreviation">
                                        TMDB
                                    </abbr>
                                    )
                                </div>
                                <div className="movie-rating__text">
                                    {moviesInfoCard.vote_count.toLocaleString('en')} Ratings
                                </div>
                            </div>
                            <div className="movie-rating__value">{movieRating}</div>
                        </div>
                    </WidgetTemplate.Block>
                )}
                {showAdditionalSections && moviesInfoCard.credits?.cast?.length && (
                    <WidgetTemplate.Block className="movie-infocard__block">
                        <h3 className="movie-infocard__title">Cast</h3>
                        <MovieSlider>
                            {moviesInfoCard.credits.cast
                                .slice(0, 10)
                                .map(({ original_name, character, profile_path }) => (
                                    <MovieSliderItem
                                        mainText={original_name}
                                        key={original_name}
                                        additionalText={character}
                                        photoUrl={`${moviesInfoCard.base_url}${profile_path}`}
                                        searchUrl={prepareSearchUrl(original_name)}
                                        openInNewTab={openInNewTab}
                                        onClick={() => searchCastQuery(original_name)}
                                    />
                                ))}
                        </MovieSlider>
                    </WidgetTemplate.Block>
                )}
                {showAdditionalSections && firstReview && (
                    <WidgetTemplate.Block className="movie-infocard__block">
                        <section className="movie-infocard__reviews">
                            <div className="movie-infocard__reviews-top">
                                <h3 className="movie-infocard__title">Reviews</h3>
                                <a
                                    href={allReviewsUrl}
                                    className="movie-infocard__reviews-button"
                                    rel="noreferrer"
                                    target={openInNewTab ? '_blank' : '_self'}
                                >
                                    {moreReviewCount > 0 ? `View ${moreReviewCount} more` : 'View more'}
                                </a>
                            </div>
                            <article className="movie-infocard__review movie-review">
                                <div className="movie-review__img-wrapper">
                                    {!reviewPhotoError && (
                                        <img
                                            src={reviewUserAvatar}
                                            alt="User avatar"
                                            className="movie-review__img"
                                            onError={onReviewPhotoError}
                                        />
                                    )}
                                    {reviewPhotoError && <FallbackPhoto className="movie-review__img" />}
                                </div>
                                <div className="movie-review__info">
                                    <div className="movie-review__top">
                                        <h4 className="movie-review__name">{firstReview.author}</h4>
                                        {firstReview.author_details.rating && (
                                            <div className="movie-review__evaluation">
                                                <StarIcon />
                                                <span>{firstReview.author_details.rating.toFixed(1)}</span>
                                            </div>
                                        )}
                                    </div>
                                    <p className="movie-review__text">
                                        {/* TODO React-trancate lib works incorrect and not supported since 2019. Need to remove it everywhere */}
                                        <TextTruncate
                                            line={3}
                                            element="span"
                                            truncateText="..."
                                            text={firstReview.content}
                                        />
                                    </p>
                                </div>
                            </article>
                        </section>
                    </WidgetTemplate.Block>
                )}
                {showAdditionalSections && collectionParts?.length && (
                    <WidgetTemplate.Block className="movie-infocard__block">
                        <h3 className="movie-infocard__title">More Movies</h3>
                        <MovieSlider className="movie-infocard__slider-movies">
                            {collectionParts.map(({ title, release_date, poster_path, id }) => (
                                <MovieSliderItem
                                    mainText={title}
                                    key={title}
                                    additionalText={moment(release_date).format('YYYY')}
                                    photoUrl={`${moviesInfoCard.base_url}${poster_path}`}
                                    searchUrl={prepareSearchUrl(title, id)}
                                    openInNewTab={openInNewTab}
                                    onClick={() => searchMoreMoviesQuery(title, id)}
                                    mainTextHeight={3}
                                />
                            ))}
                        </MovieSlider>
                    </WidgetTemplate.Block>
                )}
                {(moviesInfoCard.external_ids || getHomePageSrc) && (
                    <WidgetTemplate.Block className="movie-infocard__block">
                        <section className="movie-infocard__profiles">
                            <h3 className="movie-infocard__title">Profiles</h3>
                            <div className="movie-infocard__profiles-list">
                                {moviesInfoCard.external_ids?.facebook_id && (
                                    <MovieProfileItem
                                        label="Facebook"
                                        url={`https://www.facebook.com/${moviesInfoCard.external_ids?.facebook_id}`}
                                        icon="/images/facebook.svg"
                                        openInNewTab={openInNewTab}
                                    />
                                )}
                                {moviesInfoCard.external_ids?.imdb_id && (
                                    <MovieProfileItem
                                        label="IMDB"
                                        url={`https://www.imdb.com/title/${moviesInfoCard.external_ids?.imdb_id}`}
                                        icon="/images/imdb.svg"
                                        openInNewTab={openInNewTab}
                                    />
                                )}
                                {moviesInfoCard.external_ids?.twitter_id && (
                                    <MovieProfileItem
                                        label="Twitter"
                                        url={`https://www.twitter.com/${moviesInfoCard.external_ids?.twitter_id}`}
                                        icon="/images/twitter.svg"
                                        openInNewTab={openInNewTab}
                                    />
                                )}
                                {moviesInfoCard.external_ids?.instagram_id && (
                                    <MovieProfileItem
                                        label="Instagram"
                                        url={`https://www.instagram.com/${moviesInfoCard.external_ids?.instagram_id}`}
                                        icon="/images/instagram.svg"
                                        openInNewTab={openInNewTab}
                                    />
                                )}
                                {getHomePageSrc && (
                                    <MovieProfileItem
                                        label="Website"
                                        url={getHomePageSrc}
                                        icon="/images/website.svg"
                                        openInNewTab={openInNewTab}
                                    />
                                )}
                            </div>
                        </section>
                    </WidgetTemplate.Block>
                )}
                {showAdditionalSections && moviesInfoCard.watch_provider && (
                    <WidgetTemplate.Block className="movie-infocard__block">
                        <div className="movie-infocard__title movie-infocard__watch-now-title">Watch Now</div>
                        <div className="movie-infocard__text">
                            Find all the providers where {moviesInfoCard.title} can be watched on demand{' '}
                            <a
                                className="movie-infocard__watch-now-link"
                                href={moviesInfoCard.watch_provider.link}
                                rel="noreferrer"
                                target={openInNewTab ? '_blank' : '_self'}
                            >
                                here
                            </a>
                            .
                        </div>
                    </WidgetTemplate.Block>
                )}
                {isMobile && (
                    <WidgetCTA
                        className="movie-infocard__expand-button"
                        buttonType="vertical"
                        onClick={toggleDetails}
                        isRotateIcon={isExpanded}
                        buttonMode="light"
                    >
                        {isExpanded ? 'See Less' : 'See More'}
                    </WidgetCTA>
                )}
            </WidgetTemplate.Paper>
            <WidgetTemplate.Footer
                infocardData={{
                    byTempest: 'movies',
                    provider: moviesInfoCardMetaData.providers[0],
                }}
                withDetails
                withFeedback
            />
        </WidgetTemplate.Wrapper>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPublicKey,
            setRecentActionData,
        },
        dispatch,
    );
}

const mapStateToProps = (state, props) => ({
    publicKey: state.search.publicKey,
    publicKeyVersion: state.search.publicKeyVersion,
    publicKeyExpirationTime: state.search.publicKeyExpirationTime,
    publicKeyDomain: state.search.publicKeyDomain,
    encryptionSetting: state.app.encryptionSetting,
    moviesInfoCardMetaData: state.search.moviesInfoCardMetaData,
    openInNewTab: state.app.openInNewTab,
});

export default connect(mapStateToProps, mapDispatchToProps)(MovieInfocard);
