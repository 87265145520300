import './InstantAnswerTimezoneWidget.scss';

import React, { PureComponent } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';

import * as loadStatuses from '../../../constants/loadStatuses';
import InstantAnswerSkeleton from '../InstantAnswerWidget/InstantAnswerSkeleton/InstantAnswerSkeleton';
import WidgetTemplate from '../WidgetTemplate/WidgetTemplate';

class InstantAnswerTimezoneWidget extends PureComponent {
    formatTimezoneName = (value) => {
        const tzName = value.timeZoneName || value.utcOffset;
        if (!value.location) {
            return tzName;
        }
        return tzName ? `${value.location} (${tzName})` : value.location;
    };

    formatTime = (data, isAMPM) => {
        const options = {
            timeStyle: 'short',
            hourCycle: isAMPM ? 'h12' : 'h23',
            timeZone: 'UTC',
        };
        return new Intl.DateTimeFormat('en', options).format(new Date(data.time));
    };

    formatDate = (data, location) => {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        };
        return new Intl.DateTimeFormat(location, options).format(new Date(data.time));
    };

    render() {
        const { data, t, isAMPM, language } = this.props;
        const { primaryCityTime, otherCityTimes } = data.tzData;

        if (this.props.richHeaderLoadStatus === loadStatuses.loading) return <InstantAnswerSkeleton />;

        return (
            <WidgetTemplate.Wrapper>
                <WidgetTemplate.Paper className="instant-answer-timezone-widget" withAnimatedBorder>
                    <strong className="instant-answer-timezone-widget__primary-timezone-time">
                        {this.formatTime(primaryCityTime, isAMPM)}
                    </strong>
                    <em className="instant-answer-timezone-widget__primary-timezone-details">
                        {this.formatDate(primaryCityTime, language)}
                        <br />
                        <Trans
                            ns={'common'}
                            i18nKey="instant-answer-time-in"
                            values={{
                                primaryTimeZoneName: this.formatTimezoneName(primaryCityTime),
                            }}
                            defaults="Time in {{primaryTimeZoneName}}"
                        />
                    </em>
                    {otherCityTimes &&
                        otherCityTimes.map((city, i) => (
                            <div className="instant-answer-timezone-widget__secondary-timezone" key={i}>
                                <strong className="instant-answer-timezone-widget__secondary-timezone-time">
                                    {this.formatTime(city, isAMPM)}
                                </strong>
                                <em className="instant-answer-timezone-widget__secondary-timezone-details">
                                    {this.formatTimezoneName(city)}
                                </em>
                            </div>
                        ))}
                </WidgetTemplate.Paper>
                <WidgetTemplate.Footer
                    infocardData={{
                        byTempest: t('infocard-by-tempest-converting-values', 'converting values'),
                        provider: {
                            name: 'Microsoft Computation API',
                        },
                    }}
                    withDetails
                    withFeedback
                />
            </WidgetTemplate.Wrapper>
        );
    }
}

const mapStateToProps = (state, props) => ({
    isAMPM: state.search.isAMPM,
    language: state.app.appLanguage,
    richHeaderLoadStatus: state.search.richHeaderLoadStatus,
});

export default connect(mapStateToProps, null)(withTranslation('common')(InstantAnswerTimezoneWidget));
