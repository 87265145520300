import { Suspense, lazy } from 'react';
import React from 'react';
import { withTranslation } from 'react-i18next';

import SingleBusinessWidget from '../../../../common/components/BusinessWidget/SingleBusinessWidget';
import MovieWidget from '../../../../common/components/MovieWidget';
import MovieCollectionInfocard from '../../../../common/components/MovieWidget/MovieCollectionInfocard/MovieCollectionInfocard';
import SearchResultItem from '../../../../common/components/SearchResultItem/SearchResultItem';
import TwoColumnsView from '../AllResults/components/TwoColumnsView/TwoColumnsView';

import * as loadStatuses from '../../../../constants/loadStatuses';

const parser = require('tld-extract');

const WikiBox = lazy(() => import('../AllResults/lazyComponents/lazyWikiBox'));
const AdWidgetAside = lazy(() => import('../AllResults/lazyComponents/lazyAdWidgetAside'));

function SearchResultSideContainer({
    wikiBoxDataIsExpected,
    isNearMeSearch,
    wikiBoxLoadStatus,
    wikiIsLoaded,
    wikiBox,
    sidebarTextAds,
    page,
    openInNewTab,
    sideBarProductAds,
    handleTabChange,
    handleWikiBoxOnLoad,
    movieWidgetLoaded,
    moviesInfoCard,
    moviesInfoCardMetaData,
    moviesCollectionInfoCard,
    moviesCollectionInfoCardMetaData,
    richHeaderLoadStatus,
    widgetTypeIsMovie,
    makeSearch,
    businessWidget,
    isAMPM,
    theme,
    language,
    getMoreBusinesses,
    businessWidgetMetaData,
    t,
    widgetEntityType,
    widgetTypeIsMovieCollection,
}) {
    const getDomain = (item) => {
        try {
            return item && parser(item)?.domain;
        } catch (e) {
            return item;
        }
    };
    const richHeaderOrEntityLoaded =
        (richHeaderLoadStatus === loadStatuses.completed && widgetTypeIsMovie) ||
        wikiBoxLoadStatus === loadStatuses.completed;

    const getSidebarTextAds = sidebarTextAds?.slice(0, 2);
    const sidebarTextAdsCount = getSidebarTextAds?.length;
    const movieWidgetReady = !!(movieWidgetLoaded && (moviesInfoCard || moviesCollectionInfoCard));

    if (isNearMeSearch && wikiBoxDataIsExpected) {
        return null;
    }

    return (
        <aside
            className="search-result__aside"
            style={{
                visibility:
                    (wikiBoxDataIsExpected && richHeaderOrEntityLoaded && wikiIsLoaded) ||
                    !wikiBoxDataIsExpected ||
                    movieWidgetReady
                        ? 'visible'
                        : 'hidden',
            }}
        >
            <h2 className="sr-only">{t('asideHeadline')}</h2>
            {widgetEntityType && widgetEntityType === 'single' && (
                <SingleBusinessWidget
                    businessWidget={businessWidget}
                    isAMPM={isAMPM}
                    openInNewTab={openInNewTab}
                    theme={theme}
                    language={language}
                    getMoreBusinesses={getMoreBusinesses}
                    provider={businessWidget?.context?.provider}
                    richHeaderLoadStatus={richHeaderLoadStatus}
                    businessWidgetMetaData={businessWidgetMetaData}
                />
            )}
            {widgetTypeIsMovie && (
                <MovieWidget
                    moviesInfoCard={moviesInfoCard}
                    moviesInfoCardMetaData={moviesInfoCardMetaData}
                    makeSearch={makeSearch}
                    movieWidgetLoaded={movieWidgetLoaded}
                    richHeaderLoadStatus={richHeaderLoadStatus}
                />
            )}
            {widgetTypeIsMovieCollection && (
                <MovieCollectionInfocard
                    data={moviesCollectionInfoCard}
                    metaData={moviesCollectionInfoCardMetaData}
                    makeSearch={makeSearch}
                />
            )}
            {richHeaderOrEntityLoaded && richHeaderLoadStatus !== loadStatuses.loading && !movieWidgetReady && (
                <Suspense fallback={<div></div>}>
                    <WikiBox
                        wikiBox={wikiBox}
                        data-test={'Entities'}
                        openInNewTab={openInNewTab}
                        handleTabChange={handleTabChange}
                        onLoadCallback={handleWikiBoxOnLoad}
                        makeSearch={makeSearch}
                    />
                </Suspense>
            )}
            <TwoColumnsView>
                {getSidebarTextAds &&
                    getSidebarTextAds.map((ad, i) => {
                        const domain = getDomain(ad.url);
                        return (
                            <SearchResultItem
                                key={`ad_${i}`}
                                isSidebarAd
                                item={ad}
                                page={page}
                                index={ad.resultIndex}
                                openInNewTab={openInNewTab}
                                domain={domain}
                                keyIndex={i}
                                isLastItem={sidebarTextAdsCount - 1 === i && !sideBarProductAds}
                            />
                        );
                    })}
                {sideBarProductAds && (
                    <Suspense fallback={<div></div>}>
                        <AdWidgetAside data={sideBarProductAds} openInNewTab={openInNewTab} />
                    </Suspense>
                )}
            </TwoColumnsView>
            {/*{(sidebarTextAds || sideBarProductAds) && <FeedbackButton />}*/}
        </aside>
    );
}

export default withTranslation('common')(SearchResultSideContainer);
