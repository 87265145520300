import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as InfoIcon } from '../../images/icons/info-icon.svg';

function Checkbox(props) {
    const { onChange, labelText, className, disabled, value, withTooltip, type, isReversed } = props;
    return (
        <div className="check__wrapper" data-test={'CheckboxWrapper'}>
            <label className={`check${className && ` ${className}`}${isReversed ? ` check--reversed` : ``}`}>
                <span className="check__label-text">
                    {labelText}&nbsp;
                    {withTooltip && (
                        <>
                            <span data-tip={'button'} data-for={withTooltip ? `${type}_tooltip` : ''}>
                                <InfoIcon />
                            </span>
                            <ReactTooltip
                                id={`${type}_tooltip`}
                                place="top"
                                effect="solid"
                                type="light"
                                border={true}
                                borderColor={'#80859f'}
                            >
                                {withTooltip}
                            </ReactTooltip>
                        </>
                    )}
                </span>
                <span className="check__gap" />
                <input
                    className="check__input"
                    type="checkbox"
                    disabled={disabled}
                    checked={value}
                    data-test={'Checkbox'}
                    onChange={onChange}
                />
                <span className="check__box" />
            </label>
        </div>
    );
}

Checkbox.defaultProps = {
    onChange: Function.prototype,
    labelText: '',
    className: '',
    withTooltip: '',
    isReversed: false,
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    labelText: PropTypes.string || PropTypes.node,
    className: PropTypes.string,
    withTooltip: PropTypes.string,
    isReversed: PropTypes.bool,
};

export default Checkbox;
