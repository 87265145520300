/**
 * # Navigation Bar Interaction
 */

/**
 * ## Imports
 */
import './NavigationBarInteraction.scss';

import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { submitMetrics } from '../../../actions/app';

import { analyticsType } from '../../../constants';
import companyConfigs from '../../../constants/company';
import { action, category } from '../../../constants/metrics';
import useClickOutside from '../../hooks/useClickOutside';
import Link from '../Link/Link';
import Menu from '../Menu/Menu';
import ProductsPopover from './utils/ProductsPopover/ProductsPopover';

const { withBrowserPage, withSearchPage, withAboutPage } = companyConfigs?.webPages;

/**
 * ## Component
 * @returns React
 */
const NavigationBarInteraction = ({ isStickyBar }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('websiteV2');
    const { pathname } = useLocation();

    const [productsPopoverVisible, setProductsPopoverVisible] = useState(false);
    const popOverRef = useRef(null);

    useClickOutside({
        element: popOverRef.current,
        onClickOutside: () => {
            setProductsPopoverVisible(false);
        },
    });

    const productsClickHandler = (event) => {
        event.preventDefault();
        dispatch(
            submitMetrics(
                {
                    Category: category.homepage,
                    Action: action.view,
                    Object: 'products',
                    Value: 'products_popover',
                },
                analyticsType.wb,
            ),
        );
        setProductsPopoverVisible((prevState) => !prevState);
    };

    const submitLabelMetrics = useCallback(
        (value) => {
            dispatch(
                submitMetrics({
                    Category: category.homepage,
                    Action: action.click,
                    Object: value,
                    Value: value,
                }),
            );
        },
        [dispatch],
    );

    const handleNavClick = useCallback(
        ({ metricLabel, path }) =>
            () => {
                submitLabelMetrics(metricLabel);
                if (pathname === path) {
                    if (window.scrollY > 0) {
                        // @ts-ignore
                        window.scrollTo({ top: 0, behavior: 'instant' });
                        setProductsPopoverVisible((prevState) => !prevState);
                    } else {
                        window.location.reload();
                    }
                }
            },
        [pathname, submitLabelMetrics],
    );

    return (
        <nav className="navbar-interaction">
            <h2 className="sr-only">Navigation</h2>
            <ul className="navlist">
                {(withBrowserPage || withSearchPage) && (
                    <li className="navlist__item navlist__item--menu-point">
                        <a
                            href="https://www.tempest.com"
                            className="navlist__link navlist__link--dropdown"
                            onClick={productsClickHandler}
                        >
                            {t('homepage-posttitle-text-introduce-products', 'Products')}
                        </a>
                        <ProductsPopover isVisible={productsPopoverVisible} ref={popOverRef} onClick={handleNavClick} />
                    </li>
                )}
                {withAboutPage && (
                    <li className="navlist__item navlist__item--menu-point">
                        <Link
                            to="/about"
                            className="navlist__link"
                            clickHandler={handleNavClick({ metricLabel: 'about', path: '/about' })}
                        >
                            {t('homepage-shared-header-about', 'About')}
                        </Link>
                    </li>
                )}
                <Menu className="simple-header__menu" isStickyBar={isStickyBar} isHomeMenu={true} />
            </ul>
        </nav>
    );
};

export default NavigationBarInteraction;
