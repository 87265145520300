import './BusinessInfoCard.scss';

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { isIOS, isMobile, isTablet } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { bindActionCreators } from 'redux';

import { changeSearchType } from '../../../../actions/app';
import { getLocationWebsite, setActiveCardFromWidget } from '../../../../actions/search';

import LgView from '../../responsiveComponents/components/LgView/LgView';
import SmView from '../../responsiveComponents/components/SmView/SmView';

import companyConfigs from '../../../../constants/company';
import searchTypes from '../../../../constants/searchTypes';
import { handleBusinessCardUserAvatar, safeParsePhoneNumber } from '../../../../utils/helpers';
import { ReactComponent as GlobeIcon } from '../../../images/icons/business-info-card/globe-icon.svg';
import { ReactComponent as MapIcon } from '../../../images/icons/business-info-card/map-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../images/icons/business-info-card/phone-icon.svg';
import { ReactComponent as CloseIcon } from '../../../images/icons/close-icon.svg';
import Rating from '../../Rating/Rating';
import ToggleSchedule from '../../ToggleSchedule/ToggleSchedule';
import WidgetTemplate from '../../WidgetTemplate/WidgetTemplate';
import BusinessBrief from '../BusinessBrief/BusinessBrief';

const momentTZ = require('moment-timezone');
const isMobileButNotIPad = isMobile && !(isTablet && isIOS);

function BusinessInfoCard(props) {
    const {
        t,
        businessWidget,
        isAMPM,
        language,
        wrapperClassName,
        isMapView,
        withCloseButton,
        setActiveCard,
        changeSearchType,
        setActiveCardFromWidget,
        provider,
        withAnimatedBorder,
        businessWidgetMetaData,
        isSwipeable,
    } = props;
    const withAppleMapsDetailedView = companyConfigs?.features?.withAppleMapsDetailedView;
    const business = businessWidget.business;
    const isYelp = business?.url?.includes('yelp');
    const imageSrc = business?.image_urls ? business.image_urls : business?.image_url ? [business?.image_url] : null;
    const imgAmount = useMediaQuery({ minWidth: 760 }) || isSwipeable ? 3 : 1;
    const [isShowSchedule, setIsShowSchedule] = useState(isMapView);
    const [website, setWebsite] = useState(business?.url);
    const closesAt = t('infocard-closes-at', 'Closes at');
    const opensAt = t('infocard-opens-at', 'Opens at');

    const showOpenClosedStatus = business?.opening_hours?.length > 0;

    const imagesToRender = imageSrc && imageSrc.length ? imageSrc.filter((imageUrl, index) => index < imgAmount) : [];

    useEffect(() => {
        if (!business) {
            return;
        }

        if (business.website) {
            setWebsite(business.website);
        } else if (business.alias) {
            getLocationWebsite(business.alias).then((website) => {
                if (!!website) {
                    setWebsite(website);
                }
            });
        }
        return () => {
            setWebsite(null);
        };
    }, [business]);

    const [isOpen, workingDays, mapOpeningHours, getCurrentDayNumber, openCloseTime] = useMemo(() => {
        function getNextOpeningHours(mapOpeningHours, getCurrentDayNumber, getUserTime, getNextDayNumber) {
            if (mapOpeningHours[getCurrentDayNumber]?.length > 1) {
                const formatUserTime = parseInt(getUserTime.format('HHmm'));
                const secondHalfOfDay = mapOpeningHours[getCurrentDayNumber][1];
                if (parseInt(secondHalfOfDay.start) > formatUserTime) {
                    return secondHalfOfDay.start;
                }
            }
            return mapOpeningHours?.[getNextDayNumber]?.[0]?.start;
        }

        function getNextClosingHours(mapOpeningHours, getCurrentDayNumber, getUserTime) {
            const formatUserTime = parseInt(getUserTime.format('HHmm'));
            const firstHalfOfDay = mapOpeningHours?.[getCurrentDayNumber]?.[0];
            const secondHalfOfDay = mapOpeningHours[getCurrentDayNumber]?.[1];
            if (mapOpeningHours[getCurrentDayNumber]?.length > 1) {
                if (parseInt(firstHalfOfDay?.end) > formatUserTime) {
                    return firstHalfOfDay?.end;
                } else if (parseInt(secondHalfOfDay?.end) > formatUserTime) {
                    return secondHalfOfDay?.end;
                }
            }

            return secondHalfOfDay ? secondHalfOfDay.end : firstHalfOfDay.end;
        }

        function processData(business, provider) {
            const dayModifier = provider === 'yelp' ? 0 : 1;
            if (!business?.opening_hours) {
                return [false, [], [], 0, ''];
            }

            const mapOpeningHours = [];
            business.opening_hours.forEach((item) => {
                if (mapOpeningHours[item.day]) {
                    mapOpeningHours[item.day].push(item);
                } else if (!mapOpeningHours[item.day]) {
                    mapOpeningHours[item.day] = [item];
                }
            });
            const workingDays = Object.keys(mapOpeningHours).map((x) => parseInt(x));

            const lowestDayNumber = Math.min(...workingDays);
            const highestDayNumber = Math.max(...workingDays);

            const getLocationTimeZone = momentTZ().tz(business.timezone).format();

            const getUserTime = moment().utcOffset(getLocationTimeZone);
            const getDayNumber = getUserTime.day() - 1;
            const getCurrentDayNumber = getDayNumber;
            const isOpen = business && !business.is_closed && workingDays.includes(getCurrentDayNumber);

            const getNextDayNumber =
                getDayNumber === highestDayNumber - dayModifier
                    ? lowestDayNumber
                    : workingDays[workingDays.findIndex((x) => x === getDayNumber) + 1];
            const openCloseTime = isOpen
                ? `${closesAt} ${formatTime(getNextClosingHours(mapOpeningHours, getCurrentDayNumber, getUserTime))}`
                : `${opensAt} ${formatTime(
                      getNextOpeningHours(mapOpeningHours, getCurrentDayNumber, getUserTime, getNextDayNumber),
                  )}`;
            return [isOpen, workingDays, mapOpeningHours, getCurrentDayNumber, openCloseTime];
        }
        return processData(business, provider);
        //eslint-disable-next-line
    }, [business, provider]);

    function toggleSchedule(toggle) {
        setIsShowSchedule((isShowSchedule) => !isShowSchedule);
        toggle();
    }

    function formatTime(time) {
        const { isAMPM } = props;
        return moment(time, 'hmm').format(isAMPM ? 'h:mm A' : 'HH:mm');
    }

    function handleBusinessClick() {
        handleOpenLinkClick(website ? website : business.url);
    }

    function handleReviewClick() {
        handleOpenLinkClick(business.url);
    }

    function handleOpenLinkClick(url) {
        const { openInNewTab } = props;
        if (!url) {
            return;
        }
        if (openInNewTab) {
            window.open(url, '_blank');
        } else {
            window.location.assign(url);
        }
    }

    function replaceYelpUrl(url) {
        return url.replace(/s3-media[\d]+\.fl\.yelpcdn\.com/, 's3-media-yelp.tempest.com');
    }

    const formatDate = (data) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        };
        return new Intl.DateTimeFormat(language || 'en-EN', options).format(new Date(data));
    };

    const openMapModal = (e) => {
        e.preventDefault();
        if (withAppleMapsDetailedView && !isMapView) {
            setActiveCardFromWidget(0);
            changeSearchType(searchTypes.map);
        }
    };

    if (!business) {
        return null;
    }

    const renderReviews = (review, i) => {
        return (
            <React.Fragment key={i}>
                <div className="single-business-card__review">
                    <div className="single-business-card__review-top">
                        {review.user_avatar && (
                            <div className="single-business-card__review-avatar">
                                <img
                                    className="single-business-card__review-img"
                                    src={handleBusinessCardUserAvatar(review.user_avatar)}
                                    alt=""
                                />
                            </div>
                        )}
                        {review.rating && (
                            <Rating
                                className="single-business-card__review-rating"
                                value={review.rating}
                                iconType={isYelp ? 'yelp' : 'tripadvisor'}
                            />
                        )}
                        {review.published_date ? (
                            <time className="single-business-card__review-date" dateTime={review.published_date}>
                                {formatDate(review.published_date)}
                            </time>
                        ) : null}
                    </div>
                    <blockquote className="single-business-card__review-text-wrapper">
                        <p className="single-business-card__review-text">
                            “{review.text.trim().replace(/\s{2,}/g, ' ')}”
                        </p>
                    </blockquote>
                </div>
            </React.Fragment>
        );
    };

    return (
        <WidgetTemplate.Wrapper className={`${wrapperClassName}`}>
            <WidgetTemplate.Paper className="single-business-card" withAnimatedBorder={withAnimatedBorder}>
                {withCloseButton && (
                    <CloseIcon className="single-business-card__close-icon" onClick={() => {
                        setActiveCard(null);
                    }} />
                )}
                <header className="single-business-card__top">
                    <div className="single-business-card__info">
                        <h2
                            className={`single-business-card__title${
                                isMapView ? '' : ' single-business-card__title--clickable'
                            }`}
                            onClick={openMapModal}
                        >
                            {business.name}
                        </h2>
                        {!!business.rating && (
                            <BusinessBrief
                                isVerticalView
                                ratingValue={business.rating}
                                reviewCount={business.review_count}
                                priceRate={business.price}
                                categoriesArray={business.categories}
                                provider={provider}
                                withCutCategories={false}
                                isYelp={isYelp}
                            />
                        )}
                        {showOpenClosedStatus && (
                            <SmView>
                                <strong
                                    className={`single-business-card__status${
                                        isOpen
                                            ? ' single-business-card__status--open'
                                            : ' single-business-card__status--closed'
                                    }`}
                                >
                                    {isOpen ? t('infocard-open', 'Open') : t('infocard-closed', 'Closed')}
                                </strong>
                            </SmView>
                        )}
                    </div>
                    {imagesToRender.length > 0 && (
                        <figure className="single-business-card__img-container">
                            {provider && (
                                <figcaption className="single-business-card__img-text">
                                    {isYelp ? t('business-info-card-img-text-yelp') : t('business-info-card-img-text')}
                                </figcaption>
                            )}
                            {imagesToRender.map((imageUrl, i) => {
                                return (
                                    <img
                                        key={i}
                                        className={`single-business-card__img single-business-card__img--${imagesToRender.length}`}
                                        alt={business.name}
                                        src={isYelp ? replaceYelpUrl(imageUrl) : imageUrl}
                                    />
                                );
                            })}
                        </figure>
                    )}
                </header>
                <WidgetTemplate.Block className="single-business-card__block single-business-card__buttons">
                    <button className="single-business-card__button" onClick={handleBusinessClick}>
                        <GlobeIcon />
                        <span className="single-business-card__button-text">{t('business-info-card-website')}</span>
                    </button>
                    {business.phone && (
                        <a href={`tel:${business.phone}`} className="single-business-card__button">
                            <PhoneIcon />
                            <div className="single-business-card__button-text">{t('business-info-card-call')}</div>
                        </a>
                    )}
                    {!isMapView && withAppleMapsDetailedView && (
                        <button className="single-business-card__button" onClick={openMapModal}>
                            <MapIcon />
                            <div className="single-business-card__button-text">
                                {t('business-info-card-map', 'Map')}
                            </div>
                        </button>
                    )}
                </WidgetTemplate.Block>
                <WidgetTemplate.Block className="single-business-card__block single-business-card__contacts">
                    <h3 className="sr-only">{t('footer-about')}</h3>
                    {showOpenClosedStatus && (
                        <div className="single-business-card__contacts-row single-business-card__contacts-row--schedule">
                            <LgView>
                                <div className="single-business-card__contacts-title">Hours</div>
                            </LgView>
                            <ToggleSchedule
                                onClick={(toggle) => {
                                    toggleSchedule(toggle);
                                }}
                                isAMPM={isAMPM}
                                isOpen={isOpen}
                                workingDays={workingDays}
                                isShowSchedule={isShowSchedule}
                                mapOpeningHours={mapOpeningHours}
                                getCurrentDayNumber={getCurrentDayNumber}
                                openCloseTime={openCloseTime}
                                withTitle
                                language={language}
                                isShortDay
                            />
                        </div>
                    )}
                    <div className="single-business-card__contacts-row">
                        <div className="single-business-card__contacts-title">{t('business-info-card-address')}</div>
                        <p className="single-business-card__contacts-value">
                            <span
                                className={`single-business-card__contacts-text${
                                    isMapView || !withAppleMapsDetailedView
                                        ? ''
                                        : ' single-business-card__contacts-text--fake-link'
                                }`}
                                onClick={openMapModal}
                            >
                                {business.location.display_address.join(', ')}
                            </span>
                        </p>
                    </div>
                    {business.phone && (
                        <div className="single-business-card__contacts-row">
                            <div className="single-business-card__contacts-title">{t('business-info-card-phone')}</div>
                            <address className="single-business-card__contacts-value">
                                <a href={`tel:${business.phone}`} className="single-business-card__contacts-link">
                                    {safeParsePhoneNumber(business.phone)}
                                </a>
                            </address>
                        </div>
                    )}
                </WidgetTemplate.Block>
                {business?.reviews?.length > 0 && (
                    <WidgetTemplate.Block className="single-business-card__block single-business-card__reviews">
                        <LgView>
                            <h3 className="single-business-card__reviews-title">
                                {t('business-info-card-reviews-title')}
                            </h3>
                        </LgView>
                        {business.reviews.map((review, i) => {
                            if ((!isMobileButNotIPad && i < 3) || (isMobileButNotIPad && i === 0)) {
                                return renderReviews(review, i);
                            }
                            return null;
                        })}
                        <button
                            className="single-business-card__review-button"
                            onClick={handleReviewClick}
                            type="button"
                        >
                            {isYelp ? t('business-info-card-more-reviews-yelp') : t('business-info-card-more-reviews')}
                        </button>
                    </WidgetTemplate.Block>
                )}
            </WidgetTemplate.Paper>
            <WidgetTemplate.Footer
                withFeedback
                withDetails
                infocardData={{
                    byTempest: t('infocard-by-tempest-best-places'),
                    provider: businessWidgetMetaData?.providers[0],
                }}
            />
        </WidgetTemplate.Wrapper>
    );
}

BusinessInfoCard.defaultProps = {
    className: '',
    withCloseButton: false,
    withAnimatedBorder: true,
};

BusinessInfoCard.propTypes = {
    className: PropTypes.string,
    withCloseButton: PropTypes.bool,
    withAnimatedBorder: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeSearchType,
            setActiveCardFromWidget,
        },
        dispatch,
    );
}

export default connect(null, mapDispatchToProps)(withTranslation('common')(BusinessInfoCard));
