/**
 * # About Button
 * "About" button underneath widgets.
 */

/**
 * ## Imports
 */
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';

import { freezeBody, unfreezeBody } from '../../../utils/helpers';
import { ReactComponent as InfoIcon } from '../../images/icons/info-icon-1.svg';
import AboutButtonPopup from '../AboutButtonPopup/AboutButtonPopup';

/**
 * ## Component
 */
type AboutButtonProps = {
    classList?: string;
    data: {
        byTempest: string;
        provider: unknown;
        openInNewTab?: boolean;
    };
};

const AboutButton: FC<AboutButtonProps> = ({ classList, data }) => {
    const { t } = useTranslation('websiteV2');
    const [showAboutButtonPopup, setShowAboutButtonPopup] = useState(false);

    const showAboutButtonPopupHandler = () => {
        setShowAboutButtonPopup(true);
        freezeBody(true);
    };

    const closeAboutButtonPopupHandler = () => {
        setShowAboutButtonPopup(false);
        unfreezeBody();
    };

    return (
        <>
            <button
                className={`infocard-details${classList ? ` ${classList}` : ``}`}
                onClick={showAboutButtonPopupHandler}
            >
                <InfoIcon className="infocard-details__icon" />
                {t('infocard-more-details', 'About')}
            </button>

            <Popup
                className="feedback-popup"
                open={showAboutButtonPopup}
                onClose={closeAboutButtonPopupHandler}
                onOpen={showAboutButtonPopupHandler}
                modal
                nested
                position={'bottom center'}
            >
                <AboutButtonPopup onClose={closeAboutButtonPopupHandler} data={data} />
            </Popup>
        </>
    );
};

export default AboutButton;
