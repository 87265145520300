import React, { useEffect, useRef, useState } from 'react';
import { isIOS, isTablet } from 'react-device-detect';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { bindActionCreators } from 'redux';
import { Field, change, reduxForm } from 'redux-form';

import { openMobileInput, setRecentActionData, submitMetrics } from '../../../actions/app';
import { clearSuggestions, getSuggestions, setSearchResultCacheDisabled } from '../../../actions/search';

import constants, { analyticsType } from '../../../constants';
import breakpoints from '../../../constants/breakpoints';
import { action, category } from '../../../constants/metrics';
import { ReactComponent as ClearIcon } from '../../../img/report-card/cross.svg';
import { addBodyClass, freezeBody, removeBodyClass, scrollToTop, unfreezeBody } from '../../../utils/helpers';
import useClickOutside from '../../hooks/useClickOutside';
import { ReactComponent as BackIcon } from '../../images/icons/arrow-left-full.svg';
import { ReactComponent as ArrowUpLeftIcon } from '../../images/icons/arrow-up-left.svg';
import { ReactComponent as SearchSmallIcon } from '../../images/icons/search-small.svg';
import { ReactComponent as SearchIcon } from '../../images/icons/search.svg';
import Input from '../Input/Input';

const bodyClassName = 'search-suggestions-open';

function SearchInput({
    suggestions,
    getSuggestions,
    showSuggestions,
    clearSuggestions,
    searchFieldIsOnFocus,
    type,
    dispatch,
    makeSearch,
    handleSubmit,
    setSearchResultCacheDisabled,
    className,
    formState,
    autoFocus,
    withBackButton = true,
    isMobileInputOpen,
    openMobileInput,
    submitMetrics,
    setRecentActionData,
}) {
    const { t } = useTranslation('common');
    const [showOptions, setShowOptions] = useState(false);
    const [cursor, setCursor] = useState(-1);
    const [isMobileViewOpen, setIsMobileViewOpen] = useState(false);
    const [originSearchField, setOriginSearchField] = useState(null);
    const [blurListenerAdded, setBlurListenerAdded] = useState(false);

    const searchFormRef = useRef(null);

    const isMobile = !useMediaQuery({
        minWidth: breakpoints.minWidthSmBreakPoint,
    });
    const isHomePage = type === constants.pageTypes.home;

    useClickOutside({
        element: searchFormRef.current,
        onClickOutside: () => {
            closeSuggestions();
        },
        condition:
            typeof searchFormRef?.current?.className === 'string' &&
            !searchFormRef?.current?.className.includes('suggestion'),
    });

    useEffect(() => {
        if (!isMobile) {
            window.addEventListener('scroll', closeSuggestions);
            if (!blurListenerAdded) {
                window.addEventListener('blur', () => {
                    getInputElement()?.blur();
                    closeSuggestions();
                });
                setBlurListenerAdded(true);
            }
        }

        return () => {
            if (!isMobile) {
                window.removeEventListener('scroll', closeSuggestions);
            }
            closeMobileView();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isHomePage && !isMobile) {
            if (!searchFieldIsOnFocus) {
                closeSuggestions();
            }
        }
        // eslint-disable-next-line
    }, [showOptions, type]);

    useEffect(() => {
        if (isMobile && isMobileInputOpen) {
            openMobileView();
        }
        // eslint-disable-next-line
    }, [isMobileInputOpen, isMobile]);

    const openSuggestions = () => {
        addBodyClass(bodyClassName);
        setShowOptions(true);
    };

    const closeSuggestions = () => {
        if (!isMobileViewOpen) {
            removeBodyClass(bodyClassName);
        }
        setShowOptions(false);
    };

    const fetchSuggestions = (event) => {
        const inputValue = event?.target?.value?.trim();
        setOriginSearchField(inputValue);
        if (inputValue && showSuggestions) {
            openSuggestions();
            setCursor(-1);
            getSuggestions(inputValue);
        } else if (!inputValue && showSuggestions) {
            clearSuggestions();
        }
    };

    const handleClear = (e) => {
        const el = getInputElement();
        el.focus();
        e.preventDefault();
        dispatch(change('search_input', 'search_field', null));
        closeSuggestions();
        clearSuggestions();
        setOriginSearchField(null);
    };

    const handleSuggestionClick = (input) => {
        Promise.all([dispatch(change('search_input', 'search_field', input))]).then(() => {
            setOriginSearchField(input);
            scrollToTop();
            setRecentActionData(null);
            if (isHomePage) {
                handleSubmit();
            } else {
                makeSearch(input);
                if (isMobile) {
                    closeMobileView();
                }
            }
        });
        if (!isMobile) {
            clearSuggestions();
        }
        setCursor(-1);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            e.preventDefault();
            closeSuggestions();
            return;
        }

        if (e.key === 'Enter' && suggestions?.length > 0 && cursor === -1) {
            clearSuggestions();
            closeSuggestions();
            return;
        }

        if (e.key === 'Enter' && suggestions?.length > 0 && cursor >= 0) {
            dispatch(change('search_input', 'search_field', suggestions[cursor]));
            clearSuggestions();
            setCursor(-1);
            closeSuggestions();
            return;
        }

        if (e.key === 'Tab' && suggestions?.length > 0 && cursor !== -1) {
            e.preventDefault();
            dispatch(change('search_input', 'search_field', suggestions[cursor]));
            return;
        }

        if (e.key === 'ArrowUp' && cursor > 0) {
            e.preventDefault();
            dispatch(change('search_input', 'search_field', suggestions[cursor - 1]));
            setCursor((prevState) => prevState - 1);
            return;
        }

        if (e.key === 'ArrowDown' && cursor < suggestions?.length - 1) {
            e.preventDefault();
            dispatch(change('search_input', 'search_field', suggestions[cursor + 1]));
            setCursor((prevState) => prevState + 1);
            return;
        }
    };

    const openMobileView = () => {
        if (isMobile) {
            setIsMobileViewOpen(true);
            openSuggestions();
            freezeBody();
            setTimeout(() => {
                handleScrollToTop();
            }, 80);
            const el = getInputElement();
            el.focus();
        } else {
            handleScrollToTop();
        }
    };

    const closeMobileView = () => {
        if (isMobile) {
            closeSuggestions();
            openMobileInput(false);
            setIsMobileViewOpen(false);
            unfreezeBody();
            document.activeElement.blur();
            removeBodyClass(bodyClassName);
        }
    };

    const handleMouseLeave = () => {
        setCursor(-1);
    };

    const handleFocus = (event) => {
        fetchSuggestions(event);
        handleScrollToTop();
    };

    const handleScrollToTop = () => {
        if (isHomePage) {
            scrollToTop();
        }
    };

    const submit = (e, input) => {
        e.preventDefault();
        if (!originSearchField) return;

        submitMetrics(
            {
                Category: isHomePage ? category.homepage : category.serp,
                Action: action.search,
                Object: 'query',
                Value: 'query',
            },
            isHomePage ? analyticsType.wb : analyticsType.serp,
        );

        setSearchResultCacheDisabled(true);
        setRecentActionData(null);
        scrollToTop();
        // Redux form automatically inserts the query to the handler passed into SearchInput as `onSubmit`
        handleSubmit();

        if (isMobile || (isTablet && isIOS)) {
            if (isHomePage) {
                document.activeElement.blur();
            } else {
                closeMobileView();
            }
        }
    };

    const getInputElement = () => {
        return document.getElementById('formInput');
    };

    const hasSuggestions = showSuggestions && suggestions?.length > 0 && formState.values && showOptions;

    return (
        <form
            className={`form search-input${hasSuggestions ? ' search-input--open' : ''}
			${isMobileViewOpen ? ' search-input--mobile-active' : ''}
			${isMobileViewOpen ? ' search-input--force-active' : ''}
			${className ? ` ${className}` : ''}`}
            onSubmit={submit}
            onMouseLeave={handleMouseLeave}
            action=""
            ref={searchFormRef}
        >
            {!isMobileViewOpen && (
                <span className="search-input__left-icon">
                    <SearchSmallIcon className="search-input__left-icon-svg" />
                </span>
            )}
            <button
                className="search-input__button search-input__button--search"
                type="button"
                onClick={() => {
                    if (!formState?.values) {
                        getInputElement().focus();
                        openMobileView();
                    } else {
                        setSearchResultCacheDisabled(true);
                        setRecentActionData(null);
                        scrollToTop();
                        handleSubmit();
                    }
                }}
            >
                <SearchIcon className="search-input__search-icon" />
            </button>
            {isMobileViewOpen && withBackButton && (
                <button
                    className="search-input__button search-input__button--back"
                    type="button"
                    onClick={() => closeMobileView()}
                >
                    <BackIcon className="search-input__back-icon" />
                </button>
            )}
            <Field
                autoFocus={autoFocus}
                name="search_field"
                component={Input}
                searchable
                type="search"
                onChange={fetchSuggestions}
                onClick={openMobileView}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                placeholder={t('search-input-placeholder')}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
            />
            {formState?.values && (
                <button className="search-input__clear" onClick={handleClear} type="button">
                    <ClearIcon className="search-input__clear-icon" />
                    <span className="search-input__clear-border"> </span>
                </button>
            )}

            {hasSuggestions && (
                <ul className="search-input__suggestions-list">
                    {suggestions.map((suggestion, i) => {
                        return (
                            <li
                                key={i}
                                className={`search-input__suggestions-item${
                                    cursor === i ? ' search-input__suggestions-item--active' : ''
                                }`}
                                value={suggestion}
                                onClick={() => handleSuggestionClick(suggestion)}
                                onMouseOver={() => {
                                    setCursor(i);
                                }}
                            >
                                <div className="search-input__suggestions-item-inner">
                                    <button
                                        className="search-input__suggestions-item-button search-input__suggestions-item-button--search"
                                        type="button"
                                        onClick={() => closeMobileView()}
                                    >
                                        <SearchSmallIcon className="search-input__suggestions-search-icon" />
                                    </button>
                                    {(cursor === i || isMobileViewOpen) && (
                                        <button
                                            className="search-input__suggestions-item-button search-input__suggestions-item-button--arrow"
                                            type="button"
                                            onClick={() => closeMobileView()}
                                        >
                                            <ArrowUpLeftIcon className="search-input__suggestions-arrow-icon" />
                                        </button>
                                    )}
                                    {suggestion
                                        .split(originSearchField)
                                        .map((text, i) => {
                                            return (
                                                <span key={i} className="search-input__suggestions-item--bold">
                                                    {text}
                                                </span>
                                            );
                                        })
                                        .reduce((prev, curr) => [prev, originSearchField, curr])}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            )}
        </form>
    );
}

const validate = (values) => {
    const errors = {};
    if (!values.search_field) {
        errors.search_field = 'Required';
    }
    return errors;
};

const mapStateToProps = (state) => ({
    formState: state.form.search_input,
    suggestions: state.search.suggestions,
    showSuggestions: state.app.showSuggestions,
    isMobileInputOpen: state.app.isMobileInputOpen,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSuggestions,
            clearSuggestions,
            setSearchResultCacheDisabled,
            openMobileInput,
            submitMetrics,
            setRecentActionData,
            dispatch,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    withTranslation('common')(
        reduxForm({
            form: 'search_input',
            destroyOnUnmount: false,
            validate: validate,
        })(SearchInput),
    ),
);
