import './Footer.scss';

import React, { PureComponent, Suspense, lazy } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeHomePageSection, changePageType, submitMetrics } from '../../../../../actions/app';

import history from '../../../../../app/history';
import companyConfigs from '../../../../../constants/company';
import { action, category } from '../../../../../constants/metrics';
import CompanyLogo from '../../../../../features/Home/src/CompanyLogo';
import { ReactComponent as LogoIcon } from '../../../../images/icons/layout-icons/logo-icon.svg';
import Link from '../../../Link/Link';

const {
    withAboutPage,
    withBrowserPage,
    withSearchPage,
    withTermsOfUsePage,
    withPrivacyPolicyGdprPage,
    withPrivacyPolicyPage,
} = companyConfigs?.webPages;
const { withSearchFeedbackButton } = companyConfigs?.features;

const FeedbackButton = lazy(() => import('../../../FeedbackButton/FeedbackButton'));

class Footer extends PureComponent {
    linkEventHandler(event, type) {
        const { changeHomePageSection } = this.props;
        this.submitLabelMetrics(type);
        event.preventDefault();
        changeHomePageSection(type);
        history.push('/');
    }

    submitLabelMetrics(value) {
        this.props.submitMetrics({
            Category: category.serp,
            Action: action.click,
            Object: 'label',
            Value: value,
        });
    }

    render() {
        const { t, withFeedbackButton, isFixedFooter } = this.props;
        const isButtonVisible = withSearchFeedbackButton && withFeedbackButton;
        const shouldRenderFooterMenu =
            withAboutPage ||
            withSearchPage ||
            withBrowserPage ||
            withPrivacyPolicyGdprPage ||
            withTermsOfUsePage ||
            withPrivacyPolicyPage;
        return (
            <>
                {isButtonVisible && (
                    <div className="float-feedback">
                        <div className="float-feedback__title">
                            {t('footer-feedback-section-title', 'Help us improve Tempest')}
                        </div>
                        <div className="float-feedback__text">
                            {t(
                                'footer-feedback-section-text',
                                'Please let us know if you see something wrong. Your feedback will be highly appreciated.',
                            )}
                        </div>
                        <Suspense fallback={<div></div>}>
                            <FeedbackButton className="float-feedback__button" isInfoCard={false} isFloating>
                                {t('feedback-send', 'Send Feedback')}
                            </FeedbackButton>
                        </Suspense>
                    </div>
                )}
                <footer className={`footer${isFixedFooter ? ' footer--fixed' : ''}`}>
                    <div className="inner footer__inner">
                        <div className="footer__menu">
                            <Link to="/" className="footer__logo">
                                <CompanyLogo svgComponent={<LogoIcon />} width={25} height={25} />
                            </Link>
                            {shouldRenderFooterMenu && (
                                <div className="footer__menu-body">
                                    <div className="footer__menu-items">
                                        {withAboutPage && (
                                            <Link
                                                to="/about"
                                                className="footer__menu-item"
                                                clickHandler={() => this.submitLabelMetrics('about')}
                                            >
                                                {t('footer-about', 'About')}
                                            </Link>
                                        )}
                                        {withSearchPage && (
                                            <Link
                                                to="/tempest-search"
                                                className="footer__menu-item"
                                                clickHandler={() => this.submitLabelMetrics('search')}
                                            >
                                                {t('footer-search', 'Search')}
                                            </Link>
                                        )}
                                        {withBrowserPage && (
                                            <Link
                                                to="/browser"
                                                className="footer__menu-item"
                                                clickHandler={() => this.submitLabelMetrics('browser')}
                                            >
                                                {t('footer-browser', 'Browser')}
                                            </Link>
                                        )}
                                    </div>
                                    {/* TODO uncomment when Support page will be ready */}
                                    {/* <Link
										to="/support"
										className="footer__menu-item"
										clickHandler={() =>
											this.submitLabelMetrics('support')
										}
									>
										{t('footer-support', 'Support')}
									</Link> */}

                                    <div className="footer__menu-items">
                                        {withPrivacyPolicyPage && (
                                            <Link
                                                to="/privacy-policy"
                                                className="footer__menu-item"
                                                clickHandler={() => this.submitLabelMetrics('privacyPolicy')}
                                            >
                                                {t('footer-privacy', 'Privacy Policy')}
                                            </Link>
                                        )}
                                        {withTermsOfUsePage && (
                                            <Link
                                                to="/terms-of-service"
                                                className="footer__menu-item"
                                                clickHandler={() => this.submitLabelMetrics('termsOfService')}
                                            >
                                                {t('footer-terms', 'Terms of Service')}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changePageType,
            changeHomePageSection,
            dispatch,
            submitMetrics,
        },
        dispatch,
    );
}

export default connect(null, mapDispatchToProps)(withTranslation('common')(Footer));
