import './MovieCollectionInfocard.scss';

import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { setRecentActionData } from '../../../../actions/app';
import { getPublicKey } from '../../../../actions/search';

import breakpoints from '../../../../constants/breakpoints';
import { encrypt } from '../../../../utils/cryptoHelper';
import { validatePublicKey } from '../../../../utils/helpers';
import { ReactComponent as TMDBIcon } from '../../../images/icons/tmdb-icon.svg';
import WidgetCTA from '../../WidgetCTA/WidgetCTA';
import WidgetTemplate from '../../WidgetTemplate/WidgetTemplate';
import MovieProfileItem from '../MovieProfileItem/MovieProfileItem';
import MovieSlider from '../MovieSlider/MovieSlider';
import MovieSliderItem from '../MovieSliderItem/MovieSliderItem';

type MovieCollectionInfocardProps = {
    data: {
        name: string;
        overview: string;
        base_url: string;
        backdrop_path: string;
        poster_path: string;
        homepage?: string;
        tmdb_homepage?: string;
        genres: Array<{
            id: number;
            name: string;
        }>;
        revenue: number;
        vote_average: number;
        vote_count: number;
        parts: Array<{
            id: number;
            title: string;
            release_date: string;
            poster_path: string;
        }>;
        credits: {
            cast: Array<{
                original_name: string;
                character: string;
                profile_path: string;
            }>;
        };
    };
    metaData: { providers: Array<string> };
};

const formatCurrency = (value: number): string => {
    if (!value) {
        return;
    }
    if (value >= 1.0e12) {
        return '$' + (value / 1.0e12).toFixed(1).replace('.0', '') + ' trillion';
    }
    if (value >= 1.0e9) {
        return '$' + (value / 1.0e9).toFixed(1).replace('.0', '') + ' billion';
    }
    if (value >= 1.0e6) {
        return '$' + (value / 1.0e6).toFixed(1).replace('.0', '') + ' million';
    }
    return `$${value}`;
};

const MovieCollectionInfocard = ({
    data,
    metaData,
    encryptionSetting,
    publicKey,
    publicKeyVersion,
    publicKeyExpirationTime,
    publicKeyDomain,
    openInNewTab,
    makeSearch,
}): React.ReactElement<MovieCollectionInfocardProps> => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);

    const toggleDetails = () => {
        if (isExpanded) {
            window.scrollTo(0, 0);
        }
        setIsExpanded((prevState) => !prevState);
    };

    const dispatch = useDispatch();

    const prepareSearchUrl = (query: string, movieId?: number): string => {
        let result = `/search?q=${query}`;
        if (encryptionSetting && publicKey) {
            const encryptedQuery = encrypt(
                { q: query, rh: 1, wb: 1, wbvers: 'v2' },
                publicKey,
                publicKeyVersion,
                'MovieInfocard',
            );
            result = `/search?encv=${encryptedQuery.encv}&encq=${encodeURIComponent(encryptedQuery.encq)}`;
        }
        if (movieId) {
            result += `&movie=${movieId}`;
        }
        return result;
    };

    const searchCastQuery = (query: string) => {
        dispatch(setRecentActionData(null));
        makeSearch(query);
    };

    const searchMoreMoviesQuery = (title: string, movieId: number) => {
        dispatch(setRecentActionData(null));
        makeSearch(title, null, null, false, false, {
            postPayload: {
                infocard: 'movie',
                movie_type: 'movie',
                movie_id: movieId,
            },
        });
    };

    const isMobile: boolean = useMediaQuery({ maxWidth: breakpoints.maxWidthSmBreakPoint });
    const backdropPath: string = data.base_url + data.backdrop_path;
    const posterPath: string = data.base_url + data.poster_path;
    const getHomePageSrc: string = data?.homepage ? data.homepage : data?.tmdb_homepage;
    const genres: string = data.genres?.map((g) => g.name).join('/') || '';
    const revenue: string = formatCurrency(data?.revenue);
    const showAdditionalSections: boolean = !isMobile || isExpanded;
    const movieRating: string = `${parseInt(data.vote_average * 10)}%`;
    const movieParts = useMemo(
        () => (data.parts?.length ? data.parts.sort((a, b) => a.release_date > b.release_date).slice(0, 10) : null),
        [data],
    );

    useEffect(() => {
        if (initialized) {
            return;
        }

        if (encryptionSetting && !validatePublicKey(publicKey, publicKeyExpirationTime, publicKeyDomain)) {
            dispatch(getPublicKey());
        }

        setInitialized(true);
        // eslint-disable-next-line
    }, [data, initialized]);

    return (
        <WidgetTemplate.Wrapper>
            <WidgetTemplate.Paper className="movie-collection" withAnimatedBorder={!isMobile}>
                {!isMobile && (
                    <span className="movie-collection__video-img-wrapper">
                        <figure
                            className="figure movie-infocard__poster-wrapper"
                            style={{
                                backgroundImage: `url(${backdropPath})`,
                            }}
                        >
                            <div className="movie-collection__poster-black-layer"></div>
                            <img src={posterPath} alt="Movie poster" className="movie-collection__video-img" />
                        </figure>
                    </span>
                )}
                <h2 className="movie-collection__title movie-infocard__title--top">
                    {getHomePageSrc ? (
                        <a
                            className="movie-collection__title-text movie-infocard__title-text--link"
                            href={getHomePageSrc}
                            target={openInNewTab ? '_blank' : '_self'}
                            rel="noopener noreferrer"
                        >
                            {data.name}
                        </a>
                    ) : (
                        <span className="movie-collection__title-text">{data.name}</span>
                    )}
                    {data.vote_average > 0 && (
                        <span className="movie-collection__rating-short">
                            <span className="movie-collection__rating-icon" />
                            &nbsp;
                            <span className="movie-collection__rating-text">
                                <span className="movie-collection__rating-label">{movieRating}&nbsp;</span>
                                <abbr title="The Movie Database" className="abbreviation">
                                    TMDb
                                </abbr>
                            </span>
                        </span>
                    )}
                </h2>
                <h3 className="movie-collection__subtitle">
                    <span>Movie series</span>
                    {genres && (
                        <>
                            <span className="movie-collection__dot">&nbsp;•&nbsp;</span>
                            {genres}
                        </>
                    )}
                </h3>
                {isMobile && (
                    <div className="movie-collection__media">
                        <span className="movie-collection__poster">
                            <img src={posterPath} alt="" className="movie-collection__poster-img" />
                        </span>
                    </div>
                )}
                <WidgetTemplate.Block className="movie-collection__block">
                    <p className="movie-collection__text">{data.overview}</p>
                </WidgetTemplate.Block>
                <WidgetTemplate.Block className="movie-collection__block">
                    <ul className="movie-collection__lines">
                        {data.parts?.length && (
                            <li className="line">
                                <em className="line__caption">
                                    Number of Movies
                                    <span className="line__colon">:&nbsp;</span>
                                </em>
                                <div className="line__value">
                                    <div className="line__value-item">{data.parts?.length}</div>
                                </div>
                            </li>
                        )}
                        {revenue && (
                            <li className="line">
                                <em className="line__caption">
                                    Revenue
                                    <span className="line__colon">:&nbsp;</span>
                                </em>
                                <div className="line__value">
                                    <div className="line__value-item">{revenue}</div>
                                </div>
                            </li>
                        )}
                    </ul>
                </WidgetTemplate.Block>
                {movieParts && (
                    <WidgetTemplate.Block className="movie-collection__block">
                        <h3 className="movie-collection__title">Movies</h3>
                        <MovieSlider className="movie-collection__slider-movies">
                            {movieParts.map(({ title, release_date, poster_path, id }) => (
                                <MovieSliderItem
                                    mainText={title}
                                    key={title}
                                    additionalText={moment(release_date).format('YYYY')}
                                    photoUrl={`${data.base_url}${poster_path}`}
                                    searchUrl={prepareSearchUrl(title, parseInt(id))}
                                    openInNewTab={openInNewTab}
                                    onClick={() => searchMoreMoviesQuery(title, parseInt(id))}
                                    mainTextHeight={3}
                                />
                            ))}
                        </MovieSlider>
                    </WidgetTemplate.Block>
                )}
                {showAdditionalSections && data.credits?.cast?.length && (
                    <WidgetTemplate.Block className="movie-collection__block">
                        <h3 className="movie-collection__title">Cast</h3>
                        <MovieSlider>
                            {data.credits?.cast?.slice(0, 10).map(({ original_name, character, profile_path }) => (
                                <MovieSliderItem
                                    mainText={original_name}
                                    key={original_name}
                                    additionalText={character}
                                    photoUrl={`${data.base_url}${profile_path}`}
                                    searchUrl={prepareSearchUrl(original_name)}
                                    openInNewTab={openInNewTab}
                                    onClick={() => searchCastQuery(original_name)}
                                />
                            ))}
                        </MovieSlider>
                    </WidgetTemplate.Block>
                )}
                {data.vote_average > 0 && (
                    <WidgetTemplate.Block className="movie-collection__block">
                        <h3 className="movie-collection__title movie-rating__title">Ratings</h3>
                        <div className="movie-rating">
                            <div className="movie-rating__img-wrapper">
                                <TMDBIcon />
                            </div>
                            <div className="movie-rating__info">
                                <div className="movie-rating__caption">
                                    The Movie Database (
                                    <abbr title="The Movie Database" className="abbreviation">
                                        TMDB
                                    </abbr>
                                    )
                                </div>
                                <div className="movie-rating__text">{data.vote_count.toLocaleString('en')} Ratings</div>
                            </div>
                            <div className="movie-rating__value">{movieRating}</div>
                        </div>
                    </WidgetTemplate.Block>
                )}
                {(data.external_ids || getHomePageSrc) && (
                    <WidgetTemplate.Block className="movie-collection__block">
                        <section className="movie-collection__profiles">
                            <h3 className="movie-collection__title">Profiles</h3>
                            <div className="movie-collection__profiles-list">
                                {data.external_ids?.facebook_id && (
                                    <MovieProfileItem
                                        label="Facebook"
                                        url={`https://www.facebook.com/${data.external_ids?.facebook_id}`}
                                        icon="/images/facebook.svg"
                                        openInNewTab={openInNewTab}
                                    />
                                )}
                                {data.external_ids?.imdb_id && (
                                    <MovieProfileItem
                                        label="IMDB"
                                        url={`https://www.imdb.com/title/${data.external_ids?.imdb_id}`}
                                        icon="/images/imdb.svg"
                                        openInNewTab={openInNewTab}
                                    />
                                )}
                                {data.external_ids?.twitter_id && (
                                    <MovieProfileItem
                                        label="Twitter"
                                        url={`https://www.twitter.com/${data.external_ids?.twitter_id}`}
                                        icon="/images/twitter.svg"
                                        openInNewTab={openInNewTab}
                                    />
                                )}
                                {data.external_ids?.instagram_id && (
                                    <MovieProfileItem
                                        label="Instagram"
                                        url={`https://www.instagram.com/${data.external_ids?.instagram_id}`}
                                        icon="/images/instagram.svg"
                                        openInNewTab={openInNewTab}
                                    />
                                )}
                                {getHomePageSrc && (
                                    <MovieProfileItem
                                        label="Website"
                                        url={getHomePageSrc}
                                        icon="/images/website.svg"
                                        openInNewTab={openInNewTab}
                                    />
                                )}
                            </div>
                        </section>
                    </WidgetTemplate.Block>
                )}
                {isMobile && (
                    <WidgetCTA
                        className="movie-collection__expand-button"
                        buttonType="vertical"
                        onClick={toggleDetails}
                        isRotateIcon={isExpanded}
                        buttonMode="light"
                    >
                        {isExpanded ? 'See Less' : 'See More'}
                    </WidgetCTA>
                )}
            </WidgetTemplate.Paper>
            <WidgetTemplate.Footer
                infocardData={{
                    byTempest: 'movies',
                    provider: metaData.providers[0],
                }}
                withDetails
                withFeedback
            />
        </WidgetTemplate.Wrapper>
    );
};

const mapStateToProps = (state, props) => ({
    publicKey: state.search.publicKey,
    publicKeyVersion: state.search.publicKeyVersion,
    publicKeyExpirationTime: state.search.publicKeyExpirationTime,
    publicKeyDomain: state.search.publicKeyDomain,
    encryptionSetting: state.app.encryptionSetting,
    moviesInfoCardMetaData: state.search.moviesInfoCardMetaData,
    openInNewTab: state.app.openInNewTab,
});

export default connect(mapStateToProps, null)(MovieCollectionInfocard);
