// @ts-nocheck
// import IntersectionVisibleComponentWrapper from "../../common/components/IntersectionVisibleComponentWrapper";
import './Home.scss';

import cx from 'classnames';
import React, { PureComponent, Suspense } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';

import { changeHomePageSection, changePageType, changeSearchType, changeTheme, submitMetrics } from '../../actions/app';
import {
    readQueryFromURL,
    resetReadQueryFromURL,
    runSearch,
    saveFormInput,
    searchRequest,
    setLocation,
} from '../../actions/search';

import BackToSearch from '../../common/components/BackToSearch/BackToSearch';
import NavigationBar from '../../common/components/NavigationBar/NavigationBar';
import PageFooter from '../../common/components/PageFooter/PageFooter';
import SearchInput from '../../common/components/SearchInput/SearchInput';

import history from '../../app/history';
import constants, { analyticsType } from '../../constants';
import companyConfigs from '../../constants/company';
import { action, category } from '../../constants/metrics';
import searchTypes from '../../constants/searchTypes';
//import CompanyLogoPride from './src/CompanyLogoPride';
import { ReactComponent as HomepageLogo } from '../../img/branding/logo-tempest.svg';
import CompanyLogo from './src/CompanyLogo';
import faqQuestionCatalog from './src/faqQuestionCatalog';

const SearchBanner = React.lazy(() => import('../../common/components/SearchBanner/SearchBanner'));
const NavigationBarSticky = React.lazy(() => import('../../common/components/NavigationBarSticky/NavigationBarSticky'));
const UnionSection = React.lazy(() => import('../../common/components/UnionSection/UnionSection'));
const HomepageContentTeaser = React.lazy(() =>
    import('../../common/components/HomepageContentTeaser/HomepageContentTeaser'),
);
const HomepageSlideAnimation = React.lazy(() =>
    import('../../common/components/HomepageSlideAnimation/HomepageSlideAnimation'),
);
const HomepageFaq = React.lazy(() => import('../../common/components/HomepageFaq/HomepageFaq'));

/**
 * Todo: how should the old waitlist block work and look like?
 */
// import JoinWaitlistBlock from './JoinWaitlistBlock/JoinWaitlistBlock';
// const JoinWaitlistBlock = React.lazy(() =>
// 	import('./JoinWaitlistBlock/JoinWaitlistBlock'),
// );

class Home extends PureComponent {
    constructor(props) {
        super(props);
        this.howItWorksSection = React.createRef();
        this.state = {
            menu: false,
            key: 0,
            currentSection: null,
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handlePageSection = this.handlePageSection.bind(this);
    }

    componentDidMount() {
        const {
            changeSearchType,
            resetReadQueryFromURL,
            setLocation,
            changePageType,
            theme,
            queryFromUrl,
            encryptedQueryFromURL,
            readQueryFromURL,
            query,
            appLanguage,
            submitMetrics,
        } = this.props;

        let isWebp = false;
        /* global Modernizr */
        Modernizr.on('webp', function (result) {
            if (result) {
                // supported
                isWebp = true;
            }
        });

        const searchField = document.getElementById('formInput');
        if (searchField && (!isMobile || isTablet)) {
            searchField.focus();
            this.setState({
                searchFieldIsOnFocus: true,
            });
        }

        changePageType(constants.pageTypes.search);
        if (!queryFromUrl && !encryptedQueryFromURL && !query) {
            Promise.all([readQueryFromURL(null, 'home')]).then(() => {});
        }
        this.setState({
            key: history?.location?.key,
        });

        document.addEventListener('click', this.handleClickOutside, true);
        // document.addEventListener('wheel', this.handleBlur, true);
        this.setState({
            themeActive: theme,
            isWebp: isWebp,
        });

        submitMetrics(
            {
                Category: category.homepage,
                Action: action.view,
                Object: 'home',
                Value: 'tempest-search',
                Extras: appLanguage,
            },
            analyticsType.wb,
        );

        setLocation();
        changeSearchType(searchTypes.home, 'Home :116');
        changePageType(constants.pageTypes.home);
        resetReadQueryFromURL();
        this.props.dispatch(change('search_input', 'search_field', ''));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { readQueryFromURL, homepageSection } = this.props;
        const { key } = this.state;

        if (homepageSection) {
            this.handlePageSection(homepageSection);
        }

        if (history?.action === 'POP' && history?.location?.key !== key && (!isMobile || isTablet)) {
            this.setState({ isBackAction: true });
            Promise.all([readQueryFromURL(null, 'home')]).then(() => {});
        }
        this.setState({
            key: history?.location?.key,
        });
    }

    componentWillUnmount() {
        const { changeHomePageSection } = this.props;

        changeHomePageSection(null);
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('wheel', this.handleBlur, true);

        this.setState = (state, callback) => {
            return;
        };
    }

    handleBlur() {
        const { searchFieldIsOnFocus } = this.state;
        if (searchFieldIsOnFocus) {
            document.activeElement.blur();
            this.setState({
                searchFieldIsOnFocus: false,
            });
        }
    }

    handlePageSection(section) {
        this.setState({
            currentSection: section,
        });
    }

    makeSearch(query) {
        const { runSearch, saveFormInput, changeSearchType } = this.props;
        Promise.all([
            runSearch({
                query: query.search_field,
                offset: false,
            }),
            saveFormInput(query.search_field),
            changeSearchType(searchTypes.all, 'Home :551'),
        ]);
    }

    linkEventHandler(event, type) {
        const { submitMetrics } = this.props;
        event.preventDefault();
        submitMetrics({
            Category: category.homepage,
            Action: action.click,
            Object: type,
            Value: type,
        });
        if (type === constants.homePageSections.howItWorks) {
            submitMetrics({
                Category: category.homepage,
                Action: action.click,
                Object: 'exploreArrow',
                Value: 'exploreArrow',
            });
        }
    }

    handleClickOutside = (event) => {
        const mobileMenu = this.refs.mobileMenu;
        if (!mobileMenu || !mobileMenu.contains(event.target)) {
            this.setState({ menu: false });
        }
    };

    changeTheme(theme) {
        const { changeTheme } = this.props;
        this.setState({
            themeActive: theme,
        });
        changeTheme(theme);
    }

    handleClickFeaturedProduct = (type) => {
        const { submitMetrics } = this.props;
        submitMetrics({
            Category: category.homepage,
            Action: action.click,
            Object: 'featuredProduct',
            Value: type,
        });
    };

    render() {
        const { submitMetrics } = this.props;
        const { searchFieldIsOnFocus } = this.state;
        const { withSearchBanner, withTempestHomepageComponents } = companyConfigs?.features;

        return (
            <>
                <Helmet>
                    <title>Tempest – It’s your data. Own it.</title>
                    <meta
                        name="description"
                        content="Tempest is an independent company on a mission to help everyone take back control of their digital lives."
                    />
                </Helmet>
                <div className="homepage">
                    <div className="fixed-half">
                        <NavigationBar submitMetrics={submitMetrics} isInteriorPage={false} />
                        <div className="fixed-half__body">
                            <div className="fixed-half__body__wrapper">
                                <CompanyLogo
                                    svgComponent={<HomepageLogo />}
                                    width={230}
                                    height={50}
                                    classList="company-logo"
                                />
                                {/* <CompanyLogoPride /> */}
                                <SearchInput
                                    autoFocus={!isMobile}
                                    onSubmit={this.makeSearch.bind(this)}
                                    type={searchTypes.home}
                                    searchFieldIsOnFocus={searchFieldIsOnFocus}
                                />
                                {withSearchBanner && (
                                    <Suspense fallback={<div></div>}>
                                        <SearchBanner />
                                    </Suspense>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={cx('scrolling-half', {
                            'scrolling-half--theme-tempest': withTempestHomepageComponents,
                        })}
                    >
                        {withTempestHomepageComponents && (
                            <Suspense fallback={<div></div>}>
                                <NavigationBarSticky submitMetrics={submitMetrics} category={category.homepage} />
                                <UnionSection submitMetrics={submitMetrics} />
                                <HomepageContentTeaser submitMetrics={submitMetrics} />
                                <div className="homepage__slide-teaser">
                                    <HomepageSlideAnimation submitMetrics={submitMetrics} />
                                </div>
                                <div className="homepage-faq-wrapper">
                                    <HomepageFaq questionCatalog={faqQuestionCatalog} />
                                </div>
                            </Suspense>
                        )}
                        <PageFooter
                            submitMetrics={submitMetrics}
                            withAnchorNavi={false}
                            footerBoilerPlateOnly={!withTempestHomepageComponents}
                        />
                    </div>
                    <BackToSearch />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    searchQuery: state.app.searchQuery,
    searchResults: state.search,
    searchLocation: state.search.searchLocation,
    safeSearchType: state.app.safeSearchType,
    showSuggestions: state.app.showSuggestions,
    pageType: state.app.pageType,
    homepageSection: state.app.homepageSection,
    theme: state.app.theme,
    openBetaSearchToken: state.app.openBetaSearchToken,
    emailSubmitted: state.app.emailSubmitted,
    isMobileViewOpen: state.app.isMobileViewOpen,
    appLanguage: state.app.appLanguage,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            searchRequest,
            saveFormInput,
            runSearch,
            changeSearchType,
            resetReadQueryFromURL,
            setLocation,
            changePageType,
            changeHomePageSection,
            changeTheme,
            readQueryFromURL,
            dispatch,
            submitMetrics,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Home));
