import './MovieSlider.scss';

import React from 'react';

import { SwiperSlider } from '../../SwiperSlider/SwiperSlider';

type MovieSliderProps = {
    className?: string;
    children: React.ReactNode;
};

const sliderConfig = {
    slidesPerView: 'auto',
    spaceBetween: 8,
    freeMode: true,
    breakpoints: {
        1200: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            freeMode: false,
            ccsMode: true,
        },
    },
};

function MovieSlider({ className, children }: MovieSliderProps) {
    return (
        <SwiperSlider className={`movie-slider${className ? ` ${className}` : ''}`} config={sliderConfig}>
            {children}
        </SwiperSlider>
    );
}

export default MovieSlider;
