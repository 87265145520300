import './Input.scss';

import PropTypes from 'prop-types';
import React from 'react';

function Input({
    input,
    type,
    meta: { touched, error, warning },
    placeholder,
    className,
    onKeyDown,
    style,
    autoFocus,
    onClick,
    autoComplete,
    autoCapitalize,
    autoCorrect,
    spellCheck,
}) {
    return (
        <input
            {...input}
            autoFocus={autoFocus}
            type={type}
            placeholder={placeholder}
            className={`input${className && ` ${className}`}`}
            style={style}
            onKeyDown={onKeyDown}
            data-test={'Input'}
            onClick={onClick}
            id={'formInput'}
            autoComplete={autoComplete}
            autoCapitalize={autoCapitalize}
            autoCorrect={autoCorrect}
            spellCheck={spellCheck}
        />
    );
}

Input.defaultProps = {
    autoFocus: false,
    type: 'text',
    placeholder: '',
    onKeyDown: Function.prototype,
    className: '',
};

Input.propTypes = {
    autoFocus: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onKeyDown: PropTypes.func,
};

export default Input;
