import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';

interface ButtonProps {
    children: JSX.Element;
    type?: 'button' | 'submit';
    className?: string;
    onClick: (e?: React.MouseEvent<HTMLElement>) => void;
    tooltip?: string;
    disabled?: boolean;
    dataTitle?: string;
}

const Button: FC<ButtonProps> = ({
    children,
    type = 'button',
    onClick,
    className = '',
    disabled = false,
    tooltip,
    dataTitle,
}) => {
    return (
        <div data-test={'ButtonParentComponent'} className="button-wrapper">
            <button
                className={`button${className && ` ${className}`}`}
                type={type}
                onClick={onClick}
                disabled={disabled}
                data-tip={'button'}
                data-for={tooltip ? 'buttonTip' : ''}
                data-test={'buttonComponent'}
                data-title={dataTitle}
            >
                {children}
            </button>
            {!!tooltip && (
                <ReactTooltip
                    id="buttonTip"
                    place="top"
                    effect="solid"
                    type="light"
                    data-test={'tooltipComponent'}
                    border={true}
                    borderColor={'#80859f'}
                >
                    {tooltip}
                </ReactTooltip>
            )}
        </div>
    );
};
export default Button;
