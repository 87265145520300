import './BusinessBrief.scss';

import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import LgView from '../../responsiveComponents/components/LgView/LgView';
import SmView from '../../responsiveComponents/components/SmView/SmView';

import { ReactComponent as TripadvisorIcon } from '../../../images/icons/tripadvisor-rating-icon.svg';
import Rating from '../../Rating/Rating';
import CategoryTooltip from './CategoryTooltip/CategoryTooltip';

function BusinessBrief({
    t,
    isVerticalView,
    provider,
    ratingValue,
    reviewCount,
    priceRate,
    categoriesArray,
    withCutCategories,
    isYelp,
    className,
}) {
    const isSmallMobile = useMediaQuery({ query: '(max-width: 355px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const sliceCount = useMemo(() => {
        if (isSmallMobile || isVerticalView) {
            return 1;
        }

        if (isMobile) {
            return 2;
        }

        return 3;
    }, [isMobile, isSmallMobile, isVerticalView]);

    const groupedCategories = useMemo(() => {
        const categories = categoriesArray.map((category) => category?.title || '');
        const allCategories = categories.join(', ');

        if (categories.length <= 2) {
            return {
                all: allCategories,
                sliced: allCategories,
                hidden: [],
                hiddenCount: 0,
            };
        }

        const isLongNamesContained = categories.some((categoryName) => categoryName.length >= 10);
        const filteredSliceCount = isLongNamesContained && sliceCount > 2 ? 2 : sliceCount;

        const visibleCategories = categories.slice(0, filteredSliceCount);

        return {
            all: allCategories,
            sliced: visibleCategories.join(', '),
            hidden: categories.slice(visibleCategories.length, categories.length).join(', '),
            hiddenCount: categories.length - visibleCategories.length,
        };
    }, [categoriesArray, sliceCount]);

    const categoryItem = useMemo(
        () => (
            <li
                className={cx('business-brief__category', {
                    'business-brief__separator': priceRate,
                    'business-brief__category--cut': withCutCategories,
                })}
            >
                {!!groupedCategories.hiddenCount ? groupedCategories.sliced : groupedCategories.all}
                {!!groupedCategories.hiddenCount && (
                    <CategoryTooltip
                        hiddenCategoriesCount={groupedCategories.hiddenCount}
                        className="business-brief__category-tooltip"
                    >
                        {groupedCategories.hidden}
                    </CategoryTooltip>
                )}
            </li>
        ),
        [priceRate, withCutCategories, groupedCategories],
    );

    const addInfo = (
        <ul className={`business-brief__add-info${withCutCategories ? '' : ' business-brief__add-info--full'}`}>
            {priceRate && <li className="business-brief__price-sign">{priceRate}</li>}
            {categoriesArray?.length > 0 && categoryItem}
        </ul>
    );
    const isTripadvisor = !isYelp;

    return (
        <ul className={`business-brief${isVerticalView ? ' vertical-view' : ''}${className ? ` ${className}` : ''}`}>
            {isTripadvisor && !!ratingValue && (
                <li className="business-brief__item">
                    <TripadvisorIcon className="business-brief__rating-icon" />
                </li>
            )}
            {ratingValue !== 0 && provider && (
                <li className="business-brief__item">
                    <Rating
                        className="business-brief__rating"
                        value={ratingValue}
                        iconType={isTripadvisor ? 'tripadvisor' : 'yelp'}
                    />
                </li>
            )}
            {!!reviewCount && (
                <li className="business-brief__item">
                    <div className="business-brief__reviews">
                        {reviewCount} {t('infocard-reviews', 'reviews')}
                    </div>
                </li>
            )}
            <LgView>
                {!isVerticalView && priceRate && (
                    <li className="business-brief__item business-brief__separator">
                        <span className="business-brief__price-sign">{priceRate}</span>
                    </li>
                )}
                {!isVerticalView && categoriesArray?.length > 0 && categoryItem}
            </LgView>
            <SmView>
                <li className="business-brief__item business-brief__item--line-float">
                    {!isVerticalView && (priceRate || categoriesArray?.length > 0) && addInfo}
                </li>
            </SmView>
            {isVerticalView && (priceRate || categoriesArray?.length > 0) && addInfo}
        </ul>
    );
}

BusinessBrief.defaultProps = {
    isVerticalView: false,
    ratingValue: null,
    reviewCount: null,
    priceRate: '',
    categories: [],
    withCutCategories: true,
};

BusinessBrief.propTypes = {
    isVerticalView: PropTypes.bool,
    ratingValue: PropTypes.number,
    reviewCount: PropTypes.number,
    priceRate: PropTypes.string,
    categories: PropTypes.array,
    withCutCategories: PropTypes.bool,
    provider: PropTypes.string,
};

export default withTranslation('common')(BusinessBrief);
