import React from 'react';

import * as loadStatuses from '../../../../constants/loadStatuses';
import BusinessInfoCard from '../BusinessInfoCard/BusinessInfoCard';
import SingleBusinessCardSkeleton from './SingleBusinessCardSkeleton/SingleBusinessCardSkeleton';

export default function SingleBusinessWidget(props) {
    const { richHeaderLoadStatus, businessWidget } = props;
    if (richHeaderLoadStatus === loadStatuses.idle) {
        return null;
    }
    return richHeaderLoadStatus === loadStatuses.completed && businessWidget ? (
        <BusinessInfoCard {...props} />
    ) : (
        <SingleBusinessCardSkeleton />
    );
}
