import './MovieSliderItem.scss';

import React, { SyntheticEvent, useState } from 'react';
import Truncate from 'react-truncate';

type MovieSliderItemProps = {
    mainText: string;
    additionalText: string;
    photoUrl: string;
    searchUrl: string;
    openInNewTab: boolean;
    onClick: () => void;
    mainTextHeight: number;
};

function MovieSliderItem({
    mainText,
    additionalText,
    photoUrl,
    searchUrl,
    openInNewTab,
    onClick,
    mainTextHeight = 2,
}: MovieSliderItemProps) {
    const [photoUrlError, setPhotoUrlError] = useState(false);

    const onPhotoError = () => {
        setPhotoUrlError(true);
    };
    const onClickHandler = (event: SyntheticEvent<HTMLAnchorElement>) => {
        if (!openInNewTab) {
            event.preventDefault();
            onClick();
        }
    };

    return (
        <div className="slider-item">
            <a
                href={searchUrl}
                target={openInNewTab ? '_blank' : '_self'}
                rel="noreferrer noopener"
                className="slider-item__img-container"
                onClick={onClickHandler}
            >
                {!photoUrlError && <img src={photoUrl} alt="" className="slider-item__img" onError={onPhotoError} />}
            </a>
            <div className="slider-item__body">
                <a
                    href={searchUrl}
                    target={openInNewTab ? '_blank' : '_self'}
                    rel="noreferrer noopener"
                    className="slider-item__title"
                    onClick={onClickHandler}
                >
                    <Truncate lines={mainTextHeight}>{mainText}</Truncate>
                </a>
                <div className="slider-item__text">{additionalText}</div>
            </div>
        </div>
    );
}

export default MovieSliderItem;
