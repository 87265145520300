import './MovieInfocardSkeleton.scss';

import React from 'react';
import { useMediaQuery } from 'react-responsive';

import SkeletonTemplate from '../../SkeletonTemplate/SkeletonTemplate';
import WidgetTemplate from '../../WidgetTemplate/WidgetTemplate';

function MovieInfocardSkeleton() {
    const isMobile = useMediaQuery({ maxWidth: 759 });

    return (
        <WidgetTemplate.Wrapper>
            <WidgetTemplate.Paper className="movie-skeleton">
                <div className="movie-skeleton__top">
                    <div className="movie-skeleton__top-part-1">
                        <SkeletonTemplate.Item className="movie-skeleton__top-item-1" type="line" color="dark" />
                        <SkeletonTemplate.Item className="movie-skeleton__top-item-2" type="line" color="dark" />
                    </div>
                    <div className="movie-skeleton__top-part-2">
                        <SkeletonTemplate.Item className="movie-skeleton__gradient-1" type="gradient-field" />
                        {isMobile && (
                            <SkeletonTemplate.Item className="movie-skeleton__gradient-2" type="gradient-field" />
                        )}
                    </div>
                </div>
                <WidgetTemplate.Block className="movie-skeleton__block movie-skeleton__block--1">
                    <SkeletonTemplate.Item className="movie-skeleton__item-1 movie-skeleton__item-1--1" type="line" />
                    <SkeletonTemplate.Item className="movie-skeleton__item-1 movie-skeleton__item-1--2" type="line" />
                    <SkeletonTemplate.Item className="movie-skeleton__item-1 movie-skeleton__item-1--3" type="line" />
                    <SkeletonTemplate.Item className="movie-skeleton__item-1 movie-skeleton__item-1--4" type="line" />
                    <SkeletonTemplate.Item className="movie-skeleton__item-1 movie-skeleton__item-1--5" type="line" />
                </WidgetTemplate.Block>
                <WidgetTemplate.Block className="movie-skeleton__block movie-skeleton__block--2">
                    {Array.from({ length: 5 }).map((value, index) => (
                        <SkeletonTemplate.Item
                            className="movie-skeleton__item-1 movie-skeleton__item-2"
                            type="line"
                            key={index}
                        />
                    ))}
                </WidgetTemplate.Block>
                <WidgetTemplate.Block className="movie-skeleton__block movie-skeleton__block--3">
                    <SkeletonTemplate.Item className="movie-skeleton__item-1 movie-skeleton__item-3-1" type="line" />
                    <div className="movie-skeleton__part-3">
                        <SkeletonTemplate.Item className="movie-skeleton__item-3-2" type="line" />
                        <div className="movie-skeleton__column-3">
                            <SkeletonTemplate.Item className="movie-skeleton__item-3-3" type="line" />
                            <SkeletonTemplate.Item className="movie-skeleton__item-3-4" type="line" />
                        </div>
                        <SkeletonTemplate.Item className="movie-skeleton__item-3-5" type="line" />
                    </div>
                </WidgetTemplate.Block>
                {!isMobile && (
                    <>
                        <WidgetTemplate.Block className="movie-skeleton__block movie-skeleton__block--desk-1">
                            <SkeletonTemplate.Item className="movie-skeleton__item-desk-1-1" type="line" />
                            <div className="movie-skeleton__part-desk-1">
                                {Array.from({ length: 4 }).map((value, index) => (
                                    <div className="movie-skeleton__column-desk-1" key={index}>
                                        <SkeletonTemplate.Item className="movie-skeleton__item-desk-1-2" type="line" />
                                        <SkeletonTemplate.Item className="movie-skeleton__item-desk-1-3" type="line" />
                                        <SkeletonTemplate.Item className="movie-skeleton__item-desk-1-3" type="line" />
                                        <SkeletonTemplate.Item className="movie-skeleton__item-desk-1-4" type="line" />
                                    </div>
                                ))}
                            </div>
                        </WidgetTemplate.Block>
                        <WidgetTemplate.Block className="movie-skeleton__block movie-skeleton__block--desk-2">
                            <SkeletonTemplate.Item className="movie-skeleton__item-desk-2-1" type="line" />
                            <div className="movie-skeleton__part-desk-2">
                                <SkeletonTemplate.Item className="movie-skeleton__circle-desk-2" type="circle" />
                                <div className="movie-skeleton__column-desk-2">
                                    <SkeletonTemplate.Item className="movie-skeleton__item-desk-2-2" type="line" />
                                    <SkeletonTemplate.Item className="movie-skeleton__item-desk-2-3" type="line" />
                                    <SkeletonTemplate.Item className="movie-skeleton__item-desk-2-3" type="line" />
                                    <SkeletonTemplate.Item className="movie-skeleton__item-desk-2-4" type="line" />
                                </div>
                            </div>
                        </WidgetTemplate.Block>
                    </>
                )}
                <WidgetTemplate.Block className="movie-skeleton__block movie-skeleton__block--4">
                    <SkeletonTemplate.Item className="movie-skeleton__item-4-1" type="line" />
                    <div className="movie-skeleton__part-4">
                        {Array.from({ length: 3 }).map((value, index) => (
                            <div className="movie-skeleton__column-4" key={index}>
                                <SkeletonTemplate.Item className="movie-skeleton__circle-4" type="circle" />
                                <SkeletonTemplate.Item className="movie-skeleton__item-4-2" type="line" />
                            </div>
                        ))}
                    </div>
                </WidgetTemplate.Block>
                {isMobile && (
                    <WidgetTemplate.Block className="movie-skeleton__button">
                        <SkeletonTemplate.Item className="movie-skeleton__button-item" type="line" />
                    </WidgetTemplate.Block>
                )}
                {!isMobile && (
                    <WidgetTemplate.Block className="movie-skeleton__block movie-skeleton__block--desk-3">
                        <SkeletonTemplate.Item className="movie-skeleton__item-desk-3-1" type="line" />
                        <SkeletonTemplate.Item className="movie-skeleton__item-desk-3-2" type="line" />
                        <SkeletonTemplate.Item className="movie-skeleton__item-desk-3-3" type="line" />
                    </WidgetTemplate.Block>
                )}
            </WidgetTemplate.Paper>
            <SkeletonTemplate.Footer />
        </WidgetTemplate.Wrapper>
    );
}

export default MovieInfocardSkeleton;
