import React from 'react';

import * as loadStatuses from '../../../constants/loadStatuses';
import MovieInfocard from './MovieInfocard/MovieInfocard';
import MovieInfocardSkeleton from './MovieInfocardSkeleton/MovieInfocardSkeleton';

export default function MovieWidget(props) {
    const { movieWidgetLoaded, moviesInfoCard, richHeaderLoadStatus } = props;
    if (richHeaderLoadStatus === loadStatuses.idle) {
        return null;
    }
    return movieWidgetLoaded && richHeaderLoadStatus === loadStatuses.completed && moviesInfoCard ? (
        <MovieInfocard {...props} />
    ) : (
        <MovieInfocardSkeleton />
    );
}
