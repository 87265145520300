import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import Truncate from 'react-truncate';
import { bindActionCreators } from 'redux';

import { submitMetrics } from '../../../actions/app';

import { action, category } from '../../../constants/metrics';

class DeepLinkItem extends PureComponent {
    handleLinkClick() {
        const { index, submitMetrics, item } = this.props;
        submitMetrics({
            Category: category.serp,
            Action: action.click,
            Object: 'deeplink',
            Value: index + 1,
            Extras: item.name,
        });
    }

    render() {
        const { item, index, openInNewTab, className, noDeeplinkSnippets } = this.props;
        return (
            <div className={className}>
                {index !== 0 && !noDeeplinkSnippets && <span className={'deepLink-snippet__dot'} />}
                <a
                    target={openInNewTab ? '_blank' : ''}
                    rel="noopener noreferrer"
                    href={item.url}
                    className="search-result-item__title"
                    onClick={this.handleLinkClick.bind(this)}
                >
                    <Truncate
                        lines={1}
                        ellipsis={<span>...</span>}
                        trimWhitespace
                        width={item.name.length >= 15 ? 164 : 160}
                    >
                        {item.name}
                    </Truncate>
                </a>
                {item.snippet && noDeeplinkSnippets && (
                    <div className="search-result-item__text">
                        <Truncate lines={2} ellipsis={<span>...</span>} trimWhitespace>
                            {item.snippet}
                        </Truncate>
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            submitMetrics,
        },
        dispatch,
    );
}

export default connect(null, mapDispatchToProps)(DeepLinkItem);
