import './BrowserCtaButton.scss';

import React from 'react';

import { ReactComponent as ChromeIcon } from '../../images/icons/chrome-icon.svg';
import { ReactComponent as FirefoxIcon } from '../../images/icons/firefox-icon.svg';

type BrowserTypeList = 'chrome' | 'firefox';

type BrowserCtaButtonProps = {
    className?: string;
    children: React.ReactNode;
    onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
    browserType: BrowserTypeList;
};

const getIcon = (browserType: BrowserTypeList) => {
    switch (browserType) {
        case 'chrome':
            return <ChromeIcon className="browser-cta-button__icon" />;
        case 'firefox':
            return <FirefoxIcon className="browser-cta-button__icon" />;
    }
};

function BrowserCtaButton({ className, children, onClick, browserType }: BrowserCtaButtonProps) {
    return (
        <button className={`browser-cta-button${className ? ` ${className}` : ''}`} onClick={onClick}>
            {getIcon(browserType)}
            {children}
        </button>
    );
}

export default BrowserCtaButton;
